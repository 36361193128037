import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import searchlogo from '../../icons/search-interface-symbol 1.png'
import imageicon from '../../icons/profile.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; 
import moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import downloadicon from '../../icons/download 1.png'
import warningicon from '../../icons/caution 1.png'
import viewassign from '../../icons/viewassign.png'
import viewreport from '../../icons/inspection 2.png'
import groupicon from '../../icons/group (1) 2.png'
import info from '../../icons/info 1.png'
import axios from 'axios'
const Viewreport = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const userid = user?.user?.id
  const navigate = useNavigate();
  const [formtoggle,setformtoggle] = useState(false)
  const [followuptoggle,setfollowuptoggle] = useState(false)
  const [visitlogtoggle,setvisitlogtoggle] = useState(false)
  const [name,setname] = useState({})
  const [position,settitle] = useState([])
  const [subposition,setposition] = useState([])
  // const [newsubposition,setnewposition] = useState([])
  const [loading,setloading] = useState(false)
  const [myteam,setmyteam] = useState([])
  const [mynewteam,setnewteam] = useState([])
  const [mynewteamdrop,setnewteamdrop] = useState([])
  const [searcharray,setsearcharray] = useState([])
  const [teamdetailsdata,setteamdetailsdata] = useState([])
  const [searchterm,setsearchterm] = useState('')
  const [daterangetoggle, setdaterangetoggle] = useState(false)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [teamdetailstoggle,setteamdetailstoggle] = useState(false)
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const fname = user?.user?.f_name
  const lname = user?.user?.l_name
  const toggleform = (fname,lname,userid)=>{
    setformtoggle(true)
    setteamdetailstoggle(false)
     setname({fname,lname})
     mysubteam(userid)
    setfollowuptoggle(false)
    setvisitlogtoggle(false)
  }
    const  teamdetails = async(fname,lname,userid) =>{
      const token = user?.token
      setformtoggle(false)
      setteamdetailstoggle(true)
      setname({fname,lname})
      mysubteam(userid)
     setfollowuptoggle(false)
     setvisitlogtoggle(false)
       await axios.get(`/get-user-details?user_id=${userid}`,{
        headers: { "Authorization": `Bearer ${token}` }
       }).then((response)=>{
        setteamdetailsdata(response.data.data)
       }).catch((error)=>{
        console.log(error)
       })
    }
  // const filtered = !searchterm ?
  //   myteam.sort(function (a, b) {
  //     if (a.fname.toLowerCase() < b.fname.toLowerCase()) {
  //       return -1;
  //     }
  //     if (a.fname.toLowerCase() > b.fname.toLowerCase()) {
  //       return 1;
  //     }

  //   }) : myteam.filter((item) => {
  //     return item.fname.toLowerCase().includes(searchterm.toLowerCase())
  //   }).sort(function (a, b) {
  //     if (a.fname.toLowerCase() < b.fname.toLowerCase()) {
  //       return -1;
  //     }
  //     if (a.fname.toLowerCase() > b.fname.toLowerCase()) {
  //       return 1;
  //     }
  //   })
const viewreportfunction = (userid,fname,lname,profile_pic)=>{
  navigate('/viewreport',{
    state:{
      userid : userid,
      fullname: fname+' '+lname,
      profile_pic: profile_pic
    }
  })
}
const toggledaterangepicker = async (e) => {
  setdaterangetoggle(select=> !select)
}
const downloadreportforteam = async()=>{
  const token = user?.token
  const start = moment(startDate).isValid() ? moment(startDate).format('yyyy-MM-DD') : ''
  const end = moment(endDate).isValid() ? moment(endDate).format('yyyy-MM-DD') : ''
   if(!end){
    toast.error("Please select end date!", {
      position: "top-center"
    });
  }
   else if(start===end){
    toast.error("start date and end date are same!", {
      position: "top-center"
    });
  }
   else if(startDate && endDate ){
        await axios.get(`/get-all-team-customer?start=${start}&end=${end}`, {
  responseType: "arraybuffer", headers: {
    "Content-Type": "blob",
    "Authorization": `Bearer ${token}`
  }
  }).then((res) => {
  const fileURL = URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement("a");
  link.href = fileURL;
  link.setAttribute("download", "Report.xlsx");
  document.body.appendChild(link);
  link.click();
  
  setStartDate(null)
  setEndDate(null)
  setdaterangetoggle(false)
  
  }).catch((error) => {
  console.log(error)
  })
  }

}
const searchfun =  async()=>{
  const token = user.token
  setformtoggle(true)
  await axios.get(`/get-unsign-user-search?search_user=${searchterm}`,{
    headers: { "Authorization": `Bearer ${token}` }
   }).then((response)=>{
    setsearcharray(response.data.data)
    setloading(true)
   })
}
  const mysubteam = async(userid)=>{
    const token = user.token
    const subtitle=[]
    await axios.get(`/my-teams?user_id=${userid}`,{
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response)=>{
      setnewteam(response.data.users)
      if(response.data.users.length!==0){
        for(let i=0;i<response.data.users.length;i++){
          if(!subtitle.includes(response.data.users[i].position)){
            subtitle.push(response.data.users[i].position)
          }
         }
      }
      setposition(subtitle)
    }).catch((error)=>{
      console.log(error)
    })
  }

  const removeuser = async(user_id,fname,lname)=>{
    const token = user?.token
    console.log(mynewteamdrop)
    var fname 
    var userid
    if(mynewteamdrop.length==0){
      fname = user.user.f_name + user.user.l_name
      userid = user.user.id
    }
    else{
      fname = mynewteamdrop[mynewteamdrop.length-1].fname + mynewteamdrop[mynewteamdrop.length-1].lname
      userid = mynewteamdrop[mynewteamdrop.length-1].userid
      console.log("second")
      console.log(userid)
    }
    await axios.post(`/remove-team-member`,{user_id},{
      headers:{"Authorization": `Bearer ${token}`}
    }).then((response)=>{
      console.log(response)
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center"
        });
      }

      subteam(userid);
    }).catch((error)=>{
      console.log(error.response.data)
      if (error.response.data.statusCode === 401) {
        toast.error(error.response.data.message, {
          position: "top-center"
        });
      }
    })
  }
  const addteam = async(user_id) =>{
    const token = user?.token
    const orgid = user?.user?.org_id
    var fname 
    var userid
    if(mynewteamdrop.length==0){
      fname = user.user.f_name + user.user.l_name
      userid = user.user.id
    }
    else{
      fname = mynewteamdrop[mynewteamdrop.length-1].fname + mynewteamdrop[mynewteamdrop.length-1].lname
      userid = mynewteamdrop[mynewteamdrop.length-1].userid
    }
    await axios.post(`/add-user-team`,{user_id,orgid},{
      headers:{"Authorization": `Bearer ${token}`}
    }).then((response)=>{
      if (response.status === 200) {
        // toast.success({fname} + {lname} + "has been removed from"+{fname}, {
        //   position: "top-center"
        // });
        toast.success(response.data.message, {
          position: "top-center"
        });
      }
      subteam(userid);
      setsearchterm('')
      setformtoggle(false)
    })
  }
  // const breadcurm = []
  const toggleaccodian = (userid,fname,lname)=>{
    // console.log(userid,fname,lname)
    setformtoggle(false)
    setteamdetailstoggle(false)
    const newuserid = user?.user?.id
    // breadcurm.push({userid,fname,lname})
    if (!mynewteamdrop.some(e => e.userid === userid) && newuserid!==userid) {
      const newaaray = [...mynewteamdrop]
      newaaray.push({userid,fname,lname})
      setnewteamdrop(newaaray)
        subteam(userid)
    }
   else if(userid===mynewteamdrop[mynewteamdrop.length-2]?.userid){
    const newaaray2 = [...mynewteamdrop]
    newaaray2.pop()
    setnewteamdrop(newaaray2)
    subteam(userid)
   }
   else if(userid===newuserid){
    const newaaray2 = []
    setnewteamdrop(newaaray2)
    subteam(userid)
   }
    else{
      return false
    }

  }
  const subteam = async(userid)=>{
    try {
      var titlenew = []
      const token = user?.token
      const response = await axios.get(`/my-teams?user_id=${userid}`,{
        headers:{
          "Authorization": `Bearer ${token}`
        }
      })
      setmyteam(response.data.users)
           if(response.data.users.length!==0){
                for(let i=0;i<response.data.users?.length;i++){
                 if(!titlenew.includes(response.data.users[i]?.position)){
                  titlenew.push(response.data.users[i]?.position)   
               }
            }
             }
             settitle(titlenew)
    } catch (error) {
      console.log(error)
    }
  
  }
  const myteams = async (e) => {
    try {
      const userid = user?.user?.id
      const token = user?.token
      var title = []
      const response = await axios.get(`/my-teams?user_id=${userid}`,{
        headers:{ "Authorization": `Bearer ${token}` }
      })
      setmyteam(response.data.users)
           if(response.data.users.length!==0){
                for(let i=0;i<response.data.users?.length;i++){
                 if(!title.includes(response.data.users[i]?.position)){
                  title.push(response.data.users[i]?.position)   
               }
               
            }
             }
             settitle(title)
             setloading(true)
    } catch (error) {
      console.log(error)
    }

  }
  useEffect(()=>{

    myteams()
  },[])


  return (
    <>
    <div className="sidebarflexproperty">
       <div className="div">
        <Sidebar/>
       </div>
       <div className="dashboard col">
       <div className="row p-1 nav_bar">
            <div className="mx-4">
              <p className='font-weight-bold visit_log fontsize16 py-2 navcolor'>Team</p>
            </div>
            <div className="col-lg-10">
              <div className="d-flex">
              <input className='form-control ' type="text" placeholder='Search Members' value={searchterm} onChange={(e)=>{setsearchterm(e.target.value)}}/>
               <div className="px-2 searchbg pt-1  pl-2 imgcursor " onClick={()=>{searchfun()}}>
              <img className='' src={searchlogo} alt="" />
            </div>
            </div>
            </div>
         </div>
         <div className="row">
         <div className="col-lg-4 border_right">
          <div className="row">
            <div className="col-lg-12 bgwhite border_bottom  p-3 ">
              <div className="d-flex">
              <p className='imgcursor fontsize10 fontcolorblue font-weight-bold underline' onClick={()=>{toggleaccodian(userid,fname,lname)}}>{fname+' '+lname}</p>
              {
                       mynewteamdrop?.map((cval)=>{
                        return <>
                        <p className='imgcursor fontsize10 underline fontcolorblue font-weight-bold ml-2' onClick={()=>{toggleaccodian(cval.userid,cval.fname,cval.lname)}}>{" >  "}{cval.fname+' '+cval.lname}</p>
                        </>
                      })
              }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 ">
                  <div className="d-flex float-right">
                    <div className="div ">
                      {/* {
                        found ?                 <div className=" bgwhite brhalf imgcursor  mt-3 px-2" >
                        <div className="d-flex adddepartmentbtn2 px-2">
                            <div className="div pl-1">
                            <img src={addicon} alt="" />
                            </div>
                            <div className="div">
                            <p className='fontsize10 navcolor font-weight-bold pt-1 pl-1'>Onboard</p>
                            </div>
                        </div>
                    </div> : ''
                      } */}

                    </div>
                      
                <div className="imgcursor w-100" onClick={(e) => { toggledaterangepicker(e) }}>
                    <div className="text-center ">
                      <img className='' src={downloadicon} alt="" />
                    </div>
                    <div className="text-center">
                      <p className='fontsize8  fontcolorblue '>Download Report</p>
                    </div>
                  </div>
                  </div>
            </div>
          </div>
                
                  <div className="row">
                  <div className="col-lg-12 bgwhite heightteam">
                    {
                       loading && position.length!==0 ?  position?.map((cvalue)=>{
                          return <>
                               <p className='textcolor col-lg-12 my-3 font-weight-bold'>{cvalue}</p>
                               {
                                 loading &&  myteam?.map((cval,index)=>{
                                   if(cval.position === cvalue){
                                    return (
                                  <div className=" border bgwhite mb-2 col-lg-12" key={index}>
                                    <div className="d-flex border_bottom mx-2">
                                     <div className=" p-2">
                               <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : imageicon } alt="" />
                                </div>
                                      <div className="col-lg-6">
                                        <p className='fontsize8 pt-3'>{cval.fname+' '+cval.lname}</p>
                                         </div>
                                         <div className="text-right w-100 pt-3 pr-3 ">
                                         <h6 className='fontsize12 navcolor font-weight-bold float-right imgcursor' onClick={()=>{removeuser(cval.userid,cval.fname,cval.lname)}}>Remove</h6>
                                         </div>
                                    </div>
                                    <div className="d-flex p-1">
                                         {
                                          cval.position!== 'Field Sales Executive' ?
                                          (
                                            <>
                                            <div className=" text-center  imgcursor " onClick={()=>{toggleaccodian(cval.userid,cval.fname,cval.lname)}}>
                                            <span><img src={viewassign} alt="" /></span>
                                            <p className='fontsize8 navcolor'>View Team</p>
                                          </div>
                                          {/* <div className=" text-center imgcursor ml-3 " onClick={()=>{toggleform(cval.fname,cval.lname,cval.userid)}}>
                                            <span><img src={adduser} alt="" /></span>
                                            <p className='fontsize8 navcolor'>Assign</p>
                                          </div> */}
                                          </>
                                          )
                                          :
                                          ''
                                         }
                                            <div className=" text-center ml-3 imgcursor" onClick={()=>{teamdetails(cval.fname,cval.lname,cval.userid)}}>
                                  <span><img src={info} alt="" /></span>
                                  <p className='fontsize8 navcolor'> Details</p>
                                </div>
                                        <div className=" text-center imgcursor ml-3 " onClick={()=>{viewreportfunction(cval.userid,cval.fname,cval.lname,cval.profile_pic)}}>
                                        <span><img src={viewreport} alt="" /></span>
                                        <p className='fontsize8 navcolor'>View Report</p>
                                        </div>
                                    </div>
                                     </div>
                                     )
                                    
                                   }
                                   
                                })
                               }
                          </>
                          
                        })
                        :
                        
                      <>
                         <p className='font-weight-bold  fontsize12 p-2'><img src={warningicon} alt="" /> No Member Found</p>
                         </>
                        
                    }
                   
                   </div>
                   </div>
                   </div>
                   
                   {/* <div className="row "> */}
                    <div className="col-lg-8 ">
                      {
                         formtoggle && (
                                   <>
                          {/* <div className="row bgwhite p-3 border_bottom">
                          <div className="">
                             <img src={groupicon} alt="" />
                          </div>
                          <div className="col-lg-10">
                              <p className='font-weight-bold textcolor'>Team{'  '}{name.fname+" "+name.lname}</p>
                          </div>
                          </div> */}
                          {/* <div className="row pl-3">
                            <div className="col-lg-12">
                            {
                              subposition?.map((cvalue)=>{
                                return <> 
                                <p className='textcolor col-lg-12 my-3 font-weight-bold'>{cvalue}</p>
                                 {
                                  mynewteam && mynewteam?.map((cval)=>{
                                    if(cval.position === cvalue){
                                    return (
                                      <div className="d-flex border bgwhite mb-2 col-lg-6">
                                      <div className=" p-2">
                                        <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : imageicon} alt="" />
                                  </div>
                                       
                                      <div className="div col-lg-8 ">
                                        <p className='fontsize12 pt-3'>{cval.fname+' '+cval.lname}</p>
                                      </div>
                                              <div className=" w-100 imgcursor pt-3 pr-3 ">
                                                <p className=' navcolor font-weight-bold  float-right ' onClick={()=>{addteam(cval.userid,cval.org_id)}}>Add</p>
                                              </div>
                                     </div>
                                    )
                                  }
                                  })
                                 }
                                </>
                              })
                             }                     
                          </div>
                          </div> */}
                          {
                      searcharray.length!==0 && loading ? searcharray.map((cval)=>{
                        return (
                          <div className="div border bgwhite mb-2 col-lg-6 ml-3 mt-4">
                          <div className="d-flex border_bottom mx-2">
                          <div className=" p-2">
                    <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : imageicon } alt="" />
                     </div>
                           <div className="col-lg-6">
                             <p className='fontsize8 pt-3'>{cval.fname+' '+cval.lname}</p>
                              </div>
                              {
                                cval.team_assign_status===false ?
                                <div className=" w-100 pt-3 pr-3">
                                <h6 className='navcolor font-weight-bold float-right imgcursor' onClick={()=>{addteam(cval.userid)}}>Add</h6>
                                </div>
                                :
                                ''
                              }
                         </div>
                          </div>
                        )
                      })
                      :
                      <>
                      <p className='font-weight-bold navcolor p-3'>No Member Found</p>
                      </>
                    }
                          </>
                        )
                      }
                      {
                        teamdetailstoggle && (
                          <>
                                <div className="row bgwhite p-3 border_bottom">
                          <div className="">
                             <img src={groupicon} alt="" />
                          </div>
                          <div className="col-lg-10">
                              <p className='font-weight-bold textcolor'>{name.fname+" "+name.lname}</p>
                          </div>
                          </div>
                          <div className="Team_details">
                  {
                    teamdetailsdata?.map((cval)=>{
                      return <>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Full Name</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.full_name}</p>
                  </div>
                  </div>
                            <div className="row  p-3">
                  <div className="col-lg-4">
                    <p>Email</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.email_id}</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Phone No</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.phone_no}</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Gender</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.gender==='M' ? 'Male' : cval.gender==='F' ? 'Female' : 'Other'}</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Role</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.role}</p>
                  </div>
                  </div>
                      </>
                    })
                  }
             
                </div>
                          </>
                        )
                      }
                      <div className='daterangepicker2'>
                              {
                    daterangetoggle && (
                      <>
<DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      maxDate={new Date()}
      selectsRange
      inline
      // shouldCloseOnSelect={true}
    />
        <div className="d-flex">
    <button className='btn btn-primary w-100 mx-1' onClick={()=>{toggledaterangepicker()}}>Close</button>
    <button className='btn btn-primary w-100 mx-1' onClick={()=>{downloadreportforteam()}}>Download Report</button>
    </div>
                      </>
                    )
                  }
                      </div>
                      </div>
                    </div>
                   </div>
       </div>
    {/* </div> */}
    <ToastContainer />
    </>
  )
}

export default Viewreport