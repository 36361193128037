import {BrowserRouter, Routes, Route } from 'react-router-dom';
//pages
import Login from './pages/Login/login'
import Dashboard from './pages/Dashboard/dashboard'
import Visitlog from './pages/Visitlog/Visitlog';
import Location from './pages/Location/Location';
import Team from './pages/Team/Team';
import Customer from './pages/Customer/Customer';
import Viewreport from './pages/Viewreport/Viewreport';
import Profile from './pages/Profile/Profile';
import Resetpassword  from './pages/Login/Resetpassword';
import Setnewpassword from './pages/Login/Setnewpassword';
import './App.css';
import Notifications from './pages/Notification/Notifications';
import ProtectedRoutes from './Auth/ProtectedRoutes';
import LandingPage from './pages/LandingPage/LandingPage';
import ManageRequests from './pages/ManageRequests/ManageRequests';
import Admindashboard from './pages/Admin/Admindashboard/Admindashboard';
import ManageEmployees from './pages/Admin/ManageEmployees/ManageEmployees';
import { ManageForms } from './pages/Admin/Manageforms/ManageForms';
import Settings from './pages/Admin/Settings/Settings';
import Claims from './pages/Admin/Claims/Claims';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
              <Routes>
               
                <Route path='/' element={<LandingPage />}></Route>
                <Route path='/login' element={<Login />}></Route>
                <Route element={<ProtectedRoutes/>}>
                <Route path='/dashboard' element={<Dashboard />}></Route>
                <Route path='/visitlog' element={<Visitlog />}></Route>
                <Route path='/location' element={<Location />}></Route>
                <Route path='/viewreport' element={<Team />}></Route>
                <Route path='/customer' element={<Customer />}></Route>
                <Route path='/team' element={<Viewreport />}></Route>
                <Route path='/notifications' element={<Notifications />}></Route>
                <Route path='/profile' element={<Profile />}></Route>
                <Route path='/managerequests' element={<ManageRequests />}></Route>
                <Route path='/Admindashboard' element={<Admindashboard/>}></Route>
                <Route path='/manageemployees' element={<ManageEmployees/>}></Route>
                <Route path='/manageforms' element={<ManageForms/>}></Route>
                <Route path='/claims' element={<Claims/>}></Route>
                <Route path='/setting' element={<Settings/>}></Route>
                </Route>
                <Route path='/resetpassword' element={<Resetpassword />}></Route>
                <Route path='/setpassword' element={<Setnewpassword/>}></Route>
              </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;