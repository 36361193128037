import React from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import Sidebar from '../../../components/Sidebar'
import { useState } from 'react'
import Chart from 'react-apexcharts'

const Admindashboard = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const [countdata,setcountdata] = useState([])
    const [piedata,setpiedata] = useState([])
    const [colors,setcolor] = useState([])
    const [chartdata,setchartdata] = useState([])
    const [linevalues,setlinevalues] = useState([])
    const [linemonths,setmonths] = useState([])
    const [linevalues2,setlinevalues2] = useState([])
    const [linemonths2,setmonths2] = useState([])
    var  options={
        chart: {
            type: 'line',
            toolbar:{
              show: false
            }
          },
          stroke: {
            curve: 'straight',
            width:2
          },
          colors:['#A6AAAC'],
        xaxis:{
          show: true,
          showAlways: true,
          axisTicks: {
            show: false
          },
            categories: linemonths,
            axisBorder: {
              show: true,
              color: "#aaa",
              },
        },
        grid:{
            show: false,
        },
        yaxis:{
            show: true,
            showAlways: true,
            // title:{text:"No. of Customers Purchasing  Subscription",},    
            axisBorder: {
                show: true,
                color: "#aaa"
              },  
              labels:{
                formatter: function(val) {
                  return val.toFixed(0);
                },
                style: { fontSize: "15", colors: ["#000"] },
              },           
        }          

    }
    var  options2={
        chart: {
            type: 'line',
            toolbar:{
              show: false
            }
          },
          stroke: {
            curve: 'straight',
            width:2
          },
          colors:['#77FF61'],
        xaxis:{
          show: true,
          showAlways: true,
          axisTicks: {
            show: false
          },
            categories: linemonths2,
            axisBorder: {
              show: true,
              color: "#aaa"
            },
        },
        grid:{
            show: false,
        },
        yaxis:{
            show: true,
            showAlways: true,
            // title:{text:"No. of Customers Purchasing  Subscription",},    
            axisBorder: {
                show: true,
                color: "#aaa"
              },  
              labels:{
                formatter: function(val) {
                  return val.toFixed(0);
                },
                style: { fontSize: "15", colors: ["#000"] },
              },           
        }          

    }
    useEffect(()=>{
        const token = user.token
        const values=[]
        const names=[]
        const colors =[]
        const piedata = async()=>{
         const response = await axios.get('/get-customer-status-counts',{
            headers:{"Authorization":`Bearer ${token}`}
          })
        //   console.log(response.data.data.chart_data)
          for(let i=0;i<response.data.data.chart_data.length;i++){
            values.push(response.data.data.chart_data[i]?.value)
            names.push(response.data.data.chart_data[i]?.name)
            colors.push(response.data.data.chart_data[i]?.colour)
          }
        //   setcount(response.data.data.count_data)
          setpiedata(values)
          setcountdata(names)
          setcolor(colors)
        }
        const customer_conversion = async()=>{
          const values = []
          const months = []
          await axios.get('/get-v1-cust-conversion-closed-rate',{
            headers:{"Authorization":`Bearer ${token}`}
          }).then((response)=>{
               const result = response.data.data
                  for(let i=0;i<result.length;i++){
                    values.push(result[i].closed_customer)
                    months.push(result[i].months)
                  }
                  setlinevalues(values)
                  setmonths(months)
               })
        }
        const customer_retension = async()=>{
          const values = []
          const months = []
          await axios.get('/get-v1-customer-conversion-renewal-rate',{
            headers:{"Authorization":`Bearer ${token}`}
          }).then((response)=>{
               const result = response.data.data
                  for(let i=0;i<result.length;i++){
                    values.push(result[i].closed_customer)
                    months.push(result[i].months)
                  }
                  setlinevalues2(values)
                  setmonths2(months)
               })
        }
          const countdata = ()=>{
            axios.get('/get-v1-org-detail-counts',{
              headers:{"Authorization":`Bearer ${token}`}
            }).then((response)=>{
              setchartdata(response.data.chart_data)
            }).catch((error)=>{
              console.log(error)
            })
          }
        piedata()
        countdata()
        customer_conversion()
        customer_retension()
    },[])
  return (
    <>
    <div className="sidebarflexproperty">
        <div>
        <Sidebar/>
        </div>
        <div className="divouterdashboard">
        <div className="dashboard col">
        <div className="row p-1 nav_bar">
            <div className="mx-4">
              <p className='font-weight-bold  fontsize16 py-2 navcolor breadcrumstyle'>Dashboard</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex">
              <div className="basic_plan p-2 mt-2">
                <p className='wcolor'>Sorry, No Active Plan Now !</p>
                {/* <p className='wcolor'>Basic Plan</p>
                <p className='wcolor fontsize8'>10 Active Users</p>
                <p className='wcolor fontsize8 pt-1'>Expires on 10th March, 2023</p> */}
              </div>
              <div className="count-div text-center p-2 py-3 ml-3 mt-2">
                <p className='wcolor fontsize14'>{chartdata[0]?.value}</p>
                <p className='wcolor fontsize8'>Locations</p>
              </div>
              <div className="count-div text-center p-2 py-3 ml-3 mt-2">
                <p className='wcolor fontsize14'>{chartdata[1]?.value}</p>
                <p className='wcolor fontsize8'>Sub-Locations</p>
              </div>
              <div className="count-div text-center p-2 py-3 ml-3 mt-2">
                <p className='wcolor fontsize14'>{chartdata[2]?.value}</p>
                <p className='wcolor fontsize8'>Customers</p>
              </div>
              <div className="count-div text-center p-2 py-3 ml-3 mt-2">
                <p className='wcolor fontsize14'>{chartdata[3]?.value}</p>
                <p className='wcolor fontsize8'>Users</p>
              </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
            <div className="div br_1 bgwhite pb-3  mt-2">
              <h6 className='col-lg-12 py-1 navcolor font-weight-bold'>Overview</h6>
                 <div className="row ">
                    <Chart 
                    type='donut'
                    width={300}

                    series={piedata}
                    options={{
                      noData:{
                        text: 'No data',
                        align: 'center',
                        verticalAlign: 'middle',
                        style: {
                          color: '#fff',
                          fontSize: '14px',
                        }
                      },
                      // colors: colors,
                      colors : ['rgba(103, 103, 102, 1)','rgba(166, 170, 172, 1)','rgba(69, 174, 179, 1)','rgba(255, 206, 49, 1)','rgba(174, 214, 216, 1)', 'rgba(249, 172, 124, 1)', 'rgba(119, 255, 97, 1)'],
                      labels: countdata,
                      legend:{
                        position: 'right',
                      
                      },
                      dataLabels:{
                        enabled: false
                      }
                    }}
                    />
                 </div>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
            <div className="div br_1 bgwhite pb-1 mt-2">
              <h6 className='col-lg-12 py-3 navcolor font-weight-bold'>Customer Conversion Status</h6>
                 <div className="row pl-4">
                 <Chart type='line'
            // width={500}
            height={150}
             options={options}
          series={[{
              name: "Desktops",
              data: linevalues
          }
        ]}
    
         
          >
          </Chart>
                 </div>
                </div>
            </div>
            <div className="col-lg-6">
            <div className="div br_1 bgwhite pb-1 mt-2">
              <h6 className='col-lg-12 py-3 navcolor font-weight-bold'>Customer Retension Status</h6>
                 <div className="row pl-4">
                 <Chart type='line'
            // width={500}
            height={150}
             options={options2}
          series={[{
              name: "Desktops",
              data: linevalues2
          }
        ]}
    
         
          >
          </Chart>
                 </div>
                </div>
            </div>
          </div>
        </div>
        </div>
    </div>
    </>
  )
}

export default Admindashboard