import React, { useState, useEffect } from 'react'
import axios from 'axios'
import schedule from '../../icons/schedule 1.png'
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../../components/Sidebar'
import searchlogo from '../../icons/search-interface-symbol 1.png'
import firsticon from '../../icons/first.png'
import secondicon from '../../icons/second.png'
import thirdicon from '../../icons/third.png'
import sixthicon from '../../icons/sixth.png'
import seventhicon from '../../icons/seventh.png'
import ninethicon from '../../icons/nineth.png'
import fifthicon from '../../icons/fifth.png'
import warningicon from '../../icons/caution 1.png'
import logo from '../../icons/edit 1 (1).png'
import logo2 from '../../icons/tick-mark 1.png'
import linklogo from '../../icons/Vector (5).png'
import '../../App.css'
import moment from 'moment/moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const Visitlog = () => {
  const navigate = useNavigate()
  const [value,setValue] = useState('');
  const [value2, setValue2] = useState('');
  const [formtoggle, setformtoggle] = useState(false)
  const [followuptoggle, setfollowuptoggle] = useState(false)
  const [bartoggle, setbartoggle] = useState(false)
  const [headerval, setheaderval] = useState({})
  const [popuptoggle, setpopuptoggle] = useState(false)
  const [image, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [getclinic, setclinic] = useState([])
  const [visitstatus, setvisitstatus] = useState([])
  const [getdate, setdate] = useState('')
  const [getdate2, setdate2] = useState('')
  const [gettodostatus, settodostatus] = useState([])
  const [model, setmodel] = useState([])
  const [prefielddetails, setprefielddetails] = useState([])
  const [emailtype, setemailtype] = useState([])
  const [message_text, setmessagetext] = useState('')
  const [message_type, setmessagetype] = useState('')
  const [searchterm, setsearchterm] = useState('')
  const [loading, setloading] = useState(false)
  const [imgtoggle, setimgtoggle] = useState(false)
  const [visitlogtoggle, setvisitlogtoggle] = useState(false)
  const [logtoggle, setlogtoggle] = useState(false)
  const [open, setOpen] = useState(false)
  const user = JSON.parse(localStorage.getItem('user'))
  const org_id = user.user.org_id
  const token = user.token
  const user_id = user.user.id
  const custom_fiels = user.user.custom_fields
    var visit_status
    var visit_type_fields
    var todo_status
    var subscription_model
  for(let i=0;i<custom_fiels.length;i++){
    if(custom_fiels[i].key==='visit_status'){
       visit_status = custom_fiels[i]?.value
    }
    if(custom_fiels[i].key==='visit_type_fields'){
      visit_type_fields = custom_fiels[i]?.value
    }
    if(custom_fiels[i].key==='todo_status'){
      todo_status = custom_fiels[i]?.value
    }
    if(custom_fiels[i].key==='subscription_model'){
      subscription_model = custom_fiels[i]?.value
    }
  }
  const [formdata, setformdata] = useState({
    cp_name: '', phone_no: '', email_id: '', subs_model: '', price: '', no_of_visits: '', duration: '', status: '', visitor_comments: '',visit_type:''
  })

  const permissions = user?.user?.role_assign
  const permissionsadmin = user?.user?.admin_assign_role
  var foundadmin = false
  for(let i=0;i<permissions?.length;i++){
    if(permissions[i]?.route === '*-visitlog') {
      var array = Object.values(permissions[i].permission)
    }
  }
  for(let i=0;i<permissionsadmin?.length;i++){
    if(permissionsadmin[i]?.route === '*-visitlog') {
      foundadmin = true
    }
  }
  const found = array?.find(obj => {
    return obj === 'C' ? true : false;
  });
  const filtered = !searchterm ?
    getclinic.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }

    }) : getclinic.filter((item) => {
      return item.name.toLowerCase().includes(searchterm.toLowerCase())
    }).sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
    })
  const handleimage = () => {
    setImageUrl(null)
    setSelectedImage(null)
  }
  const toggleimage = () => {
    setimgtoggle(prev => !prev)
  }
  const handleinputs = (e) => {
    const newdata = { ...formdata }
    newdata[e.target.name] = e.target.value;
    setformdata(newdata)
  }
  // const toggleform = () => {


    
  // }
  const togglefollowup = (name, adr1, adr2, customer_id) => {
    setbartoggle(true)
    setformtoggle(false)
    localStorage.setItem("clinic_name",JSON.stringify(name))
    setheaderval({ name, adr1, adr2, customer_id })
    setfollowuptoggle(true)
    setvisitlogtoggle(false)
  }
  const togglefollowupnew = () => {
    setbartoggle(false)
    setformtoggle(false)
    setfollowuptoggle(true)
    setvisitlogtoggle(false)
  }
  const togglepopup = () => {
    setpopuptoggle(true)
    setTimeout(() => {
      setpopuptoggle(false)
    }, 2000);
  
  }
   const get_visit_details = async(name, adr1, adr2, customer_id,cpname,no,email)=>{
    localStorage.setItem("clinic_name",JSON.stringify(name))
    setbartoggle(true)
    setheaderval({ name, adr1, adr2, customer_id })
    setformtoggle(true)
    setfollowuptoggle(false)
    await axios.get(`/get-visit-details?user_id=${user_id}&customer_id=${customer_id}`,{
      headers:{
        "Authorization": `Bearer ${token}`
      }
    }).then((response)=>{
      const result = response.data.data
      setprefielddetails(result[result?.length-1])
      const dateandtime = JSON.stringify(result[result?.length-1]?.visited_on)?.split(' ')
      var newdate
      var newhour
      var newmin
      if(dateandtime) {
       newdate = dateandtime[0]?.slice(1,11)
       newhour = dateandtime[1]?.slice(0,2)      
       newmin = dateandtime[1]?.slice(3,5)      
      }
      setlogtoggle(true)
      const d = new Date(newdate)
      const p = new Date();
      p.setHours(newhour)
      p.setMinutes(newmin)
      setdate(result[result?.length-1]?.visited_on ? d : '')
      setValue(result[result?.length-1]?.visited_on ? p : null)
      setformdata({
        cp_name: result[result?.length-1]?.contact_person ? result[result?.length-1]?.contact_person  : cpname, phone_no: result[result?.length-1]?.phone_no ? result[result?.length-1]?.phone_no  : no , email_id: result[result?.length-1]?.email_id ? result[result?.length-1]?.email_id  : email, subs_model: result[result?.length-1]?.subs_model ? result[result?.length-1]?.subs_model : '', price: result[result?.length-1]?.price ? result[result?.length-1]?.price  : '', no_of_visits: result[result?.length-1]?.no_of_visits ? result[result?.length-1]?.no_of_visits + 1  : 1, status: result[result?.length-1]?.status ? result[result?.length-1]?.status  : '', visitor_comments: result[result?.length-1]?.visitor_comments ? result[result?.length-1]?.visitor_comments  : '', duration: result[result?.length-1]?.duration ? result[result?.length-1]?.duration  : '', visit_type: result[result?.length-1]?.visit_type ? result[result?.length-1]?.visit_type  : ''
      })
    
    })
   }
  const createcustomer = async (e) => {
    e.preventDefault()
    const visit = moment(value.$d).format('HH:mm:ss')
    const date = moment(getdate).format('yyyy-MM-DD')
    const visited_on = (date + 'T' + visit)
    const customer_id = headerval.customer_id
    const { cp_name, phone_no, email_id, subs_model, price, no_of_visits, status, visitor_comments, duration, visit_type } = formdata
      await axios.post('/create-visit-log', { org_id, email_id, cp_name, phone_no, subs_model, price, no_of_visits, status, visitor_comments, visited_on, duration,visit_type, customer_id, image }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.data.statusCode === 200) {
          toast.success("Visit Log Created!", {
            position: "top-center"
          });
          setformdata({
            cp_name: '', phone_no: '', email_id: '', subs_model: '', price: '', no_of_visits: '', status: '', visitor_comments: '', duration: '',visit_type:''
          })
          setSelectedImage(null)
          setdate('')
          setValue(null)
          setImageUrl(null)
          setvisitlogtoggle(true)
        }
      }).catch((error) => { console.log(error) })
  }
  const addfollowup = async (e) => {
    e.preventDefault()
    const visit = moment(value2.$d).format('HH:mm:ss')
    const date = moment(getdate2).format('yyyy-MM-DD')
    const target_date = (date + 'T' + visit)
    const message_header = JSON.parse(localStorage.getItem('clinic_name'))
    await axios.post('/create-todo', { org_id, message_header, message_text, message_type, target_date }, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      if (response.data.statusCode === 200) {
        setmessagetype('')
        setmessagetext('')
        setdate2('')
        setValue2(null)
        togglepopup()
        localStorage.removeItem("clinic_name")
      }
    }).catch((error) => {
      console.log(error)
    })
  }
  useEffect(() => {
    const getcustomername = async () => {
      const token = user.token
      const response = await axios.get('/get-customer-list', {
        headers: { "Authorization": `Bearer ${token}` }
      })
      const result = response.data.data
      setclinic(result)
      // console.log(result.filter(e => e.created_by === user_id).length) 
      setloading(true)
    }
    // const customerstatus = async () => {
    //   const response = await axios.get('/visit-status', {
    //     headers: { "Authorization": `Bearer ${token}` }
    //   })
    //   const result = response.data.data
    //   setvisitstatus(result)
    // }
    // const gettodostatus = async () => {
    //   const response = await axios.get('/get-todo-status')
    //   settodostatus(response.data.data)
    // }
    // const subscriptionmodel = async()=>{
    //   const response = await axios.get('/get-subscription-model')
    //   setmodel(response.data.data)
    // }
    // const emailtypefn = async()=>{
    //   const response = await axios.get('/get-visit-type-drop-down')
    //   setemailtype(response.data.data)
    // }
    if (image) {
      setImageUrl(URL.createObjectURL(image));
    }
    getcustomername()
  }, [image])
  return (
    <>
      <div className="sidebarflexproperty">
        <div className="">
          <Sidebar />
        </div>
        <div className=' dashboard  col '>
          <div className="row p-1 nav_bar">
            <div className=" mx-4">
              <p className='font-weight-bold visit_log fontsize16 py-2 navcolor'>Visit Log</p>
            </div>
            <div className="col-lg-10">
              <div className="d-flex">
                <input className='form-control ' type="text" placeholder='Search Clinics' value={searchterm} onChange={(e) => { setsearchterm(e.target.value) }} />
                <div className="px-2 searchbg pt-1 imgcursor pl-2">
                  <img className='' src={searchlogo} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row visit_log_left">
            <div className="col-lg-4 left_div borderryt ">
              {
                searchterm.length!==0 ? <><p className='fontsize14 navcolor font-weight-bold p-2'>Search Result</p></> : '' 
              }
              {
                searchterm.length===0   &&  getclinic.filter(e => e.created_by=== user_id).length!==0 && loading  ?  <p className='fontsize14 navcolor font-weight-bold p-2'>Created by Me</p> : ''
              }
              {
                filtered.length === 0 && loading ? <p className=' mt-3 fontsize12 font-weight-bold text-center'><img src={warningicon} alt="" /> No Result Found!</p> : getclinic && filtered.map((cval) => {
                  if(cval.created_by===user_id){
                    return (
                      <div className="row border m-1 my-2 " key={cval.customer_id}>
                      <div className="div col-lg-11 border_bottom ml-2">
                        <div className=" py-1">
                          <p className='fontsize8 font-weight-bold'>{cval.name}</p>
                        </div>
                        <div className="pb-1">
                          <p className='fontsize8'>{cval.addr1}{cval.addr1 && cval.addr2 ? '-' : ''}{cval.addr2}</p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="d-flex p-1">
                          {
                            found || foundadmin ?    <div className="text-center">
                            <img className=' text-center imgcursor' src={logo} alt="" onClick={() => { get_visit_details(cval.name, cval.addr1, cval.addr2, cval.customer_id,cval.cp_name,cval.phone_no,cval.email_id) }} />
                             <p className='fontsize8 navcolor'>Follow-Up log</p>
                          </div> :''}
                   
                      <div className="pl-3 text-center">
                        <img className='  cursor-pointer imgcursor' src={schedule} alt="" onClick={() => { togglefollowup(cval.name, cval.addr1, cval.addr2, cval.customer_id)}} />
                       <p className='fontsize8 navcolor'>To-Do</p>
                      </div>
                        </div>
                    </div>
                  </div>
                   
                    )
                  }
                })
              }
              
               {
                  searchterm.length===0  &&  getclinic.filter(e => e.created_by!== user_id).length!==0 ?
                 <p className='fontsize14 navcolor font-weight-bold p-2'>Assined to Me</p> :  ''
              }
             
              {
                filtered.length === 0 && loading ? '' : getclinic && filtered.map((cval) => {
                  if(cval.created_by!==user_id){
                    return (
                      <div className="row border m-1 my-2 " key={cval.customer_id}>
                          <div className="div col-lg-11 border_bottom ml-2">
                            <div className=" py-1">
                              <p className='fontsize8 font-weight-bold'>{cval.name}</p>
                            </div>
                            <div className="pb-1">
                              <p className='fontsize8'>{cval.addr1}-{cval.addr2}</p>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="d-flex p-1">
                          <div className="text-center">
                            <img className=' text-center imgcursor' src={logo} alt="" onClick={() => { get_visit_details(cval.name, cval.addr1, cval.addr2, cval.customer_id,cval.cp_name,cval.phone_no,cval.email_id) }} />
                             <p className='fontsize8 navcolor'>Follow-Up log</p>
                          </div>
                          <div className="pl-3 text-center">
                            <img className='  cursor-pointer imgcursor' src={schedule} alt="" onClick={() => { togglefollowup(cval.name, cval.addr1, cval.addr2, cval.customer_id)}} />
                           <p className='fontsize8 navcolor'>To-Do</p>
                          </div>
                            </div>
                        </div>
                      </div>
                    )
                    }
                })
              }


            </div>
            <div className="col-lg-8">
              {
                bartoggle && (
                  <div className="row p-1 bgwhite border_bottom">
                    <div className="col-lg-6 mt-1">
                      <div className="div">
                        {headerval.name}
                      </div>
                      <div className="div">
                        <p className='fontsize12'>{headerval.adr1}-{headerval.adr2}</p>
                      </div>

                    </div>
                    {/* <div className="col-lg-6 my-1 ">
                      <button className='float-right add_followUp bgwhite fontsize12 p-1 font-weight-bold' onClick={togglefollowup}> <img className='mr-1' src={logo3} alt="" />Add Follow-up</button>
                    </div> */}
                  </div>
                )
              }
              {
                formtoggle && logtoggle && (
                  <>
                    <form onSubmit={createcustomer}>
                      <div className="row bgwhite">
                        <div className="formpagecomp col-lg-12">
                          <div className=" col-lg-12">
                            <div className="d-flex mt-5">
                              <input name='cp_name' type="text" className='w-100 input_fields' placeholder='Contact Person' onChange={(e) => handleinputs(e)} value={formdata.cp_name} required autoComplete='off' />
                              <div className="div mt-2 form_icons_set">
                                <img src={firsticon} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="d-flex mt-5">
                              <input name='phone_no' type="text" className='w-100 input_fields' placeholder='Contact' onChange={(e) => handleinputs(e)} value={formdata.phone_no} pattern="[789][0-9]{9}" required autoComplete='off' />
                              <div className="div mt-2 form_icons_set">
                                <img src={secondicon} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="d-flex mt-5">
                              <input name='email_id' type="email" className='w-100 input_fields' placeholder='Email' onChange={(e) => handleinputs(e)} value={formdata.email_id} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required autoComplete='off' />
                              <div className="div mt-2 form_icons_set">
                                <img src={thirdicon} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="d-flex mt-5">
                              <input name='no_of_visits' type="text" className='w-100 input_fields' placeholder='No. Of Visits' onChange={(e) => handleinputs(e)} value={formdata.no_of_visits} pattern="[0-9]{1,5}" required autoComplete='off' readOnly/>
                              <div className="div mt-2 form_icons_set">
                                <img src={sixthicon} alt="" />
                              </div>
                            </div>
                          </div>
                          {
                            prefielddetails?.subs_model_name ?
                            <div className="col-lg-12">
                            <div className="d-flex mt-5 dropplaceholder">
                              <select name='subs_model' onChange={handleinputs} value={formdata.subs_model} className='input_fields w-100' required >
                                <option className='fontsize8' value={prefielddetails.subs_model} selected hidden>{prefielddetails.subs_model_name}</option>
                                {
                                  subscription_model?.map((curele) => {
                                    return <><option value={curele.type}>{curele.status}</option></>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                                :
                                <div className="col-lg-12">
                                <div className="d-flex mt-5 dropplaceholder">
                                  <select name='subs_model' onChange={handleinputs} value={formdata.subs_model} className='input_fields w-100' required >
                                    <option className='fontsize8' value="" disabled selected hidden>Subscription Model</option>
                                    {
                                      subscription_model?.map((curele) => {
                                        return <><option value={curele.type}>{curele.status}</option></>
                                      })
                                    }
                                  </select>
                                </div>
                              </div>
                          }
                      
                          <div className="col-lg-12">
                            <div className="d-flex mt-5">
                              <input name='price' type="text" className='w-100 input_fields' placeholder='Price' onChange={(e) => handleinputs(e)} value={formdata.price} pattern="[0-9]{1,7}" required autoComplete='off' />
                              <div className="div mt-2 form_icons_set">
                                <img src={fifthicon} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="d-flex mt-5">
                              <DatePicker autoComplete='off' dateFormat='yyyy-MM-dd' className='w-100 input_fields' selected={getdate} onChange={date => setdate(date)} maxDate={new Date()} placeholderText='Date of Visit' required />
                              <div className="div mt-2 form_icons_set">
                                <img src={seventhicon} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mb-2">
                            <div className="d-flex mt-5">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  className='w-100 input_fields'
                                  value={value}
                                  ampm={false}
                                  open={open}
                                  onOpen={() => setOpen(true)}
                                  onClose={() => setOpen(false)}
                                  onChange={(newValue) => {
                                    setValue(newValue);
                                  }}
                                  inputProps={{
                                    placeholder: "Time of Visit"
                                  }}
                                  InputProps={{
                                    disableUnderline: true
                                  }}
                                  renderInput={(params) => <TextField  {...params} variant="standard" onClick={()=>{setOpen(true)}} autoComplete="off"/>}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="d-flex mt-5">
                              <input name='duration' type="text" className='w-100 input_fields' placeholder='Duration' onChange={(e) => handleinputs(e)} value={formdata.duration} required autoComplete='off' />
                              <div className="div mt-2 form_icons_set">
                                <img src={ninethicon} alt="" />
                              </div>
                            </div>
                          </div>
                          {
                            prefielddetails?.type ?
                            <div className="col-lg-12">
                            <div className="d-flex mt-5 dropplaceholder">
                              <select name='status' onChange={handleinputs} value={formdata.status} className='input_fields w-100' required >
                                <option className='fontsize8' value={prefielddetails.type}  selected hidden>{prefielddetails.type}</option>
                                {
                                  visit_status?.map((curele) => {
                                    return <><option value={curele.type}>{curele.status}</option></>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                            :
                            <div className="col-lg-12">
                            <div className="d-flex mt-5 dropplaceholder">
                              <select name='status' onChange={handleinputs} value={formdata.status} className='input_fields w-100' required >
                                <option className='fontsize8' value="" disabled selected hidden>Status</option>
                                {
                                  visit_status?.map((curele) => {
                                    return <><option value={curele.type}>{curele.status}</option></>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                          } 
                          {
                            prefielddetails?.visit_type ?
                            <div className="col-lg-12">
                            <div className="d-flex mt-5 dropplaceholder">
                              <select name='visit_type' onChange={handleinputs} value={formdata.visit_type} className='input_fields w-100' required >
                                <option className='fontsize8' value={prefielddetails.visit_type}  selected hidden>{prefielddetails.visit_type}</option>
                                {
                                  visit_type_fields?.map((curele) => {
                                    return <><option value={curele.type}>{curele.type}</option></>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                            :
                            <div className="col-lg-12">
                            <div className="d-flex mt-5 dropplaceholder">
                              <select name='visit_type' onChange={handleinputs} value={formdata.visit_type} className='input_fields w-100' required >
                                <option className='fontsize8' value="" disabled selected hidden>Follow-Up Type</option>
                                {
                                  visit_type_fields?.map((curele) => {
                                    return <><option value={curele.type}>{curele.type}</option></>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                          } 
                          <div className="col-lg-12">
                            <div className="d-flex mt-5">
                              <input name='visitor_comments' type="text" className='w-100 input_fields' placeholder='Comments' maxLength="255" onChange={(e) => handleinputs(e)} value={formdata.visitor_comments}  autoComplete='off' />
                              <div className="div mt-2 form_icons_set">
                                <img src={ninethicon} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="row px-3 py-2">
                            <div className="col-lg-2 ">
                              <label for="inputTag" className='imgcursor' required><img src={linklogo} alt="" /><p className='fontsize8 '>Select Image</p><input name="image" id="inputTag" className='displayhide' type="file" accept="image/png, image/jpg, image/jpeg" onChange={(e) => setSelectedImage(e.target.files[0])} /></label>

                            </div>
                            <div className="col-lg-4">
                              {
                                imageUrl && (
                                  <img src={imageUrl} alt="" className='smallimg'  data-toggle="modal" data-target="#exampleModalCenter"/>
                                )
                              }

                              {
                                imageUrl && (
                                  <div className="div">
                                    <p className='removeimg imgcursor px-1' onClick={() => { handleimage() }}>-</p>
                                  </div>
                                )
                              }

                            </div>
                            <div className="col-lg-6">
                              <button className='btn btn-primary bgcolor fontsize12 px-5 py-2 float-right' >Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                )
              }
              {
                followuptoggle && (
                  <form onSubmit={addfollowup}>
                    <div className="row bgwhite px-4 pt-3">
                      <div className="fullheight col-lg-12">
                        <div className="col-lg-12">
                          <div className="d-flex mt-5 ">
                            <input type="text" className='w-100 input_fields' placeholder='Message' value={message_text} onChange={(e) => { setmessagetext(e.target.value) }} required />
                            <div className="div mt-2 form_icons_set">
                              <img src={thirdicon} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex mt-5 dropplaceholder">
                            <select name='status' value={message_type} className='input_fields w-100' onChange={(e) => { setmessagetype(e.target.value) }} required >
                              <option className='fontsize8' value="" disabled selected hidden>Message Type</option>
                              {
                                todo_status?.map((curele) => {
                                  return <><option value={curele.type}>{curele.status}</option></>
                                })
                              }
                            </select>

                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex mt-5 ">
                            <DatePicker autoComplete='off' dateFormat='dd-MM-yyyy' className='w-100 input_fields' minDate={new Date()} selected={getdate2} onChange={date => setdate2(date)} placeholderText='Date of Visit' required />
                            <div className="div mt-2 form_icons_set">
                              <img src={seventhicon} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex mt-5 ">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                className='w-100 input_fields'
                                value={value2}
                                ampm={false}
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                onChange={(newValue) => {
                                  setValue2(newValue);
                                }}
                                inputProps={{
                                  placeholder: "Time of Visit"
                                }}
                                InputProps={{
                                  disableUnderline: true
                                }}
                                renderInput={(params) => <TextField  {...params} variant="standard" onClick={(e) => setOpen(true)} autoComplete="off" />}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="col-lg-12 mt-4 ">
                          <button className='btn btn-primary bgcolor fontsize12 px-5 py-2 float-right' >Save</button>
                        </div>
                      </div>
                    </div>
                  </form>
                )
              }
              <div className={popuptoggle ? 'open-popup bgwhite popup' : 'popup bgwhite'}>
                <div className="">
                  <div className="col-lg-12 text-center my-4">
                    <img src={logo2} alt="" />
                  </div>
                  <p className='col-lg-12 text-center navcolor font-weight-bold'>Follow-Up Scheduled</p>
                  <p className='col-lg-12 text-center navcolor font-weight-bold'>Successfully!</p>
                </div>
              </div>
              <div className={visitlogtoggle ? 'open-popup bgwhite popup' : 'popup bgwhite'}>
                <div className="">
                  <p className='col-lg-12 text-center navcolor font-weight-bold my-4'>Do You want to create To-Do for the same clinic.</p>
                  <div className="row">
                  <p className='col-lg-7 pl-5 navcolor font-weight-bold imgcursor' onClick={()=>{togglefollowupnew()}}>Yes</p>
                  <p className='col-lg-5 pl-5 navcolor font-weight-bold imgcursor float-right' onClick={()=>{navigate('/team')}}>No</p>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> */}
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <img src={imageUrl} alt="" className='img-fluid' />
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Visitlog