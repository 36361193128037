import React from 'react'
import Sidebar from '../../components/Sidebar'
import reportsicon from '../../icons/document (1) 1.png'
import trackicon from '../../icons/gps-navigation 1.png'
import reportsicon2 from '../../icons/document 1.png'
import trackicon2 from '../../icons/gps-navigation (1) 1.png'
import visitlog from '../../icons/visitlog.png'
import searchlogo from '../../icons/search-interface-symbol 1.png'
import calendericon from '../../icons/calendericon.png'
import imageicon from '../../icons/profile.png'
import { useState } from 'react'
import { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import moment from 'moment/moment'
import Googlemap from './Googlemap'
import downloadicon from '../../icons/download 1.png'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router'
// import { LocalizationProvider } from '@mui/x-date-pickers-pro';
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
// import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
// import TextField from '@mui/material/TextField';

// import Box from '@mui/material/Box';
const Team = () => {
  const location = useLocation()
  const user_id = location?.state?.userid
  const fullname = location?.state?.fullname
  const pp = location?.state?.profile_pic
  const user = JSON.parse(localStorage.getItem('user'))
  const custom_fiels = user.user.custom_fields
  var visit_status 
  for(let i=0;i<custom_fiels.length;i++){
    if(custom_fiels[i].key==='visit_status'){
      visit_status = custom_fiels[i]?.value
   }
  }
  const userid = user?.user?.id
  const fname = user?.user?.f_name
  const lname = user?.user?.l_name
  const profile_pic = user?.user?.profile_pic
  const [formtoggle, setformtoggle] = useState(true)
  const [followuptoggle, setfollowuptoggle] = useState(false)
  const [visitlogtoggle, setvisitlogtoggle] = useState(false)
  const [organization, setorganization] = useState([])
  const [visitloglist, setvisitloglist] = useState([])
  const [visitlogs, setvisitlogs] = useState([])
  // const [latlong, setlatlong] = useState([])
  // const [customerid, setcustomerid] = useState('')
  const [searchterm, setsearchterm] = useState('')
  const [getdate, setdate] = useState('')
  const [loading, setloading] = useState('')

  const [imagetoggle, setimagetoggle] = useState(false)
  // const [visitstatus, setvisitstatus] = useState([])
  const [messagetype, setmessagetype] = useState('')
  const [daterangetoggle, setdaterangetoggle] = useState(false)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const toggleform = () => {
    setformtoggle(true)
    setfollowuptoggle(false)
    setvisitlogtoggle(false)
  }
  const togglefollowup = () => {
    setformtoggle(false)
    setfollowuptoggle(true)
    setvisitlogtoggle(false)
  }
  const toggleimage = (img) => {
    setimagetoggle(img)
  }
  const togglevisitlog = (customer_id) => {
    setvisitlogtoggle(true)
    setformtoggle(false)
    setfollowuptoggle(false)
    // setcustomerid(customer_id)
    getvisitloglist(customer_id)
  }
  const filtered = !searchterm
    ? visitloglist.sort(function (a, b) {
      if (a.customer_name.toLowerCase() < b.customer_name.toLowerCase()) {
        return -1;
      }
      if (a.customer_name.toLowerCase() > b.customer_name.toLowerCase()) {
        return 1;
      }

    })
    : visitloglist.filter((item) =>
      item.customer_name.toLowerCase().includes(searchterm.toLowerCase())
    ).sort(function (a, b) {
      if (a.customer_name.toLowerCase() < b.customer_name.toLowerCase()) {
        return -1;
      }
      if (a.customer_name.toLowerCase() > b.customer_name.toLowerCase()) {
        return 1;
      }

    });
  const toggledaterangepicker = async (e) => {
    setdaterangetoggle(item=> !item)
      //  if(value[0].$d){
      //   downloadreport()
      //  }
  }
  const downloadreport = async()=>{
    const token = user?.token
    const userid = user.user.id
    const start = moment(startDate).isValid() ? moment(startDate).format('yyyy-MM-DD') : ''
    const end = moment(endDate).isValid() ? moment(endDate).format('yyyy-MM-DD') : ''
     if(!end){
      toast.error("Please select end date!", {
        position: "top-center"
      });
    }
     else if(start===end){
      toast.error("start date and end date are same!", {
        position: "top-center"
      });
    }
     else if(start && end){
          await axios.get(`/get-visit-details-excel?user_id=${ user_id ? user_id : userid}&start=${start}&end=${end}`, {
    responseType: "arraybuffer", headers: {
      "Content-Type": "blob",
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const fileURL = URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", "Report.xlsx");
    document.body.appendChild(link);
    link.click();
    setdaterangetoggle(false)
  }).catch((error) => {
    console.log(error)
  })
    }
    else{
      toggledaterangepicker()
    }
  }
  const getvisitloglist = async (customer_id) => {
    const token = user.token
    const userid = user?.user?.id
    const date = moment(getdate).isValid() ? moment(getdate).format('yyyy-MM-DD') : ''
    await axios.get(`/get-visit-details?customer_id=${customer_id}&user_id=${user_id ? user_id : userid}&visited_on=${date}&status=${messagetype}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      setvisitlogs(response.data.data)
    })
  }
  useEffect(() => {

    const getvisitlog = async () => {
      const token = user.token
      await axios.get(`/get-visit-log-report?user_id=${user_id ? user_id : userid}`, {
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response) => {
        setorganization(response.data.organization)
        console.log(response.data.organization)
      })
    }

    const visitlog = async () => {
      const token = user.token
      const date = moment(getdate).isValid() ? moment(getdate).format('yyyy-MM-DD') : ''
      await axios.get(`/get-visit-all-report?user_id=${user_id ? user_id : userid}&visited_on=${date}&status=${messagetype}`, {
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response) => {
        setvisitloglist(response.data.data)
        setloading(true)
      })
    }
    // const customerstatus = async () => {
    //   const token = user.token
    //   const response = await axios.get('/visit-status', {
    //     headers: { "Authorization": `Bearer ${token}` }
    //   })
    //   const result = response.data.data
    //   setvisitstatus(result)
    // }
    getvisitlog()
    visitlog()
  }, [getdate,messagetype])
  return (
    <>
      <div className="sidebarflexproperty">
        <div className="div">
          <Sidebar />
        </div>
        <div className="dashboard col">
          <div className="row p-1 nav_bar">
            <div className="mx-4">
              <p className='font-weight-bold visit_log fontsize16 py-2 navcolor'>View Reports</p>
            </div>
            <div className="col-lg-10">
              <div className="d-flex">
                <input className='form-control ' type="text" placeholder='Search Clinics' value={searchterm} onChange={(e) => { setsearchterm(e.target.value) }} />
                <div className="px-2 searchbg pt-1  pl-2">
                  <img className='' src={searchlogo} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="row bgwhite border_bottom">
            <div className="d-flex col-lg-12 p-2 pb-3">
              <div className=" ">
                <img className='m-auto imgview' src={pp ? pp : profile_pic} alt="" />
              </div>
              <div className="name col-lg-9 pt-2">
                <p className=''>{fullname ? fullname : fname+ ' ' + lname }</p>
              </div>
              <div className="d-flex imgcursor " onClick={toggleform}  style={{alignItems:'center'}}>
                <div className="div ">
                  {
                    formtoggle ? <img src={reportsicon} alt="" /> : <img src={reportsicon2} alt="" />
                  }
                  
                </div>
                <p className={formtoggle ? 'fontcolorblue ml-2' : 'ml-2 greycolor'}>Reports</p>
              </div>
              <div className=" d-flex imgcursor mx-4" onClick={togglefollowup} style={{alignItems:'center'}}>
                <div className="div ">
                  {
                    followuptoggle ? <img src={trackicon2} alt="" /> : <img src={trackicon} alt="" />
                  }
                  
                </div>
                <div className="div ">
                  <p className={followuptoggle ? 'fontcolorblue ml-1 mt-1' : 'ml-1 mt-1 greycolor'}>Track</p>
                </div>
              </div>
              
            </div>

          </div>
          {
            formtoggle && (
              <>
                <div className="row ">
                  {
                    organization?.map((cval) => {
                      return <>
                        <div className="col-lg-12">
                          <p className='navcolor fontsize14 font-weight-bold m-1 pl-1'>{cval.node_name}</p>
                          <div className="d-flex border bgwhite m-2 col-lg-5">
                            <div className="p-2">
                              <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : imageicon} alt="" />
                            </div>
                            <div className="div col-lg-8 ">
                              <p className='fontsize12 pt-3'>{cval.f_name + ' ' + cval.l_name}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    })
                  }
                  <div className="col-lg-5">
                    <p className='m-3 fontcolorblue '>Reports</p>
                  </div>
                  <div className="col-lg-3 ">
                          <div className="dropplaceholder">
                            <select name='status' value={messagetype}  className='input_fields w-100' onChange={(e) => { setmessagetype(e.target.value) }} required>
                              <option className='fontsize8' value='' disabled  hidden selected  >Status</option>
                              {
                                visit_status?.map((curele) => {
                                  return <><option value={curele.type}>{curele.status}</option></>
                                })
                              }
                            </select>
                          </div>
                        </div>
                  <div className="col-lg-2">
                    <div className="d-flex viewreportz-index">
                      <DatePicker autoComplete='off' className=' input_fields ' selected={getdate} onChange={date => setdate(date)} placeholderText='Select Date' required maxDate={new Date()}/>
                      <div className="div pt-2 pl-3 calendericon">
                        <img src={calendericon} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="ml-5 pl-3 text-center imgcursor mb-1 mt-2" onClick={(e) => { toggledaterangepicker(e) }}>
                    <div className="">
                      <img className=' px-3' src={downloadicon} alt="" />
                    </div>
                    <div className="">
                      <p className='fontsize8 fontcolorblue float-right'>Download Report</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                    </div>
                    <div className="col-lg-6 ">
                 
                    </div>
                  </div>
                  <div className="tableheight w-100 abc">
                    <div className="sticky ">
                      <table className="table mb-0 table-md table-bordered " >
                        <thead>
                          <tr className='height10'>
                            <th className='bgcolor' scope="col">S.No.</th>
                            <th className='bgcolor' scope="col" >Clinic Name</th>
                            <th className='bgcolor' scope="col" >Current Status</th>
                            <th className='bgcolor' scope="col">Visit</th>
                            <th className='bgcolor' scope="col">Visit log</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            filtered.length === 0 && loading ? <tr className='height10 bgwhite'><td className='fontsize10' colSpan='8'>No Result Found!</td></tr> : visitloglist && filtered?.map((cval, index) => {
                              return <>
                                <tr className='height10 bgwhite' key={index}>
                                  <td className='fontsize10'>{index + 1}</td>
                                  <td className='fontsize10'>{cval.customer_name}</td>
                                  <td className='fontsize10'>{cval.status === 'N' ? 'Not Intrested' : cval.status === 'D' ? 'Closed' : cval.status === 'O' ? 'Demo' :  cval.status === 'F' ? 'Follow-Up' : cval.status === 'I' ? 'Introduction' : cval.status === 'G' ? 'Negotiation' :'Renewal'}</td>
                                  <td className='fontsize10'>{cval.no_of_visits}</td>
                                  <td onClick={() => { togglevisitlog(cval.customer_id) }} ><img src={visitlog} alt="" className='imgcursor' /></td>
                                </tr>
                              </>
                            })
                          }
                        </tbody>
                      </table>
                  <div className="div float-right my-2 daterangepicker">
                      {
                    daterangetoggle && (
                      <>
<DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      maxDate={new Date()}
      selectsRange
      inline
    />
    <div className="d-flex">
    <button className='btn btn-primary w-100 mx-1' onClick={()=>{toggledaterangepicker()}}>Close</button>
    <button className='btn btn-primary w-100 mx-1' onClick={()=>{downloadreport()}}>Download Report</button>
    </div>
                      </>
                    )
                  }
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }
          {
            followuptoggle && (
              <>
                <div className="row ">
                  <div className="mapstyle " >
                    {
                      <Googlemap /> 
                      }
                  </div>
                </div>
              </>
            )
          }
          {
            visitlogtoggle && (
              <>
                <div className=" border_bottom col-lg-12">
    
                  <div className="heightvisitlog">
                    {
                      visitlogs.length!==0 ? visitlogs.slice(0).reverse().map((cval) => {
                        return <>
                          <div className="bordervisit">
                            <div className="row p-2">
                              <div className="col-lg-6">
                                <p className='my-2'>Contact Person</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor float-right my-2'>{cval.contact_person}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Contact </p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.phone_no}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Email</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.email_id}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Subscription Model</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.subs_model_name}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>No of Visits</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.no_of_visits}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Price</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.price}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Date/Time Of Visit</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.visited_on}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>status</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.status === 'N' ? 'Not Intrested' : cval.status === 'D' ? 'Closed' : cval.status === 'O' ? 'Demo' :  cval.status === 'F' ? 'Follow-Up' : cval.status === 'I' ? 'Introduction' : cval.status === 'G' ? 'Negotiation' :'Renewal'}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Comments</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.visitor_comments}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Photo</p>
                              </div>
                              <div className="col-lg-6" >
                                {
                                  cval.image ? <>
                                  <img className='imageresize imgcursor float-right' src={cval.image} alt="" onClick={() => { toggleimage(cval.image) }} data-toggle="modal" data-target="#exampleModalCenter"/>
                                  {/* {
                                    toggle === cval.visit_id && toggle ? <span className={toggle === cval.visit_id && toggleimage ? 'closebtn imgcursor' : ''} onClick={() => { toggleimage(cval.visit_id) }}>X</span> :''
                                  } */}
                                  </>
                                  : <><p className='navcolor my-2 float-right'>NA</p></>
                                }
                            
                              </div>
                            </div>

                          </div>
                        </>
                      })
                            :
                            <>
                            <p className='greycolor errormessage font-weight-bold'>No Visit-Log Found</p>
                            </>
                    }
                  </div>
                </div>
              </>

            )
          }
        </div>
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> */}
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <img src={imagetoggle} alt="" className='img-fluid' />
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Team;

