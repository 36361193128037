import React from 'react'
import { useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import addicon from '../../../icons/Vector (26).png'
import axios from 'axios'
import dropicon from '../../../icons/Vector (23).png'
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react'
import upicon from '../../../icons/Vector (34).png'
import down from '../../../icons/Vector (35).png'
import searchlogo from '../../../icons/search-interface-symbol 1.png'
import imageicon from '../../../icons/profile.png'
import rebicon from '../../../icons/return 1.png'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import timeline2 from '../../../icons/Vector (46).png'
import timeline3 from '../../../icons/Vector (47).png'
import searchicon from '../../../icons/ri-search-line.png'
import milageicon from '../../../icons/speedometer 1.png'
import gifticon from '../../../icons/gift-box 1.png'
import Switch from 'react-switch'
import deleteicon from '../../../icons/Vector (42).png'
import '../../../App.css'

const Settings = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const admin_task_login = user.user.org_tasks
  var orgtask = []
  for(let i=0;i<admin_task_login.length;i++){
     if(admin_task_login[i].type==='Admin'){
      orgtask.push(admin_task_login[i])
     }
  }
  const admin_roles = user.user.admin_assign_role
  const [newplantoggle, setnewpagetoggle] = useState(false)
  const [mainpage, setmainpagetoggle] = useState(true)
  const [breadcrum, setbreadcrum] = useState('')
  const [newrolepage, setnewrolepage] = useState(false)
  const [newdepartment, setnewdepartment] = useState(false)
  const [dept_name, setdeptname] = useState('')
  const [toggle, settoggle] = useState('')
  // const [toggle2,settoggle2] = useState('')
  const [toggle3, settoggle3] = useState('a')
  const [departments, setdepartments] = useState([])
  const [deptid, setdeptid] = useState('')
  const [defaultvalue, setdefaultvalue] = useState('')
  const [rolelist, setrole] = useState([])
  const [tempdeptid, settempdeptid] = useState('')
  const [active, setactive] = useState(0)
  const [plan, setplan] = useState([])
  const [planfeatures, setplanfeatures] = useState([])
  const [historyplan, sethistoryplan] = useState([])
  const [temparray, settempdept] = useState([])
  const [toggledropdown, settoggledropdown] = useState(false)
  const [toggledropdown2, settoggledropdown2] = useState(false)
  const [permissiontoggle, setpermissiontoggle] = useState(false)
  const [claimpage, setclaimpage] = useState(false)
  const [claimsection, setclaimsection] = useState(true)
  const [togglesection, settogglesection] = useState(true)
  const [searchterm, setsearchterm] = useState('')
  const [employee_list, setemplist] = useState([])
  const [org_tasklist, setorgtask] = useState([])
  const [claimtypearr, setclaimtypearr] = useState([])
  const [claim_subtypearr, setclaim_subtype] = useState([])
  const [category, setcategory] = useState('')
  const [admin_id, setIs] = useState('')
  const [claimdata, setclaimdata] = useState({})
  const [searchemp, setsearchemp] = useState([])
  const [transfertoggle, settransfertoggle] = useState(false)
  const [customtoggle, setcustomtoggle] = useState(false)
  const [state, setstate] = useState([])
  const [permission_listassign, setpermission_listassign] = useState([])
  const [timelinedata, settimelinedata] = useState([])
  const [claim_type, setclaim_type] = useState('')
  const [searchtoggle, setsearchtoggle] = useState(false);
  const [formdata, setfromdata] = useState({
    node_name: '', parent_node_id: '', child_node_id: ''
  })
  const perroute = admin_roles?.find(obj => obj.route === '*-permissions')
  const orgroute = admin_roles?.find(obj => obj.route === '*-organization')
  const followuproute = admin_roles?.find(obj => obj.route === '*-follow-up')
  const subscriptionroute = admin_roles?.find(obj => obj.route === '*.subscription')
  const handleChange = (nextChecked) => {
    setorgtask(org_tasklist.map((std) => {
      if (std.id === nextChecked) {
        return { ...std, case: !std.case }
      }
      else {
        return std
      }
    }))
  };
  const handleChange2 = (nextChecked) => {
    setpermission_listassign(permission_listassign.map((std) => {
      if (std.id === nextChecked) {
        return { ...std, case: !std.case }
      }
      else {
        return std
      }
    }))
  };
  const permissionsfn = () => {
    const switchtoggle = []
    const token = user.token
    for (let i = 0; i < org_tasklist?.length; i++) {
      if (org_tasklist[i].case === true) {
        switchtoggle.push(org_tasklist[i].id)
      }
    }
    for (let i = 0; i < permission_listassign?.length; i++) {
      if (permission_listassign[i].case === true) {
        switchtoggle.push(permission_listassign[i].id)
      }
    }
    //   const taskid =  switchtoggle.map(str=>{
    //     return parseInt(str)
    //   })
    const task_id = switchtoggle.toString()
    console.log(switchtoggle)
    axios.post('/admin-v1-add-user-task', {admin_id, task_id }, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      if (response.data.statusCode === 200) {
        toast.success("Permissions Updated Successfully", {
          position: "top-center"
        });
      }
    })
  }
  const handlebreadcrum = () => {
    setfromdata({
      node_name: '', parent_node_id: '', child_node_id: ''
    })
    setbreadcrum('')
    setnewpagetoggle(false)
    setmainpagetoggle(true)
    setnewrolepage(false)
    setnewdepartment(false)
    setpermissiontoggle(false)
    settogglesection(true)
    setclaimpage(false)
    setclaimsection(true)
    setcustomtoggle(false)
    setsearchterm('')
    setsearchemp([])
  }
  const addclaimfieldfn = (name, id, tag) => {
    setbreadcrum(name)
    setclaimpage(true)
    setnewpagetoggle(false)
    setmainpagetoggle(true)
    setnewrolepage(false)
    setnewdepartment(false)
    setpermissiontoggle(false)
    settogglesection(true)
    setclaimsection(false)
    timeline(id)
    setclaimdata({ name, id })
    claim_subtype(name + "_sub_type")
    dynamicfields(tag)
    setclaim_type(tag)

  }
  const dynamicfields = (tag) => {
    var addfieldstemp = []
    const token = user.token
    axios.get(`/get-v1-claim-additional-fields?claim_type=${tag}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      const result = response.data.data
      console.log(result)
      for (let i = 0; i < result?.length; i++) {
        if (result[i].field1_name !== null) {
          addfieldstemp.push({ field_name: result[i].field1_name, field_resp: result[i].field1_resp, field_type: result[i].field1_type ? result[i].field1_type : 'textfield', value: null,search_val:'' })
        }
        if (result[i].field2_name !== null) {
          addfieldstemp.push({ field_name: result[i].field2_name, field_resp: result[i].field2_resp, field_type: result[i].field2_type ? result[i].field2_type : 'textfield', value: '',search_val:'' })
        }
        if (result[i].field3_name !== null) {
          addfieldstemp.push({ field_name: result[i].field3_name, field_resp: result[i].field3_resp, field_type: result[i].field3_type ? result[i].field3_type : 'textfield', value: '',search_val:'' })
        }
        if (result[i].field4_name !== null) {
          addfieldstemp.push({ field_name: result[i].field4_name, field_resp: result[i].field4_resp, field_type: result[i].field4_type ? result[i].field4_type : 'textfield', value: '',search_val:'' })
        }
        if (result[i].field5_name !== null) {
          addfieldstemp.push({ field_name: result[i].field5_name, field_resp: result[i].field5_resp, field_type: result[i].field5_type ? result[i].field5_type : 'textfield', value: '',search_val:'' })
        }
      }
      setstate(addfieldstemp)
    })
  }
  const addform = ()=>{
    if(state?.length>4){
      toast.error("Limit exceeded",{
        position: "top-center"
      })
    }
    else{
      let newfield = { field_name: '', field_type: 'textfield',field_resp:'',value:'',search_val:'' }
      setstate([...state, newfield])
    }
    
  }
  const deleteform = (index)=>{
    const token = user.token
    let data = [...state];
    data.splice(index, 1)
    setstate(data)
      if(data[0]?.field_name!==null){
        var field1_name = data[0]?.field_name
        var field1_type = data[0]?.field_type
        var field1_resp = data[0]?.field_resp
      }
      if(data[1]?.field_name!==null){
        var field2_name = data[1]?.field_name
        var field2_type = data[1]?.field_type
        var field2_resp = data[1]?.field_resp
      }
      if(data[2]?.field_name!==null){
        var field3_name = data[2]?.field_name
        var field3_type = data[2]?.field_type
        var field3_resp = data[2]?.field_resp
      }
      if(data[3]?.field_name!==null){
        var field4_name = data[3]?.field_name
        var field4_type = data[3]?.field_type
        var field4_resp = data[3]?.field_resp
      }
      if(data[4]?.field_name!==null){
        var field5_name = data[4]?.field_name
        var field5_type = data[4]?.field_type
        var field5_resp = data[4]?.field_resp
      }
    axios.post('/admin-v1-claim-additional-field',{claim_type,field1_name,field1_type,field1_resp,field2_name,field2_type,field2_resp,field3_name,field3_resp,field3_type,field4_name,field4_resp,field4_type,field5_name,field5_resp,field5_type},{
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response)=>{
      console.log(response.data)
       if(response.data.statusCode===200){
        toast.success("custom field deleted successfully", {
          position: "top-center"
        });
       }
    })
  }
  const handleFormChange = (index, event,type,userid) => {
    let data = [...state]; 
    if(event?.target?.name){
      data[index][event.target.name] = event.target.value;
    }
    else{
      data[index].field_resp = userid
    }
    if(event?.target?.name==='search_val' && state[index].search_val.length>2){
      searchuser(index)
    }
    setstate(data);
    console.log(data)
 }
 const adduserid = (userid,index)=>{
  const data = [...state]
  data[index].field_resp = userid
  data[index].search_val = ''
  setstate(data);
  toast.success('Employee Selected',{
    position: "top-center"
  })
  setsearchemp([])
 }
 const createcustomfields = (e)=>{
  e.preventDefault()
  const token = user.token
    let data = [...state]
  if(state.length===0){
    toast.error("Please create a field", {
      position: "top-center"
    });
  }
  else{
    if(data[0]?.field_name!==null){
      var field1_name = data[0]?.field_name
      var field1_type = data[0]?.field_type
      var field1_resp = data[0]?.field_resp
    }
    if(data[1]?.field_name!==null){
      var field2_name = data[1]?.field_name
      var field2_type = data[1]?.field_type
      var field2_resp = data[1]?.field_resp
    }
    if(data[2]?.field_name!==null){
      var field3_name = data[2]?.field_name
      var field3_type = data[2]?.field_type
      var field3_resp = data[2]?.field_resp
    }
    if(data[3]?.field_name!==null){
      var field4_name = data[3]?.field_name
      var field4_type = data[3]?.field_type
      var field4_resp = data[3]?.field_resp
    }
    if(data[4]?.field_name!==null){
      var field5_name = data[4]?.field_name
      var field5_type = data[4]?.field_type
      var field5_resp = data[4]?.field_resp
    }
    console.log(field1_resp,field2_resp)
    axios.post('/admin-v1-claim-additional-field',{claim_type,field1_name,field1_type,field1_resp,field2_name,field2_type,field2_resp,field3_name,field3_resp,field3_type,field4_name,field4_resp,field4_type,field5_name,field5_resp,field5_type},{
   headers: { "Authorization": `Bearer ${token}` }
 }).then((response)=>{
    if(response.data.statusCode===200){
     toast.success(response.data.message, {
       position: "top-center"
     });
    }
   })
  }
  
}
  const filtered = !searchterm ? employee_list : employee_list.filter((val) =>
    val.fullname.toLowerCase().includes(searchterm.toLowerCase())
  )
  const handlenewrolepage = () => {
    setbreadcrum('New Role')
    setnewpagetoggle(false)
    setmainpagetoggle(false)
    setnewrolepage(true)
    setnewdepartment(false)
    setpermissiontoggle(false)
    settogglesection(false)
    setclaimpage(false)

  }
  const handleadddepartment = () => {
    setbreadcrum('New Department')
    setnewpagetoggle(false)
    setmainpagetoggle(false)
    setnewrolepage(false)
    setnewdepartment(true)
    setpermissiontoggle(false)
    settogglesection(false)
    setclaimpage(false)
  }
  const searchuser = (index) => {
    const token = user.token
    axios.get(`/get-unsign-user-search?search_user=${state[index]?.search_val}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      setsearchemp(response.data.data)
    })
  }
  const searchuser2 = () => {
    const token = user.token
    axios.get(`/get-unsign-user-search?search_user=${searchterm}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      setsearchemp(response.data.data)
      setsearchtoggle(true)
    }).catch((error)=>{
      toast.error("Enter Employee name", {
        position: "top-center"
      });
    })
  }
  const handlenewrole = (e) => {
    const newdata = { ...formdata }
    newdata[e.target.name] = e.target.value
    setfromdata(newdata)
  }
  const handlerolesubmit = (e) => {
    e.preventDefault()
    const token = user.token
    const { node_name, parent_node_id, child_node_id } = formdata;
    if (parent_node_id === child_node_id && rolelist.length !== 0) {
      toast.error("Positions can't be same!", {
        position: "top-center"
      });
    }
    else {
      if (rolelist.length !== 0) {
        const dept_id = deptid ? deptid : tempdeptid
        axios.post('/admin-v1-create-role', { node_name, parent_node_id, child_node_id, dept_id }, {
          headers: { "Authorization": `Bearer ${token}` }
        }).then((response) => {
          if (response.data.statusCode === 200) {
            toast.success(response.data.message, {
              position: "top-center"
            });
          }
          setfromdata({
            node_name: '', parent_node_id: '', child_node_id: ''
          })
          rolefn(dept_id)
          setfromdata({
            node_name: '', parent_node_id: '', child_node_id: ''
          })
          setbreadcrum('')
          setnewpagetoggle(false)
          setmainpagetoggle(true)
          setnewrolepage(false)
          setnewdepartment(false)
          setpermissiontoggle(false)
          settogglesection(true)
          setclaimpage(false)
        }).catch((error) => {
          if (error.response.data.statusCode === 401) {
            toast.error(error.response.data.message, {
              position: "top-center"
            });
          }
        })
      }
      else {
        const dept_id = deptid ? deptid : tempdeptid
        axios.post('/admin-v1-create-role', { node_name, dept_id }, {
          headers: { "Authorization": `Bearer ${token}` }
        }).then((response) => {
          if (response.data.statusCode === 200) {
            toast.success(response.data.message, {
              position: "top-center"
            });
          }
          setfromdata({
            node_name: '', parent_node_id: '', child_node_id: ''
          })
          rolefn(dept_id)
          setfromdata({
            node_name: '', parent_node_id: '', child_node_id: ''
          })
          setbreadcrum('')
          setnewpagetoggle(false)
          setmainpagetoggle(true)
          setnewrolepage(false)
          setnewdepartment(false)
          setpermissiontoggle(false)
          settogglesection(true)
          setclaimpage(false)
        }).catch((error) => {
          if (error.response.data.statusCode === 401) {
            toast.error(error.response.data.message, {
              position: "top-center"
            });
          }
        })
      }
    }
  }
  const buttonactive = (deptname, index) => {
    setactive(index)
    setdeptid(deptname)
  }

  const handlechange = (e) => {
    const token = user.token
    const visit_approve = e.target.value
    axios.post('/admin-v1-visit-auto-approve', { visit_approve }, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      if (response.data.statusCode === 200) {
        toast.success(response.data.message, {
          position: "top-center"
        });
      }
      approvevalue()
    }).catch((error) => {
      console.log(error)
    })
  }

  const handledepartmentsubmit = (e) => {
    e.preventDefault();
    const token = user.token
    axios.post('/admin-v1-create-department', { dept_name }, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      if (response.data.statusCode === 200) {
        toast.success("Department Added", {
          position: "top-center"
        });
      }
      setdeptname('')
    }).catch((error) => {
      if (error.response.data.statusCode === 401) {
        toast.error(error.response.data.message, {
          position: "top-center"
        });
      }
    })
  }
  const rolefn = (deptname) => {
    const token = user.token
    axios.get(`/get-v1-org-user-role-name?dept_id=${deptid ? deptid : deptname}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      setrole(response.data.data)
    })
  }
  const approvevalue = () => {
    const token = user.token
    axios.get('/get-v1-visit-auto-approve', {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      setdefaultvalue(response.data.data)
    })
  }
  const handletoggle = () => {
    settoggledropdown(prev => !prev)
  }
  const handletoggle2 = (index) => {
    settoggledropdown2((prev) => {
      return prev === index ? null : index;
    });
  }
  const permissionslist = (id, name) => {
    setbreadcrum(name)
    setnewpagetoggle(false)
    setmainpagetoggle(true)
    setnewrolepage(false)
    setnewdepartment(false)
    setpermissiontoggle(true)
    settogglesection(false)
    setclaimpage(false)
    setIs(id)
    const token = user.token
    const assigntrue = []
    axios.get(`/admin-v1-get-org-assign-task?user_id=${id}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      const result = response.data
      console.log(result.admin_task)
      const combinedarray = [...result.admin_task]
      console.log(orgtask)
      for (let i = 0; i < orgtask?.length; i++) {
        var count = 0
        for (let j = 0; j < combinedarray?.length; j++) {
          if (orgtask[i].task_name === combinedarray[j].task_name) {
            count = count + 1

          }
        }
        if (count === 0) {
          assigntrue.push(orgtask[i])
        }
      }
      const newarray = assigntrue.map(object => {
        return { ...object, case: false };
      });
      setpermission_listassign(result.admin_task)
      setorgtask(newarray)
      // console.log(result.admin_task)
      // console.log(newarray)
    })
  }
  const handlesubs = () => {
    settoggle('a')
    setpermissiontoggle(false)
    setbreadcrum('')
    settogglesection(true)
    setclaimpage(false)
    setclaimsection(true)
    setcustomtoggle(false)
            setsearchterm('')
        setsearchemp([])
  }
  const handleorg = () => {
    settoggle('b')
    setpermissiontoggle(false)
    setbreadcrum('')
    settogglesection(true)
    setclaimpage(false)
    setclaimsection(true)
    setcustomtoggle(false)
            setsearchterm('')
        setsearchemp([])

  }
  const handlefollow = () => {
    settoggle('c')
    setpermissiontoggle(false)
    setbreadcrum('')
    settogglesection(true)
    setclaimpage(false)
    setclaimsection(true)
    setcustomtoggle(false)
            setsearchterm('')
        setsearchemp([])
  }
  const handlere = () => {
    settoggle('d')
    setpermissiontoggle(false)
    setbreadcrum('')
    settogglesection(true)
    setclaimpage(false)
    setclaimsection(true)
    setcustomtoggle(false)
  }
  const handleper = () => {
    settoggle('e')
    setpermissiontoggle(false)
    setbreadcrum('')
    settogglesection(true)
    setclaimpage(false)
    setclaimsection(true)
    setcustomtoggle(false)
            setsearchterm('')
        setsearchemp([])
  }
  const handlesubtype = (e) => {
    e.preventDefault()
    const token = user.token
    const key = breadcrum + "_sub_type"
    const temp = claim_subtypearr[0]?.value
    var toggle=0
    for(let i=0;i<temp.length;i++){
      if(temp[i]?.type.toLowerCase()===category.toLowerCase()){
         toggle=1
      }
    }
    if(toggle===0){
      var value = [...temp, { "type": category }]
      axios.post('/create-customer-dynamic-field', { key, value }, {
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response) => {
        if (response.data.statusCode === 200) {
          toast.success("Category Added Successfully", {
            position: "top-center"
          });
          claim_subtype(key)
          setcategory('')
        }
      })
    }
    else{
      toast.error("Category Already Exists",{
        position: "top-center"
      });
    }




  }
  const deletesubtype = (index) => {
    const token = user.token
    const key = breadcrum + "_sub_type"
    const temp = claim_subtypearr[0]?.value
     temp.splice(index, 1)
     var value = temp
    axios.post('/create-customer-dynamic-field', { key, value }, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      if (response.data.statusCode === 200) {
        toast.success("Category Deleted Successfully", {
          position: "top-center"
        });
        claim_subtype(key)
        setcategory('')
      }
    })
  }
  const claim_subtype = (name) => {
    const token = user.token
    axios.get(`/get-v1-user-claim-subtype?key=${name}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      const result = response.data.data
      setclaim_subtype(result)

    })
  }
  const toggleaddapprover = () => {
    settransfertoggle(true)
  }
  const addapprrover = (approver_id) => {
    const token = user.token
    const claim_type = claimdata.id
    const claim_name = claimdata.name
    axios.post('/admin-v1-add-claim-approver', { approver_id, claim_name, claim_type }, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      if (response.data.statusCode === 200) {
        toast.success(response.data.message, {
          position: "top-center"
        });

        timeline(claim_type)
        setsearchterm('')
        setsearchemp([])
      }
    })
  }
  const Addhierarchy = () => {
    const token = user.token
    const claim_type = claimdata.id
    const claim_name = claimdata.name
    const approver_id = ''
    axios.post('/admin-v1-add-claim-approver', { approver_id, claim_name, claim_type }, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      if (response.data.statusCode === 200) {
        toast.success(response.data.message, {
          position: "top-center"
        });
        timeline(claim_type)
        handleclosemodal()
      }
    })
  }
  const timeline = (id) => {
    const token = user.token
    axios.get(`/get-v1-claim-approver-name?claim_type=${id}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      settimelinedata(response.data.data)
    })
  }
  const customfieldsadd = () => {
    setcustomtoggle(true)
    setclaimpage(false)
    setbreadcrum(breadcrum + '< Additional Requirements')
  }
  const handleclosemodal = ()=>{
    setsearchterm('')
    setsearchemp([])
    // searchuser()
    setsearchtoggle(false)
    settransfertoggle(false)
  }
  useEffect(() => {
    const departmentlist = () => {
      const token = user.token
      var temp = []
      axios.get('/get-v1-org-department-name', {
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response) => {
        const result = response.data.data
        setdepartments(result)
        temp = result.sort((a, b) => {
          if (a.dept_name.toLowerCase() < b.dept_name.toLowerCase()) {
            return -1;
          }
          if (a.dept_name.toLowerCase() > b.dept_name.toLowerCase()) {
            return 1;
          }
        })
        settempdept(temp)
        rolefn(result[0].dept_id)
        settempdeptid(result[0].dept_id)
      })
    }
    const subscription_plans = () => {
      const token = user.token
      axios.get('/get-v1-subscription-plans', {
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response) => {
        const result = response.data.data
        const features = response.data.features
        setplan(result)
        setplanfeatures(features)
      })
    }
    const history_plans = () => {
      const token = user.token
      axios.get('/get-v1-subscription-plan-list', {
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response) => {
        const result = response.data.data

        sethistoryplan(result)
      })
    }
    const emplist = () => {
      const token = user.token
      axios.get('/admin-v1-org-user-list', {
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response) => {
        const result = response.data.data
        setemplist(result)

      })
    }
    const claimtype = () => {
      const token = user.token
      axios.get('/admin-v1-get-claim-type', {
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response) => {
        const result = response.data.data
        setclaimtypearr(result)

      })
    }
    departmentlist()
    approvevalue()
    subscription_plans()
    history_plans()
    emplist()
    claimtype()
  }, [deptid])
  return (
    <>
      <div className="sidebarflexproperty">
        <div>
          <Sidebar />
        </div>
        <div className="divouterdashboard">
          <div className="dashboard col">
            <div className="row p-1 nav_bar">
              <div className="mx-4 d-flex">
                <p className='font-weight-bold navcolor fontsize16 py-2 imgcursor breadcrumstyle' onClick={handlebreadcrum}>Settings {' '}</p>
                <p className='font-weight-bold navcolor fontsize16 pt-2 imgcursor breadcrumstyle' onClick={handlebreadcrum}>{toggle === 'a' ? '<' + ' ' + 'Subscription Plans' : toggle === 'b' ? '< Organization' : toggle === 'c' ? '< Follow-Up' : toggle === 'd' ? '< Claim' : toggle === 'e' ? '< Permissions' : ''}  </p>
                <p className='font-weight-bold navcolor fontsize16 py-2 imgcursor breadcrumstyle' onClick={handlebreadcrum}>{toggle === 'b' && toggle3 === 'a' ? '< Manage Departments' : toggle === 'b' && toggle3 === 'b' ? '< Organization Hierarchy' : toggle === 'b' && toggle3 === 'c' ? '< Organization Overview' : ''}  </p>
                <p className='font-weight-bold navcolor fontsize16 py-2 imgcursor breadcrumstyle'>{breadcrum.length !== 0 ? ' < ' + breadcrum : ''}  </p>
              </div>
            </div>
            <div className="d-flex  ">
              {
                mainpage && (
                  <>
                    <div className="setting_sidemenu bgwhite  p-3">
                      {
                        subscriptionroute ? <div className=" border mb-3 imgcursor" onClick={() => { handlesubs() }}>
                          <p className={toggle === 'a' ? 'py-2 brhalf locationtoggledesign fontsize12 pl-2' : 'pl-2 brhalf py-2 bgwhite greycolor fontsize12'}>Subscription Plans</p>
                        </div> : ''
                      }

                      {
                        orgroute ? <div className=" border mb-3 imgcursor" onClick={() => { handleorg() }}>
                          <p className={toggle === 'b' ? 'py-2 brhalf locationtoggledesign fontsize12 pl-2' : 'py-2 brhalf bgwhite greycolor pl-2 fontsize12'}>Organization</p>
                        </div> : ''
                      }
                      {
                        followuproute ? <div className=" border mb-3 imgcursor" onClick={() => { handlefollow() }}>
                          <p className={toggle === 'c' ? 'py-2 brhalf locationtoggledesign fontsize12 pl-2' : 'pl-2 brhalf py-2 bgwhite greycolor fontsize12'}>Follow-Up</p>
                        </div> : ''
                      }

                      <div className=" border mb-3 imgcursor" onClick={() => { handlere() }}>
                        <p className={toggle === 'd' ? 'py-2 brhalf locationtoggledesign fontsize12 pl-2' : 'pl-2 brhalf py-2 bgwhite greycolor fontsize12'}>Claim</p>
                      </div>
                      {
                        perroute ? <div className=" border mb-3 imgcursor" onClick={() => { handleper() }}>
                          <p className={toggle === 'e' ? 'py-2 brhalf locationtoggledesign fontsize12 pl-2' : 'pl-2 brhalf py-2 bgwhite greycolor fontsize12'}>Permissions</p>
                        </div> : ''
                      }

                    </div>
                  </>
                )
              }
              <div className="col-lg-9">
                {
                  togglesection && (
                    <>
                      {
                        toggle === 'a' && (
                          <>
                            <div className="row">
                              {/* <div className="col-lg-12  my-4">
                <button className='navcolor bgwhite btn btn-primary float-right' onClick={newplanpage}>New Plan</button>
            </div> */}
                              <div className="col-lg-12 mt-3">
                                <p className='fontsize8 greycolor pl-1'>Active Plan</p>
                              </div>
                            </div>
                            <div className="row">
                              {
                                plan.length !== 0 ? plan?.map((cval, index) => {
                                  return <>
                                    <div className="col-lg-12  " key={index}>
                                      <div className="border">
                                        <div className="d-flex py-2 locationtoggledesign forgot" onClick={handletoggle}>
                                          <div className="col-lg-9">
                                            <p className='navcolor fontsize12 font-weight-bold'>{cval.plan_name} <span className='fontsize10 font-weight-normal'>({cval.active_user} Active Users)</span> </p>
                                            <p className='navcolor fontsize12'>Rs. {cval.price}</p>
                                            <p className='navcolor fontsize10'>Your Plan Expires on: {cval.next_billing_date}</p>
                                            {
                                              toggledropdown ? < >
                                                {
                                                  planfeatures?.map((val) => {
                                                    return <>
                                                      <p className='fontsize10'>&#x2022;  {val.feature_name}</p>
                                                    </>
                                                  })
                                                }
                                              </> : ''
                                            }
                                          </div>
                                          {/* <div className="col-lg-3">
                            <button className='bgcolor btn btn-primary mt-2 float-right'>View Invoice</button>
                         </div> */}
                                          <div className="col-lg-3 ">
                                            {
                                              toggledropdown ? <img className='float-right' src={down} alt="" /> : <img className='float-right' src={upicon} alt="" />
                                            }

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                }) : <><p className='pl-3 font-weight-bold navcolor'>No Active Plan!</p></>
                              }
                            </div>
                            <div className="row">
                              <div className="col-lg-12 mt-3">
                                <p className='fontsize8 greycolor pl-1'>History</p>
                              </div>
                              {
                                historyplan?.map((cval, index) => {
                                  return <>
                                    <div className="col-lg-12 mb-2 ">
                                      <div className="border bgwhite greyborder">
                                        <div className="d-flex py-2  forgot" onClick={() => { handletoggle2(index) }}>
                                          <div className="col-lg-9">
                                            <p className=' greycolor fontsize12 font-weight-bold'>{cval.plan_name} <span className='fontsize10 font-weight-normal'>({cval.active_user} Active Users)</span> </p>
                                            <p className=' greycolor fontsize12'>Rs. {cval.price}</p>
                                            <p className=' greycolor fontsize10'>Your Plan Expires on: {cval.expires_on}</p>
                                            {
                                              toggledropdown2 === index ? < >
                                                {
                                                  cval.features.map((val) => {
                                                    return <>
                                                      <p className='fontsize10'>&#x2022;  {val.feature_name}</p>
                                                    </>
                                                  })
                                                }
                                              </> : ''
                                            }
                                          </div>
                                          {/* <div className="col-lg-3">
                            <button className='bgcolor btn btn-primary mt-2 float-right'>View Invoice</button>
                         </div> */}
                                          <div className="col-lg-3 ">
                                            {
                                              toggledropdown2 ? <img className='float-right' src={down} alt="" /> : <img className='float-right' src={upicon} alt="" />
                                            }

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                })
                              }

                            </div>
                          </>
                        )
                      }
                      {
                        toggle === 'b' && (
                          <>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="d-flex pt-3">
                                  <button className={toggle3 === 'a' ? 'btn btn-primary bgcolor px-3  fontsize10' : 'btn btn-primary navcolor bgwhite px-3 fontsize10'} onClick={() => { settoggle3('a') }}>Manage Departments</button>
                                  <button className={toggle3 === 'b' ? 'btn btn-primary bgcolor px-3 ml-4  fontsize10' : 'btn btn-primary navcolor bgwhite px-3 ml-4 fontsize10'} onClick={() => { settoggle3('b') }}>Organization Hierarchy</button>
                                  {/* <button className={toggle3 === 'c' ? 'btn btn-primary bgcolor px-3 ml-4  fontsize10' : 'btn btn-primary navcolor bgwhite px-3 ml-4 fontsize10'} onClick={() => { settoggle3('c') }}>Organization Overview</button> */}
                                </div>
                              </div>
                            </div>
                            {
                              toggle3 === 'a' && (
                                <>
                                  <div className="row mt-5">
                                    <div className="col-lg-12 ">
                                      <div className="d-flex">
                                        <div className="settingheight  scrollview sticky bgwhite tablewidth ">
                                          <table className="table table-bordered table-sm ">
                                            <thead>
                                              <tr>
                                                <th className='bgcolor' scope="col">S.No.</th>
                                                <th className='bgcolor' scope="col">Name</th>
                                              </tr>
                                            </thead>
                                            <tbody >
                                              {
                                                temparray && temparray.map((cval, index) => {
                                                  return <>
                                                    <tr>
                                                      <td className='tablecellwidth'> {index + 1}</td>
                                                      <td>{cval.dept_name}</td>
                                                    </tr>
                                                  </>
                                                })
                                              }
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className="div">
                                          <div className=' bgwhite px-2 py-1 adddepartmentbtn imgcursor ml-2 d-flex' onClick={() => { handleadddepartment() }}>
                                            <div>
                                              <img src={addicon} alt="" />
                                            </div>
                                            <p className='navcolor fontsize10 pl-2 font-weight-bold pt-1'>Add Department</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            }

                            {
                              toggle3 === 'b' && (
                                <>
                                  <div className="row mt-4">
                                    <div className="col-lg-9">
                                      <div className="department-list departmentscroll ">
                                        {
                                          departments && departments.map((cval, index) => {
                                            return <><button className={active === index ? 'btn-primary btn bgcolor  font-weight-bold' : 'btn-primary btn font-weight-bold empactivebtn'} onClick={() => { buttonactive(cval.dept_id, index) }} key={index}>{cval.dept_name}</button></>
                                          })}
                                      </div>
                                    </div>
                                    <div className="col-lg-3  ">
                                      <div className="  float-right  "  >
                                        <div className="d-flex p-1 px-3 brhalf bgwhite imgcursor fixwidth" onClick={() => { handlenewrolepage() }}>
                                          <div className="div">
                                            <img src={addicon} alt="" />
                                          </div>
                                          <div className="div ">
                                            <p className='fontsize10 p-1 navcolor font-weight-bold'>New Role</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12 ">
                                      <div className=" mt-4 scrollview sticky bgwhite positionheight">
                                        <table className="table table-bordered table-sm ">
                                          <thead>
                                            <tr>
                                              <th className='bgcolor' scope="col">S.No.</th>
                                              <th className='bgcolor' scope="col">Role Name</th>
                                            </tr>
                                          </thead>
                                          <tbody >
                                            {
                                              rolelist.length !== 0 ?
                                                rolelist && rolelist.sort((a, b) => {
                                                  if (a.node_name.toLowerCase() < b.node_name.toLowerCase()) {
                                                    return -1;
                                                  }
                                                  if (a.node_name.toLowerCase() > b.node_name.toLowerCase()) {
                                                    return 1;
                                                  }
                                                }).map((cval, index) => {
                                                  return <>
                                                    <tr>
                                                      <td className='tablecellwidth'> {index + 1}</td>
                                                      <td>{cval.node_name}</td>
                                                    </tr>
                                                  </>
                                                })
                                                : <>
                                                  <tr>
                                                    <td colSpan={2}>No Result Found!</td>
                                                  </tr>
                                                </>

                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                          </>
                        )}
                      {
                        toggle === 'c' && (
                          <>
                            <div className="row bgwhite p-3" >
                              <div className="col-lg-9">
                                <div className="d-flex">
                                  <input type="radio" name="followup" value='True' checked={defaultvalue === true} onChange={handlechange} id="" />
                                  <p className='navcolor fontsize12 font-weight-bold pl-2'>Auto Approve</p>
                                </div>
                                <p className='navcolor fontsize8 pl-4 pt-2'>Enabling this option auto-approves follow requests for your organization's members, eliminating the need for manual approval.</p>
                              </div>
                              <div className="col-lg-9 mt-2">
                                <div className="d-flex">
                                  <input type="radio" name="followup" id="" value='False' checked={defaultvalue === false} onChange={handlechange} />
                                  <p className='navcolor fontsize12 font-weight-bold pl-2'>Manual Approve</p>
                                </div>
                                <p className='navcolor fontsize8 pl-4 pt-2'>Enabling this option requires manual approval of follow-up requests for your organization's members by respective managers,
                                  providing an added layer of control and accountability.</p>
                              </div>
                            </div>
                          </>
                        )
                      }
                      {
                        toggle === 'd' && (
                          <>
                            {
                              claimsection && (<>
                                <div className="row">
                                  <div className="col-lg-12 d-flex">
                                  <div className='text-center bgwhite p-3 boxsize_claim' onClick={() => { addclaimfieldfn(claimtypearr[0]?.name, claimtypearr[0]?.id, claimtypearr[0]?.tag) }}>
                                            <div>
                                              <img src={milageicon} alt="" />
                                            </div>
                                            <p className='fontsize12 mt-2'>{claimtypearr[0]?.name}</p>
                                          </div>
                                  <div className='text-center bgwhite p-3 boxsize_claim' onClick={() => { addclaimfieldfn(claimtypearr[1]?.name, claimtypearr[1]?.id, claimtypearr[1]?.tag) }}>
                                            <div>
                                              <img src={gifticon} alt="" />
                                            </div>
                                            <p className='fontsize12 mt-2'>{claimtypearr[1]?.name}</p>
                                          </div>
                                  <div className='text-center bgwhite p-3 boxsize_claim' onClick={() => { addclaimfieldfn(claimtypearr[2]?.name, claimtypearr[2]?.id, claimtypearr[2]?.tag) }}>
                                            <div>
                                              <img src={rebicon} alt="" />
                                            </div>
                                            <p className='fontsize12 mt-2'>{claimtypearr[2]?.name}</p>
                                          </div>
                                    {/* {
                                      claimtypearr?.map(cval => {
                                        return <>
                                          <div className='text-center bgwhite p-3 boxsize_claim' onClick={() => { addclaimfieldfn(cval.name, cval.id, cval.tag) }}>
                                            <div>
                                              <img src={rebicon} alt="" />
                                            </div>
                                            <p className='fontsize12 mt-2'>{cval.name}</p>
                                          </div>
                                        </>
                                      })
                                    } */}
                                  </div>
                                </div>
                              </>)
                            }
                            {
                              claimpage && (<>
                                <div className="settingclaimheight">
                                  <div className="row mb-3">
                                    <div className="col-lg-12">
                                      <p className='pt-3  font-wight-bold'>Category</p>
                                    </div>
                                    <form action="" className='col-lg-12' onSubmit={handlesubtype}>
                                      <div className="col-lg-12 mt-3">
                                        <input type="text" name="" className='input_field_css w-100 pb-1' placeholder='Add Category' value={category} required onChange={(e) => { setcategory(e.target.value) }} />
                                      </div>
                                      <div className="col-lg-12 mt-3">
                                        <input type='submit' className='bgcolor btn btn-primary fontsize10' />
                                      </div>
                                    </form>
                                  </div>
                                  {
                                    claim_subtypearr[0]?.value?.map((cval,index) => {
                                      return <>

                                        <div className="row ml-0 bgwhite border_bottom ">
                                          <div className="col-lg-6  py-1">
                                            <p className='fontsize12'> {cval.type}</p>
                                          </div>
                                          <div className="col-lg-6 ">
                                  <div className='float-right' onClick={()=>{deletesubtype(index)}}>
                                    <img src={deleteicon} alt="" className='mr-3' />
                                  </div>
                            </div>
                                        </div>
                                      </>
                                    })
                                  }
                                  <div className="row">
                                    <div className="col-lg-6 mt-2">
                                      <p className='font-weight-bold ' >Additional Requirement</p>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 ">
                                        <p className='fontsize10 pl-3'>If you require any kind of additional document or have any
                                          additional requirements in order to approve this reimbursement claim then you can add your requirements here.</p>
                                      </div>
                                    </div>
                                    <div className='d-flex ml-3 mt-3 px-2 add_req_btn imgcursor' >
                                      <div>
                                        <img src={addicon} alt="" width={15} />
                                      </div>
                                      <p className='navcolor fontsize10 pt-1 pl-2' onClick={() => { customfieldsadd() }}>Additional Requirements</p>
                                    </div>
                                  </div>
                                  {/* {
                                    transfertoggle && (<>
                                    <div className="row">
                                      <div className="col-lg-12 my-2 mb-3">
                                        <div className="d-flex">
                                          <input className='form-control ' type="text" placeholder='Search Members' value={searchterm} onChange={(e) => { setsearchterm(e.target.value) }} />
                                          <div className="px-2 searchbg pt-1  pl-2 imgcursor " >
                                            <img className='' src={searchlogo} alt="" onClick={() => {
                                              searchuser2()
                                            }} />
                                          </div>
                                        </div>
                                        <p className='fontsize8 m-0 pt-2'>Choose the users to whom you would like to grant access </p>
                                      </div>
                                      <div className="col-lg-12">
                                        {
                                          searchemp?.map((cval) => {
                                            return <>
                                              <div className="div border bgwhite mb-2 col-lg-7  ">
                                                <div className="d-flex border_bottom mx-2">
                                                  <div className=" pl-2 pt-2 pb-2">
                                                    <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : imageicon} alt="" />
                                                  </div>
                                                  <div className="col-lg-6">
                                                    <p className='fontsize12 pt-3'>{cval.fname + ' ' + cval.lname}</p>
                                                  </div>
                                                  <div className=" w-100 pt-3 pr-3">
                                                    <h6 className='navcolor font-weight-bold float-right imgcursor' onClick={() => { addapprrover(cval.userid) }} >Add</h6>
                                                  </div>

                                                </div>
                                              </div>
                                            </>
                                          })
                                        }
                                      </div>
                                      </div>
                                    </>)
                                  } */}
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <p className='pt-3  font-wight-bold'>Approver</p>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="d-flex ml-3 imgcursor" onClick={() => { toggleaddapprover() }} data-toggle="modal" data-target="#exampleModalCenter2">
                                        <div>
                                          <img src={timeline2} alt="" />
                                        </div>
                                        <p className='fontsize12 greycolor ml-3'>Add Approver</p>
                                      </div>
                                      <Timeline
                                        sx={{
                                          [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                          },
                                        }}>
                                        {
                                          timelinedata?.map((val, index) => {
                                            return <>
                                             
                                              <TimelineItem>
                                                <TimelineSeparator>
                                                  <div>
                                                    <img src={timeline3} alt="" width={18} />
                                                  </div>
                                                  {
                                                    timelinedata.length === index + 1 ? '' : <TimelineConnector sx={{ backgroundColor: '#0d67a2' }} />
                                                  }

                                                </TimelineSeparator>
                                                <TimelineContent><p className='fontsize12'>{val.approver_full_name} </p>
                                                 <p className='fontsize10'>Level{" "}{index+1}</p>
                                                  <p className='fontsize12 navcolor font-weight-bold'>{val.decision}</p></TimelineContent>
                                              </TimelineItem>
                                            </>
                                          })
                                        }
                                      </Timeline>
                                    </div>
                                  </div>
                                </div>
                              </>)
                            }

                          </>
                        )
                      }
                      {
                        toggle === 'e' && (
                          <>
                            <div className="row">
                              <div className="col-lg-12 my-2 mb-3">
                                <div className="d-flex">
                                  <input className='form-control ' type="text" placeholder='Search Members' value={searchterm} onChange={(e) => { setsearchterm(e.target.value) }} />
                                  <div className="px-2 searchbg pt-1  pl-2 imgcursor " >
                                    <img className='' src={searchlogo} alt="" />
                                  </div>
                                </div>
                                <p className='fontsize8 m-0 pt-2'>Choose the users to whom you would like to grant access </p>
                              </div>
                            </div>
                            <div className='employeelistheight'>
                              {
                                filtered?.length === 0 ? <><p className='greycolor'>No Employee Found</p></> :
                                  filtered?.map((cval) => {
                                    return <>
                                      <div className="col-lg-12 imgcursor mt-2" onClick={() => {permissionslist(cval.id, cval.fullname) }}>
                                        <div className="border bgwhite">
                                          <div className="d-flex">
                                            <div className="p-1 pl-3">
                                              <img className=' imgview' src={cval.profile_pic ? cval.profile_pic : imageicon} alt="" />
                                            </div>
                                            <p className=' pl-2 greycolor fontsize12' style={{ paddingTop: 12 }}>{cval.fullname}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  })
                              }
                            </div>

                          </>
                        )
                      }
                      {
                        customtoggle && (<>
                          <form action="" onSubmit={createcustomfields}>
                            <div className="row">
                              <div className="col-lg-10 fieldsheight">
                                {
                                  state?.map((cval, index) => {
                                    return <>
                                      <div className="d-flex ">
                                        <div className="border bgcolor mt-3 w-100">
                                          <div className="innerdivadmin bgwhite inputfocus  clearfix">
                                            <div className=" py-2 col ">
                                              <input type="text" className='input_fields  w-100' name="field_name" id="" value={cval.field_name} placeholder='Type your question here' onChange={event => handleFormChange(index, event, cval)} autoComplete='off' required />
                                            </div>
                                            <div className="d-flex col">
                                  <input name='search_val' className='form-control ' autoComplete='off' type="text" readOnly={cval.field_resp?.length===0 ? false : true} placeholder='Search Members' value={cval.search_val} onChange={event => handleFormChange(index, event, cval)} />
                                  <div className="px-2 searchbg pt-1  pl-2 imgcursor " onClick={()=>{searchuser(index)}}>
                                    <img className='' src={searchlogo} alt="" />
                                  </div>
                                </div>
                                {
                                       state[index]?.search_val?.length===0 ? '' : state[index]?.search_val.length!==0 &&  searchemp?.length===0 ? <>
                                       <div className="col-lg-12">
                                       <p className='greycolor fontsize12 font-weight-bold mt-1'>No Employee Found</p>
                                        </div></> :    searchemp?.map((cval) => {
                                            return <>
                                              <div className="div   my-2 col  ">
                                                <div className="border d-flex ">
                                                  <div className="p-1 ">
                                                    <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : imageicon} alt="" />
                                                  </div>
                                                  <div className="col-lg-6">
                                                    <p className='fontsize12 namemargin' style={{color:'black'}}>{cval.fname +' '+ cval.lname}</p>
                                                  </div>
                                                  <div className=" w-100 namemargin pr-3">
                                                    <h6 className='navcolor font-weight-bold float-right imgcursor' onClick={()=>{adduserid(cval.userid,index)}} >Add</h6>
                                                  </div>
                                                </div>
                                           
                                              </div>
                                            </>
                                          })
                                        }
                                            {/* <div className="col d-flex posrel">
                                              <div>
                                                <img src={searchicon} alt="" width={20} className='searchiconcss' />
                                              </div>
                                              <input type="text" placeholder='search user' className='form-control searchbardynamic' />
                                            </div> */}
                                            {/* {
                                              cval.field_type !== 'checkbox' ? <div className="col py-2 col">
                                                <input className='input_fields w-100' name="hint_text" id="" value={cval.hint_text} placeholder='Hint text' onChange={event => handleFormChange(index, event, cval)} autoComplete='off' required />
                                              </div> : ''
                                            } */}
                                            <div className="filter col-lg-12 ">
                                              <div className="col-lg-5">
                                              </div>
                                              <div className="d-flex  mt-2 mb-2 dropplaceholder">
                                                <select name='field_type' className='form-control  w-50 ' value={cval.field_type} onChange={event => handleFormChange(index, event, cval)}>
                                                  <option value={cval.field_type} hidden disabled>{cval.field_type === 'checkbox' ? 'Check Box' : cval.field_type === 'date' ? 'Date' : 'Short Answer'}</option>
                                                  <option value="textfield" selected >Short Answer</option>
                                                  <option value="checkbox">Check Box</option>
                                                  <option value="date">Date</option>
                                                </select>
                                                <div className="px-2 searchbg2 pt-1 ">
                                                  <img className='pl-1' src={dropicon} alt="" />
                                                </div>
                                                {/* {
                                                  cval.field_type === 'checkbox' ? '' : <>  <p className='navcolor font-weight-bold pt-2 mx-3'>Required</p>
                                                    <div className='pt-2 '>
                                                      <Switch
                                                        name='required_type'
                                                        onChange={event => handleFormChange(index, event, cval)}
                                                        checked={cval.required_type}
                                                        onColor="#1988C5"
                                                        handleDiameter={12}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        height={18}
                                                        width={40}
                                                        className="react-switch "
                                                      />
                                                    </div>

                                                  </>
                                                } */}
                                                <div className=" w-100 ">
                                                  <div className="float-right">
                                                    <div className="border bgwhite mt-2 imgcursor   mr-3" onClick={() => { deleteform(index) }}>
                                                      <img src={deleteicon} alt="" className='m-2 ' />
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </>
                                  })
                                }
                                {
                                  state?.length > 0 ? <div className="div">
                                    <div className="col-lg-12 mt-3">
                                      <button className='btn btn-primary bgcolor  float-right'>Save</button>
                                    </div>
                                  </div> : ''
                                }

                              </div>

                              <div className="col-lg-2">
                                <div className="border bgwhite d-flex imgcursor mt-3" onClick={() => { addform() }}>
                                  <div>
                                    <img src={addicon} alt="" className='m-1 pl-1' height={13} />
                                  </div>
                                  <p className='fontsize10 pt-1 navcolor font-weight-bold'>Add Form</p>
                                </div>
                              </div>
                            </div>
                          </form>
                        </>)
                      }
                    </>
                  )
                }
                {
                  permissiontoggle && (
                    <>
                      <div className=" row">
                        <div className="d-block w-100 permissionsheight scrollview">
                          <>
                            {
                              permission_listassign?.map((val) => {
                                return <>
                                  <div className="border_bottom d-flex bgwhite imgcursor">
                                    <p className='greycolor py-2 col-lg-5 pl-3 fontsize12'>{val.task_name}</p>
                                    <div className='col-lg-7 pt-2'>
                                      <Switch
                                        onColor="#1988C5"
                                        onChange={() => { handleChange2(val.id) }}
                                        checked={val.case}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={14}
                                        width={30}
                                        id={`react-switch-new${val.id}`}
                                        className="react-switch float-right"
                                      />
                                    </div>
                                  </div>
                                </>
                              })
                            }
                            {
                              org_tasklist?.map((val) => {
                                return <>
                                  <div className="border_bottom d-flex bgwhite imgcursor">
                                    <p className='greycolor py-2 col-lg-5 pl-3 fontsize12'>{val.task_name}</p>
                                    <div className='col-lg-7 pt-2'>
                                      <Switch
                                        onColor="#1988C5"
                                        onChange={() => { handleChange(val.id) }}
                                        checked={val.case}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={14}
                                        width={30}
                                        className="react-switch float-right"
                                      />
                                    </div>
                                  </div>
                                </>
                              })

                            }
                            {/* {
                              permission_list?.map((val) => {
                                return <>
                                  <div className="border_bottom d-flex bgwhite imgcursor">
                                    <p className='greycolor py-2 col-lg-5 pl-3 fontsize12'>{val.task_name}</p>
                                    <div className='col-lg-7 pt-2'>
                                      <Switch
                                        onColor="#1988C5"
                                        onChange={() => { handleChange(val.id) }}
                                        checked={val.case}
                                        disabled
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={14}
                                        width={30}
                                        className="react-switch float-right"
                                      />
                                    </div>
                                  </div>
                                </>
                              })
                            } */}
                          </>
                        </div>
                      </div>
                      <div className="row savebtncss">
                        <div className="col-lg-12 imgcursor" onClick={() => { permissionsfn() }}>
                          <p className='bgcolor d-inline px-3 py-1 brhalf'>Save</p>
                        </div>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
            {
              newdepartment && (
                <>
                  <div className="row">
                    <div className="col-lg-7 ">
                      <form action="" onSubmit={handledepartmentsubmit}>
                        <p className='fontsize12 navcolor mt-2 font-weight-bold'>New Department</p>
                        <input className='form-control mt-3' placeholder='Department Name' type="text" value={dept_name} onChange={(e) => { setdeptname(e.target.value) }} />
                        <button className='btn-primary mt-3 btn bgcolor float-right'>Submit</button>
                      </form>
                    </div>
                  </div>
                </>
              )
            }

            {
              newrolepage && (
                <>
                  <form action="" onSubmit={handlerolesubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <p className='fontsize18 pt-2 font-weight-bold navcolor'>New Role</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-7 mt-3">
                        <input name='node_name' type="text" className='form-control' autoComplete='off' placeholder='Role Name' value={formdata.node_name} onChange={(e) => { handlenewrole(e) }} />
                      </div>
                    </div>
                    {
                      rolelist.length !== 0 ? <>
                        <div className="row">
                          <div className="col-lg-7 mt-3 " >
                            <div className=" dropplaceholder">
                              <select name='parent_node_id' className=' form-control' value={formdata.parent_node_id} onChange={(e) => { handlenewrole(e) }} required>
                                <option className='fontsize8' value="" selected hidden disabled>Reporting To</option>
                                {
                                  rolelist && rolelist.map((cval) => {
                                    return <>
                                      <option value={cval.node_id}>{cval.node_name}</option>
                                    </>
                                  })
                                }
                              </select>
                            </div>
                            <div className="div mt-2 float-right font-italic">
                              <p className='fontsize8'>When creating a role, it is necessary to choose the appropriate upline position.</p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-7 mt-3 dropplaceholder">
                            <select name='child_node_id' className='form-control' onChange={(e) => { handlenewrole(e) }} required>
                              <option value="" selected hidden disabled>Reporting By</option>
                              {
                                rolelist && rolelist.map((cval) => {
                                  return <>
                                    <option value={cval.node_id}>{cval.node_name}</option>
                                  </>
                                })
                              }
                            </select>
                            <div className="div mt-2 float-right font-italic">
                              <p className='fontsize8'>When creating a role, it is necessary to choose the appropriate subsequent position.</p>
                            </div>
                          </div>
                        </div></> : ''
                    }
                    <div className="row">
                      <div className="col-lg-7 mt-3 ">
                        <button className='bgcolor btn btn-primary float-right'>Save</button>
                      </div>
                    </div>
                  </form>
                </>
              )
            }

          </div>
        </div>
        <div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle2" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Add Approver</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>{handleclosemodal()}} >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                                  <>
                                  <div className="row">

                                    <div className="col-lg-12 my-2 mb-3">
                                      <div className="d-flex">
                                        <input
                                          className="form-control "
                                          type="text"
                                          placeholder="Search Members"
                                          value={searchterm}
                                          onChange={(e) => {
                                            setsearchterm(e.target.value);
                                          }}
                                        />
                                        <div className="px-2 searchbg pt-1  pl-2 imgcursor ">
                                          <img
                                            className=""
                                            src={searchlogo}
                                            alt=""
                                            onClick={() => {
                                              searchuser2();
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {/* <p className='fontsize8 m-0 pt-2'>Choose the users to whom you would like to grant access </p> */}
                                    </div>
                                  </div>
                                    <div className="col-lg-12">
                                      {searchemp.length===0 && searchtoggle ? <><p className="greycolor font-weight-bold">No Employee Found!</p></> : searchemp?.map((cval) => {
                                        return (
                                          <>
                                          <div className="row">
                                            <div className="div border bgwhite mb-2 col-lg-12  ">
                                              <div className="d-flex border_bottom mx-2">
                                                <div className=" pl-2 pt-2 pb-2">
                                                  <img
                                                    className="m-auto imgview"
                                                    src={
                                                      cval.profile_pic
                                                        ? cval.profile_pic
                                                        : imageicon
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="col-lg-6">
                                                  <p className="fontsize12 pt-3">
                                                    {cval.fname +
                                                      " " +
                                                      cval.lname}
                                                  </p>
                                                </div>
                                                <div className=" w-100 pt-3 pr-3">
                                                  <h6
                                                    className="navcolor font-weight-bold float-right imgcursor"
                                                    onClick={() => { addapprrover(cval.userid) }}
                                                    data-dismiss="modal"
                                                  >
                                                    Add
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </>
                              
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={()=>{Addhierarchy()}}>Add Hierarchy</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={()=>{handleclosemodal()}}>Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
export default Settings