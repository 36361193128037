import React from 'react'
import Sidebar from '../../components/Sidebar'
import warningicon from '../../icons/caution 1.png'

const Notifications = () => {
  return (
    <div className="sidebarflexproperty">
        <div className="div">
            <Sidebar/>
        </div>
        <div className="dashboard">
        <div className="p-3 nav_bar">
            <div className="mx-2 ">
              <p className='font-weight-bold visit_log fontsize16 py-2 d-inline navcolor'>Notifications</p>
            </div>
         </div>
         <div className="">
            <div className="d-flex border bgwhite m-3">
            <div className="pt-2 pl-3">
                <img src={warningicon} alt="" />
            </div>
            <div className="col-lg-10 pt-2 fontsize12  mt-1">
                <p>Please don’t switch off your device or uninstall the app, this would give an impression of you being inactive</p>
                </div>
            </div>
         </div>
         <div className="">
            <div className="d-flex border bgwhite m-3">
            <div className="pt-2 pl-3">
                <img src={warningicon} alt="" />
            </div>
            <div className="col-lg-10 pt-2 fontsize12 pt-2 mt-1">
                <p>Please don’t switch off your device or uninstall the app, this would give an impression of you being inactive</p>
                </div>
            </div>
         </div>
         <div className="">
            <div className="d-flex border bgwhite m-3">
            <div className="pt-2 pl-3">
                <img src={warningicon} alt="" />
            </div>
            <div className="col-lg-10 pt-2 fontsize12 pt-2 mt-1">
                <p>Please don’t switch off your device or uninstall the app, this would give an impression of you being inactive</p>
                </div>
            </div>
         </div>
        </div>
    </div>
  )
}

export default Notifications