import React from 'react'
import { useState } from 'react'
import title from '../../icons/SALES TRACKY (2).png'
import logo from "../../icons/logo.png"
import loginlogo from "../../icons/Group 1043.png"
import eyeicon from "../../icons/Vector (4).png"
import {  useLocation } from 'react-router-dom'
// import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast,ToastContainer } from 'react-toastify'
// import { ToastContainer, toast } from 'react-toastify';
// import { useNavigate } from 'react-router'

const Setnewpassword = () => {
   const user = JSON.parse(localStorage.getItem('user'))
    const location = useLocation();
    const navigate = useNavigate();
    const email_id = location?.state?.email
    const otp = location?.state?.otp
    const [reset, setreset] = useState({
        newPassword: "",
        confirmPassword: ""
      })
      const handleinput = (e) => {
        const newdata = { ...reset }
        newdata[e.target.name] = e.target.value;
        setreset(newdata)
      }
      const forgetpassword = async(e)=>{
        try {
          e.preventDefault()
          if(user){
            const token = user.token
            const {newPassword,confirmPassword} = reset
            const response  = await axios.post('/change-password',{newPassword,confirmPassword},{
              headers: { "Authorization": `Bearer ${token}` }
            })
            if(response.data.statusCode===200){
                  toast.success("Password Changed",{
                    position: "top-center"
                  })
             }
          }
          else{
           
              const {newPassword,confirmPassword} = reset
              const response  = await axios.post('/reset-password',{otp,email_id,newPassword,confirmPassword})
              if(response.data.statusCode===200){
                    navigate('/')
               }
          }
        } catch (error) {
            
        }
      }
  return (
    <>
    <div className="outer_div p-5 ">
 <div className="row inner_div m-3">
  <div className="col-lg-6">
          <div className="d-flex ">
            <div className="m-3">
          <img className='' src={logo} alt="" />
            </div>
            <div className="my-4">
          <img className='' src={title} alt="" />
            </div>
          </div>
          <div className="title m-4 ">
            <h2>{user ? 'Change Password' : 'Reset Password'} </h2>
          </div>
           <form onSubmit={forgetpassword}  >
             <div className="col-lg-12">
              <label htmlFor="" className='greycolor'>New Password<span className='redcolor'>*</span> </label> <br />
              <div className="d-flex">
              <input name='newPassword' className='input_field form-control' type="password" onChange={handleinput} value={reset.newPassword} required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"/>
              <div className="mt-1 iconshift">
               <span><img  src={eyeicon} alt="" /></span> 
              </div>
              </div>
             </div>
             <div className="col-lg-12 pt-3">
              <label htmlFor="" className='greycolor'>Confirm New Password<span className='redcolor'>*</span> </label> <br />
              <div className="d-flex">
                <input name='confirmPassword' className='input_field form-control ' type="password" onChange={(e) => {
            handleinput(e)
          }} value={reset.confirmPassword} required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"/>
                <div className="mt-1 iconshift">
                 <span><img  src={eyeicon} alt="" /></span> 
                </div>
              </div>
             </div>
             <div className="col-lg-12 mt-2">
                <p className='fontsize10'>Password should contain minimum 8 charachters, atleast one Uppercase, 
one lowercase, one digit & one special charchter</p>
             </div>
             <div className="col-lg-12 mt-3 pt-4">
             <button className='btn btn-primary w-100 bgcolor'  type='submit'>Save</button>
             </div>
             {
              user ?     <div className="col-lg-12 text-center">
              <p className='navcolor fontsize12 pt-1 forgot' onClick={()=>{navigate(-1)}}> {'<'} Back to Home</p>
              </div> : ''
             }
        
           </form>
          </div>
        <div className="col-lg-6">
            <div className="login-img">
              <img className='loginimgcss img-fluid'  src={loginlogo} alt="" />
            </div>
        </div>
        </div>
      </div>
      <ToastContainer />
      </>
  )
}

export default Setnewpassword