import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import formicon from '../../../icons/Vector (21).png'
import formicongrey from '../../../icons/Vector (22).png'
import dropicon from '../../../icons/Vector (23).png'
import addicon from '../../../icons/addition-thick-symbol.png'
import deleteicon from '../../../icons/Vector (25).png'
import Switch from 'react-switch'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
export const ManageForms = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const token = user?.token
  const customfields = user?.user?.custom_fields
   const values =[]
   for(let i=0;i<customfields?.length;i++){
      if(customfields[i].key==='customer_fields'){
          values.push(customfields[i]?.value)
      }
   }
    const [tagtoggle,settagtoggle] = useState('a')
    const [state,setstate] = useState(values[0] || [])
    // const handleChange = nextChecked => {
    //   setChecked(nextChecked);
    // };
    const addform = ()=>{
      if(state?.length>4){
        toast.error("Limit exceeded",{
          position: "top-center"
        })
      }
      else{
        let newfield = { field_name: '', field_type: 'text',hint_text:'',required_type: false }
        setstate([...state, newfield])
      }
      
    }
    const handleFormChange = (index, event,type) => {
      let data = [...state]; 
      if(event?.target?.name){
        console.log('hello')
        data[index][event.target.name] = event.target.value;
      }
      else{
        console.log('2nd')
        data[index].required_type = event
      }
      
      setstate(data);
      console.log(data)
   }
   const createcustomfields = (e)=>{
      e.preventDefault()
      const customer_fields = state
      if(state.length===0){
        toast.error("Please create a field", {
          position: "top-center"
        });
      }
      else{
        axios.post('admin-v1-create-custom-fields',{customer_fields},{
       headers: { "Authorization": `Bearer ${token}` }
     }).then((response)=>{
       console.log(response.data)
        if(response.data.statusCode===200){
         toast.success(response.data.message, {
           position: "top-center"
         });
        }
        localStorage.removeItem(user)
        for( let i=0;i<user.user.custom_fields.length;i++){
          if(user.user.custom_fields[i].key==='customer_fields'){
            user.user.custom_fields[i].value= customer_fields
          }
        }
       localStorage.setItem('user',JSON.stringify(user))
       })
      }
      
   }
    const deleteform = (index)=>{
      let data = [...state];
      data.splice(index, 1)
      setstate(data)
      const customer_fields = data
   
      axios.post('admin-v1-create-custom-fields',{customer_fields},{
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response)=>{
        console.log(response.data)
         if(response.data.statusCode===200){
          toast.success("custom field deleted successfully", {
            position: "top-center"
          });
         }
         localStorage.removeItem(user)
          for( let i=0;i<user.user.custom_fields.length;i++){
            if(user.user.custom_fields[i].key==='customer_fields'){
              user.user.custom_fields[i].value= customer_fields
            }
          }
         localStorage.setItem('user',JSON.stringify(user))
      })
    }
    useEffect(()=>{
    },[])
  return (
    <>
       <div className="sidebarflexproperty">
        <div className="div">
            <Sidebar/>
        </div>
        <div className="divouterdashboard ">
            <div className="dashboard col">
            <div className="row p-1 nav_bar">
            <div className="mx-4 d-flex">
              <p className='font-weight-bold  fontsize16 py-2 imgcursor navcolor breadcrumstyle' >Manage Forms</p>
              
            </div>
          </div>
          <div className="d-flex row">
            <div className="col-lg-3 bgwhite locationheight">
                <div className={tagtoggle==='a' ? "locationtoggledesign border d-flex mt-2 " : "border d-flex mt-2"} onClick={()=>{settagtoggle('a')}}>
                    <div>
                        <img className='mt-3 ml-3' src={tagtoggle==='a' ? formicon :  formicongrey} alt="" />
                    </div>
                    <p className={tagtoggle==='a' ? 'greycolor fontsize10 p-3 navcolor' : 'greycolor fontsize10 p-3'}>Follow-up Log</p>
                </div>
                {/* <div className={tagtoggle==='b' ? "locationtoggledesign border d-flex mt-2 " : "border d-flex mt-2"} onClick={()=>{settagtoggle('b')}}>
                    <div>
                        <img className='mt-3 ml-3' src={tagtoggle==='b' ? formicon :  formicongrey} alt="" />
                    </div>
                    <p className={tagtoggle==='b' ? 'greycolor fontsize10 p-3 navcolor' : 'greycolor fontsize10 p-3'} >Follow - Up </p>
                </div>
                <div className={tagtoggle==='c' ? "locationtoggledesign border d-flex mt-2 " : "border d-flex mt-2"} onClick={()=>{settagtoggle('c')}}>
                    <div>
                        <img className='mt-3 ml-3' src={tagtoggle==='c' ? formicon :  formicongrey} alt="" />
                    </div>
                    <p className={tagtoggle==='c' ? 'greycolor fontsize10 p-3 navcolor' : 'greycolor fontsize10 p-3'} >Customer</p>
                </div> */}
            </div>
            <div className="col-lg-9">                 
                {
                tagtoggle==='a' && (
                   <>
                        <form action="" onSubmit={createcustomfields}>

                      
                       <div className="row">
                        <div className="col-lg-10 fieldsheight">
                        {
                    state?.map((cval,index)=>{
                      return <>
                      <div className="d-flex ">
                     <div className="border bgcolor mt-3 w-100">
                        <div className="innerdivadmin bgwhite inputfocus  clearfix">
                     
                            <div className=" py-2 col ">
                                <input  type="text" className='input_fields  w-100' name="field_name" id="" value={cval.field_name} placeholder='Type your question here' onChange={event => handleFormChange(index, event,cval)} autoComplete='off' required/>
                            </div>
                            {
                              cval.field_type!=='checkbox' ?    <div className="col py-2 col">
                              <input  className='input_fields w-100' name="hint_text" id="" value={cval.hint_text} placeholder='Hint text' onChange={event => handleFormChange(index, event,cval)} autoComplete='off' required/>
                          </div> : ''
                            }
                            <div className="filter col-lg-12 ">
                            <div className="col-lg-5">
                            </div>
                    <div className="d-flex  mt-4 mb-2 dropplaceholder">
                    <select name='field_type' className='form-control  w-50 '   onChange={event => handleFormChange(index, event,cval)}>
                      <option value={cval.field_type}  hidden disabled>{cval.field_type==='checkbox' ? 'Check Box' : cval.field_type==='date' ? 'Date' : 'Short Answer' }</option>
                       <option value="text" selected >Short Answer</option>
                       <option value="checkbox">Check Box</option>
                       <option value="date">Date</option>
                       </select>
                <div className="px-2 searchbg2 pt-1 ">
                  <img className='pl-1' src={dropicon} alt="" />
                </div>
                {
                  cval.field_type==='checkbox' ? '' : <>  <p className='navcolor font-weight-bold pt-2 mx-3'>Required</p>
                  <div className='pt-2 '>
                  <Switch
                  name='required_type'
        onChange={event => handleFormChange(index, event,cval)}
        checked={cval.required_type}
        onColor="#1988C5"
        handleDiameter={12}
        uncheckedIcon={false}
        checkedIcon={false}
        height={18}
        width={40}
        className="react-switch "
      />
                  </div>
                  
                  </>
                }
               <div className=" w-100 ">
                <div className="float-right">
               <div className="border bgwhite mt-2 imgcursor   mr-3"  onClick={()=>{deleteform(index)}}>
                        <img src={deleteicon} alt="" className='m-2 ' />
                       </div>
                </div>
               </div>

                    </div>
                </div>
                        </div>
                     </div>
                     </div>
                
                      </>
                    })
                   }
                   {
                    state?.length>0 ?             <div className="div">
                    <div className="col-lg-12 mt-3">
                      <button className='btn btn-primary bgcolor  float-right'>Save</button>
                    </div>
                  </div> : ''
                   }
           
                   </div>
                     
                   <div className="col-lg-2">
                   <div className="border bgwhite d-flex imgcursor mt-3" onClick={()=>{addform()}}>
                    <div>
                        <img src={addicon} alt="" className='m-1 pl-1' height={13}/>
                    </div>
                    <p className='fontsize10 pt-1 navcolor font-weight-bold'>Add Form</p>
                       </div>
                   </div>
                   </div>
                   </form>

                   </>
                )
                }
                      
                {/* {
                tagtoggle==='b' && (
                   <>
                   <h1>b</h1>
                   </>
                )
                }
                {
                tagtoggle==='c' && (
                   <>
                   <h1>c</h1>
                   </>
                )
                } */}
            </div>
          </div>
            </div>
        </div>
       </div>
       <ToastContainer/>
    </>
  )
}
