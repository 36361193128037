import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import uploadicon from "../../../icons/Vector (38).png";
import imageicon from "../../../icons/profile.png";
import imageicon2 from "../../../icons/Vector (40).png";
import addicon from "../../../icons/addition-thick-symbol.png";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import filtericon from "../../../icons/Vector (17).png";
import searchlogo from "../../../icons/search-interface-symbol 1.png";
import downloadicon from "../../../icons/Vector (44).png";
import uploadiconred from "../../../icons/ri-upload-line.png";
import timeline1 from "../../../icons/Vector (45).png";
import timeline2 from "../../../icons/Vector (46).png";
import timeline3 from "../../../icons/Vector (47).png";
import pdficon from "../../../icons/Vector (48).png";
import arrowup from "../../../icons/ri-arrow-down-s-line-1.png";
import arrowdown from "../../../icons/ri-arrow-down-s-line.png";
import editicon from "../../../icons/Vector (18).png";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useEffect } from "react";
import axios from "axios";
import moment from "moment";

const Claims = () => {
  var status;
  const user = JSON.parse(localStorage.getItem("user"));
  const userid = user.user.id;
  for (let i = 0; i < user?.user?.custom_fields?.length; i++) {
    if (user.user.custom_fields[i].key === "claim_status") {
      status = user.user.custom_fields[i]?.value;
    }
  }
  const [toggle, settoggle] = useState();
  const [subtypetemp, setsubtypetemp] = useState("");
  // const [statuslist,setstatus] = useState([])
  const [addreq, setaddreq] = useState(false);
  const [section2, setsection2toggle] = useState(true);
  const [maintoggle, setmaintoggle] = useState(true);
  const [claimtoggle, setclaimtoggle] = useState(false);
  const [categoryarr, setclaimtypearr] = useState([]);
  const [claimsubtype, setclaim_subtype] = useState([]);
  const [daterangetoggle, setdaterangetoggle] = useState(false);
  const [togglenew, settogglenew] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchterm, setsearchterm] = useState("");
  const [tablearr, settablearr] = useState([]);
  const [imagefile, setSelectedImage] = useState(null);
  const [filter, setfilter] = useState("All");
  const [myclaimslist, setmyclaims] = useState([]);
  const [incomingclaim, setincomingclaims] = useState([]);
  const [timelinedata, settimelinedata] = useState([]);
  const [transfertoggle, settransfertoggle] = useState(false);
  const [editbtntoggle, seteditbtntoggle] = useState(false);
  const [editflow, seteditflow] = useState(false);
  const [searchemp, setsearchemp] = useState([]);
  const [claimcarddetail, setclaimdetail] = useState({});
  const [approvaldata, setapprovaldata] = useState({});
  const [state, setstate] = useState([]);
  const [customfields, setcustfields] = useState(['','','','','']);
  const [listcustomfields, setlistcustomfields] = useState([]);
  const [prev, setprev] = useState("");
  const [statusval, setstatusval] = useState("");
  const [itemid, setitemid] = useState("");
  const [toggleindex, settoggleindex] = useState();
  const [claimid, setclaimid] = useState("");
  const [typename, settypename] = useState("");
  const [image, setimageurl] = useState("");
  const [searchtoggle, setsearchtoggle] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [imgview, setimgviewer] = useState();
  const [formdata, setformdata] = useState({
    type: "",
    amount: "",
    sub_type: "",
    notes: "",
    currency: "",
    customer_name: "",
    description: "",
    start_location: "",
    end_location: "",
    km: "",
    m: "",
    distance: "",
  });
  const cleardatefilter = () => {
    setStartDate(null)
    setEndDate(null)
    setdaterangetoggle(false)
    const token = user.token;
    axios
      .get(
        `/get-v1-user-claim-filter?status=${filter}&start_date=${""}&end_date=${""}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const result = response.data;
        const  temparray = [...result?.incentiveCliam ||[]
          ,...result?.mileageClaim || [],...result?.reimbursementClaim || []]
        setmyclaims(temparray);
        settogglenew('')
      });
  }
  const handleinputs = (e) => {
    const newdata = { ...formdata };
    if(e.target.name === "km"){
       if(e.target.value.length<5 ){
        newdata[e.target.name] = e.target.value
      }
      else{
        e.preventDefault();
      }
    }else if(e.target.name === "m"){
      if(e.target.value.length<4 && e.target.value!=='e'){
        newdata[e.target.name] = e.target.value;
      }
      else{
        e.preventDefault();
      }
    }
    else{
      newdata[e.target.name] = e.target.value;
    }
    if (e.target.name === "km" || e.target.name === "m" ) {
      newdata.distance =
        newdata.m?.length !== 0 ? newdata.km + "." + newdata.m : newdata.km;
    }
    setformdata(newdata);
    console.log(newdata)
    // setIsSubmitted(false);
  };
  const imgviewer = (imglink) => {
    setimgviewer(imglink)
  }
  const Myclaimstoggle = () => {
    setmaintoggle(false);
    setclaimtoggle(true);
    settoggle("a");
    seteditflow(false);
    seteditbtntoggle(false);
    settoggleindex("");
    setIsSubmitted(false)
  };
  const handleaddreq = () => {
    setaddreq(true);
    setsection2toggle(false);
  };
  const handlebreadcrum = () => {
    setmaintoggle(true);
    setclaimtoggle(false);
    settoggle("");
    seteditflow(false);
    settablearr([]);
    setformdata({
      type: "",
      amount: "",
      sub_type: "",
      notes: "",
      currency: "",
      customer_name: "",
      description: "",
      start_location: "",
      end_location: "",
      km: "",
      m: "",
      distance: "",
    });
  };
  const handlebreadcrum2 = () => {
    setmaintoggle(false);
    setclaimtoggle(true);
    settoggle("a");
    setsection2toggle(true);
    setaddreq(false);
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const toggledaterangepicker = () => {
    setdaterangetoggle((item) => !item);
  };
  const handleimages = (e) => {
    setSelectedImage(e.target.files[0]);
    const token = user.token;

    var upload_file = e.target.files[0]
    console.log(e.target.files[0])
    axios
      .post(`/user-v1-upload-file`, { upload_file }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setimageurl(response.data.data)
        }
      });
  };
  const searchuser = () => {
    const token = user.token;
    axios
      .get(`/get-unsign-user-search?search_user=${searchterm}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setsearchemp(response.data.data);
        setsearchtoggle(true)
      });
  };
  const inputStyle = {
    borderColor: "#dc3545",
    borderWidth: 2,
    color: "#dc3545",
  };
  const inputStyle2 = {
    fontWeight: "bold",
    color: "#dc3545",
  };
  const additem = () => {
    setIsSubmitted(true);
    if (
      formdata.type === 'Reimbursement' &&
      formdata.sub_type &&
      formdata.amount &&
      formdata.currency &&
      formdata.notes &&
      image
    ) {
      var cust = [...state];
      var temp = { ...formdata };
      for (let i = 0; i < state.length; i++) {
        cust[i].value = customfields[i] ? customfields[i] : '';
      }
      const custfinal = cust?.map(({ search_val, index, ...rest }) => ({
        ...rest,
      }));
      delete temp.km;
      delete temp.m;
      var custom_fields = custfinal.length === 0 ? "" : custfinal;
      var new1 = [...tablearr];
      new1.push({
        ...temp,
        bill_report: image ? image : "",
        custom_fields: custom_fields,
      });
      // const temp = [{...tablearr,'bill_report':image}]
      settablearr(new1);
      console.log(new1);
      setcustfields(['','','','',''])
      dynamicfields('RSM')
      setformdata({
        type: formdata.type,
        amount: "",
        sub_type: "",
        notes: "",
        currency: "",
        customer_name: "",
        description: "",
        start_location: "",
        end_location: "",
        km: "",
        m: "",
        distance: "",
      });
      setSelectedImage(null);
      setimageurl("");
      setIsSubmitted(false)
    } else if (
      formdata.type === 'Mileage' &&
      formdata.sub_type &&
      formdata.amount &&
      formdata.currency &&
      formdata.notes &&
      formdata.start_location &&
      formdata.end_location &&
      formdata.km &&
      formdata.m &&
      image) {
      var cust = [...state];
      var temp = { ...formdata };
      for (let i = 0; i < state.length; i++) {
        cust[i].value = customfields[i] ? customfields[i] : '';
      }
      const custfinal = cust?.map(({ search_val, index, ...rest }) => ({
        ...rest,
      }));
      delete temp.km;
      delete temp.m;
      var custom_fields = custfinal.length === 0 ? "" : custfinal;
      var new1 = [...tablearr];
      new1.push({
        ...temp,
        bill_report: image ? image : "",
        custom_fields: custom_fields,
      });
      // const temp = [{...tablearr,'bill_report':image}]
      settablearr(new1);
      console.log(new1);
      setformdata({
        type: formdata.type,
        amount: "",
        sub_type: "",
        notes: "",
        currency: "",
        customer_name: "",
        description: "",
        start_location: "",
        end_location: "",
        km: "",
        m: "",
        distance: "",
      });
      setcustfields(['','','','',''])
      dynamicfields('MLG')
      setSelectedImage(null);
      setimageurl("");
      setIsSubmitted(false)
    } else if (
      formdata.type === 'Incentive' &&
      formdata.sub_type &&
      formdata.amount &&
      formdata.currency &&
      formdata.customer_name &&
      formdata.description &&
      image) {
      var cust = [...state];
      var temp = { ...formdata };
      for (let i = 0; i < state.length; i++) {
        cust[i].value = customfields[i] ? customfields[i] : '';
      }
      const custfinal = cust?.map(({ search_val, index, ...rest }) => ({
        ...rest,
      }));
      delete temp.km;
      delete temp.m;
      var custom_fields = custfinal.length === 0 ? "" : custfinal;
      var new1 = [...tablearr];
      new1.push({
        ...temp,
        bill_report: image ? image : "",
        custom_fields: custom_fields,
      });
      // const temp = [{...tablearr,'bill_report':image}]
      settablearr(new1);
      console.log(new1);
      setcustfields(['','','','','']);
      setformdata({
        type: formdata.type,
        amount: "",
        sub_type: "",
        notes: "",
        currency: "",
        customer_name: "",
        description: "",
        start_location: "",
        end_location: "",
        km: "",
        m: "",
        distance: "",
      });
      dynamicfields('INC')
      setSelectedImage(null);
      setimageurl("");
      setIsSubmitted(false)
    } else {
      toast.error("Please fill mandatory details", {
        position: "top-center",
      });
    }
  };
  const createclaim = (togglestatus) => {
    if (tablearr.length === 0) {
      toast.error("Please Create a Claim", {
        position: "top-center",
      });
    } else {
      const token = user.token;
      var temp = {};
      // const {claim,amount,notes,currency} = formdata
      const temp2 = tablearr[0].type;
      const expense_date = new Date();
      for (let i = 0; i < categoryarr?.length; i++) {
        if (categoryarr[i].name === temp2) {
          temp = categoryarr[i];
        }
      }
      var total = 0;
      for (let i = 0; i < tablearr.length; i++) {
        total += parseInt(tablearr[i]?.amount);
      }
      var total_amount = JSON.stringify(total);
      const { id, tag, name } = temp;
      const claim_id = id;
      const claim_type = tag;
      const claim_name = name;
      var status;
      if (togglestatus === "1") {
        status = "Draft";
      } else {
        status = "Requested";
      }
      const currency = "INR";
      var reimbursement_items = [];
      var incentive_items = [];
      var mileage_items = [];
      if (editflow) {
        var reimbursement_old_items = [];
        var incentive_old_items = [];
        var mileage_old_items = [];
        const id = claimid;
        const newArrayOfObj = tablearr.map(
          ({bill_report:file, ...rest }) => ({
            file,
            expense_date,
            ...rest,
          })
        );
        for (let i = 0; i < newArrayOfObj.length; i++) {
          if (newArrayOfObj[i].custom_fields === null) {
            newArrayOfObj[i].custom_fields = "";
          }
        }
        console.log(
          newArrayOfObj,
          claim_id,
          claim_type,
          claim_name,
          status,
          currency,
          total_amount,
          id
        );
        if (temp2 === "Reimbursement") {
          reimbursement_old_items = newArrayOfObj;
          axios
            .post(
              `/create-v1-user-claim-update`,
              {
                claim_id,
                claim_type,
                claim_name,
                status,
                currency,
                total_amount,
                reimbursement_old_items,
                reimbursement_items,
                id,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode === 200) {
                toast.success(response.data.message, {
                  position: "top-center",
                });
                // settablearr([])
              }
              setformdata({
                type: formdata.type,
                amount: "",
                sub_type: "",
                notes: "",
                currency: "",
                customer_name: "",
                description: "",
                start_location: "",
                end_location: "",
                km: "",
                m: "",
                distance: "",
              });
              setcustfields(['','','','','']);
              setSelectedImage(null);
              setimageurl("");
              myclaims();
              settablearr([]);
            }).catch((error)=>{
              if (error.response.data.statusCode!== 200) {
                toast.error(error.response.data.message, {
                  position: "top-center",
                });
                // settablearr([])
              }
            })
        }
        if (temp2 === "Incentive") {
          incentive_old_items = newArrayOfObj;
          axios
            .post(
              `/create-v1-user-claim-update`,
              {
                claim_id,
                claim_type,
                claim_name,
                status,
                currency,
                total_amount,
                incentive_old_items,
                incentive_items,
                id,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode === 200) {
                toast.success(response.data.message, {
                  position: "top-center",
                });
                //  settablearr([])
              }
              setformdata({
                type: formdata.type,
                amount: "",
                sub_type: "",
                notes: "",
                currency: "",
                customer_name: "",
                description: "",
                start_location: "",
                end_location: "",
                distance: "",
                km: "",
                m: "",
              });
              setcustfields(['','','','','']);
              setSelectedImage(null);
              setimageurl("");
              myclaims();
              settablearr([]);
            }).catch((error)=>{
              if (error.response.data.statusCode!== 200) {
                toast.error(error.response.data.message, {
                  position: "top-center",
                });
                // settablearr([])
              }
            })
        }
        if (temp2 === "Mileage") {
          mileage_old_items = newArrayOfObj;
          axios
            .post(
              `/create-v1-user-claim-update`,
              {
                claim_id,
                claim_type,
                claim_name,
                status,
                currency,
                total_amount,
                mileage_old_items,
                mileage_items,
                id,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode === 200) {
                toast.success(response.data.message, {
                  position: "top-center",
                });
                //  settablearr([])
              }
              setformdata({
                type: formdata.type,
                amount: "",
                sub_type: "",
                notes: "",
                currency: "",
                customer_name: "",
                description: "",
                start_location: "",
                end_location: "",
                distance: "",
                km: "",
                m: "",
              });
              setcustfields(['','','','','']);
              setSelectedImage(null);
              setimageurl("");
              myclaims();
              settablearr([]);
            }).catch((error)=>{
              if (error.response.data.statusCode!== 200) {
                toast.error(error.response.data.message, {
                  position: "top-center",
                });
                // settablearr([])
              }
            })
        }
      } else {
        const result = tablearr.map(({ claim, index, ...rest }) => ({
          ...rest,
          expense_date,
        }));
        const newArrayOfObj = result.map(({ file: bill_report, ...rest }) => ({
          bill_report,
          ...rest,
        }));
        // for(let i=0;i<newArrayOfObj.length;i++){
        //   if(newArrayOfObj[i].custom_fields)
        // }
        console.log(
          newArrayOfObj,
          claim_id,
          claim_type,
          claim_name,
          status,
          currency,
          total_amount
        );
        if (temp2 === "Reimbursement") {
          reimbursement_items = newArrayOfObj;
          axios
            .post(
              `/create-v1-user-claim`,
              {
                claim_id,
                claim_type,
                claim_name,
                status,
                currency,
                total_amount,
                reimbursement_items,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode === 200) {
                toast.success(response.data.message, {
                  position: "top-center",
                });
                settablearr([]);
                setcustfields(['','','','','']);
                setSelectedImage(null);
                setimageurl("");
                myclaims();
              }
              setformdata({
                type: "",
                amount: "",
                sub_type: "",
                notes: "",
                currency: "",
              });
            }).catch((error)=>{
              if (error.response.data.statusCode!== 200) {
                toast.error(error.response.data.message, {
                  position: "top-center",
                });
                // settablearr([])
              }
            })
        }
        if (temp2 === "Incentive") {
          incentive_items = newArrayOfObj;
          axios
            .post(
              `/create-v1-user-claim`,
              {
                claim_id,
                claim_type,
                claim_name,
                status,
                currency,
                total_amount,
                incentive_items,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode === 200) {
                toast.success(response.data.message, {
                  position: "top-center",
                });
                settablearr([]);
              }
              setformdata({
                type: "",
                amount: "",
                sub_type: "",
                notes: "",
                currency: "",
              });
              setSelectedImage(null);
              setimageurl("");
              myclaims();
              setcustfields(['','','','',''])
            }).catch((error)=>{
              if (error.response.data.statusCode!== 200) {
                toast.error(error.response.data.message, {
                  position: "top-center",
                });
                // settablearr([])
              }
            })
        }
        if (temp2 === "Mileage") {
          mileage_items = newArrayOfObj;
          axios
            .post(
              `/create-v1-user-claim`,
              {
                claim_id,
                claim_type,
                claim_name,
                status,
                currency,
                total_amount,
                mileage_items,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode === 200) {
                toast.success(response.data.message, {
                  position: "top-center",
                });
                settablearr([]);
              }
              setformdata({
                type: "",
                amount: "",
                sub_type: "",
                notes: "",
                currency: "",
              });
              setSelectedImage(null);
              setimageurl("");
              myclaims();
              setcustfields(['','','','',''])
            }).catch((error)=>{
              if (error.response.data.statusCode!== 200) {
                toast.error(error.response.data.message, {
                  position: "top-center",
                });
                // settablearr([])
              }
            })
        }
      }
    }
  };
  const claim_subtype = () => {
    const token = user.token;
    axios
      .get(`/get-v1-user-claim-subtype?key=${formdata.type + "_sub_type"}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;
        setclaim_subtype(result[0]?.value);
      });
  };
  const claimdetails = (amount, date, type, id, short, status) => {
    if (status === "Draft") {
      setmaintoggle(true);
      setclaimtoggle(false);
      seteditflow(true);
      settoggle("");
      claimitem(type, id);
      setclaimid(id);
      setformdata({
        type: type,
        amount: "",
        sub_type: "",
        notes: "",
        currency: "",
        customer_name: "",
        description: "",
        start_location: "",
        end_location: "",
        distance: "",
        km: "",
        m: "",
      });
    } else {
      setclaimdetail({ amount, date, id, type, short });
      settogglenew("b");
      claimitem(type, id);
      claimtimeline(id, short);
      setstatusval(status);
    }
  };
  const incomingclaimfn = (
    name,
    amount,
    date,
    id,
    type,
    shorttype,
    profile
  ) => {
    settogglenew("a");
    setclaimdetail({ amount, date, id, profile, shorttype });
    settypename({ name, type });
    claimitem(type, id);
    claimtimeline(id, shorttype);
    setapprovaldata({ shorttype, id });
    settransfertoggle(false);
  };
  const handleclaimsectionone = () => {
    settoggle("a");
    settogglenew("");
  };
  const handleclaimsectiontwo = () => {
    settoggle("b");
    settogglenew("");
    settransfertoggle(false);
  };
  const claimitem = (type, id) => {
    const token = user.token;
    axios
      .get(`/get-v1-user-claim-items?claim_type=${type}&claim_id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;
        for (let i = 0; i < result.length; i++) {
          if (result[i].custom_fields?.length === 0) {
            result[i].custom_fields = "";
          }
        }
        const newArrayOfObj = result.map(({ file: bill_report, ...rest }) => ({
          bill_report,
          ...rest,
        }));
        settablearr(newArrayOfObj);
        console.log(newArrayOfObj);
      });
  };
  const claimtimeline = (id, type) => {
    const token = user.token;
    const claim_type = type;
    axios
      .get(
        `/get-v1-user-claim-approver-status?claim_id=${id}&claim_type=${claim_type}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const result = response.data.data;
        settimelinedata(result);
        console.log(result);
      })
      .catch((error) => {
        settimelinedata(error.response.data.data);
      });
  };
  const handlenexttransfer = (num) => {
    settransfertoggle(true);
    setprev(num)
  };
  const transferclaim = (idnew) => {
    var url;
    if (prev !== "1") {
      url = "/approver-v1-claim-auth-transfer";
    } else {
      url = "/user-v1-claim-add-previous-approver";
    }
    const claim_id = claimcarddetail.id;
    const claim_type = claimcarddetail.shorttype;
    var id;

    var decision = prev === "1" ? "Requested" : prev === "2" ? "Approved" :  "Transfered";
    for (let i = 0; i < timelinedata?.length; i++) {
      if (timelinedata[i].decision === "Requested") {
        id = timelinedata[i].id;
      }
    }
    const new_approver_id = idnew;
    const token = user.token;
    axios
      .post(
        url,
        { claim_id, claim_type, id, decision, new_approver_id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success(response.data.message, {
            position: "top-center",
          });
        }
        settransfertoggle(false);
        incomingclaims()
        settogglenew('')
        setsearchtoggle(false)
        setsearchterm('')
        setsearchemp([])
      });
  };
  const handlewithdraw = () => {
    const token = user.token;
    const claim_id = claimcarddetail.id;
    const claim_type = claimcarddetail.short;
    var approval_id;
    var decision = "Withdrawn";
    for (let i = 0; i < timelinedata.length; i++) {
      if (timelinedata[i].decision === "Requested") {
        approval_id = timelinedata[i].id;
      }
    }
    axios
      .post(
        `/user-v1-claim-withdraw`,
        { claim_id, claim_type, decision, approval_id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success(response.data.message, {
            position: "top-center",
          });
          myclaims();
          settogglenew("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const approverequest = () => {
    const token = user.token;
    var id;
    const claim_type = approvaldata.shorttype;
    const claim_id = approvaldata.id;
    const decision = "Approved";
    for (let i = 0; i < timelinedata.length; i++) {
      if (timelinedata[i].decision === "Requested") {
        id = timelinedata[i].id;
      }
    }
    const result = tablearr.map(({ id, custom_fields, ...rest }) => ({
      id,
      custom_fields,
    }));
    console.log(result)
    if (typename.type === "Reimbursement") {
      const reimbursement_old_items = result;
      axios
        .post(
          "/user-v1-claim-accept-reject",
          { claim_type, claim_id, decision, id, reimbursement_old_items },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            toast.success(response.data.message, {
              position: "top-center",
            });
          }
          incomingclaims();
          settogglenew("");
        });
    }
    if (typename.type === "Incentive") {
      const incentive_old_items = result;
      axios
        .post(
          "/user-v1-claim-accept-reject",
          { claim_type, claim_id, decision, id, incentive_old_items },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            toast.success(response.data.message, {
              position: "top-center",
            });
          }
          incomingclaims();
          settogglenew("");
        });
    }
    if (typename.type === "Mileage") {
      const mileage_old_items = result;
      axios
        .post(
          "/user-v1-claim-accept-reject",
          { claim_type, claim_id, decision, id, mileage_old_items },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            toast.success(response.data.message, {
              position: "top-center",
            });
          }
          incomingclaims();
          settogglenew("");
        });
    }
  };
  const denyrequest = () => {
    const token = user.token;
    var id;
    const claim_type = approvaldata.shorttype;
    const claim_id = approvaldata.id;
    const decision = "Rejected";
    for (let i = 0; i < timelinedata.length; i++) {
      if (timelinedata[i].decision === "Requested") {
        id = timelinedata[i].id;
      }
    }
    axios
      .post(
        "/user-v1-claim-accept-reject",
        { claim_type, claim_id, decision, id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success(response.data.message, {
            position: "top-center",
          });
        }
        incomingclaims();
        settogglenew('')
      });
  };
  const incomingclaims = () => {
    const token = user.token;
    axios
      .get(`/user-approver-v1-get-claim-request`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;
        setincomingclaims(result);
        console.log(result);
      });
  };
  const handleprevioustransfer = () => {
    settransfertoggle(true);
    setprev("1");
  };
  const editclaim = (
    type,
    sub_type,
    customer_name,
    description,
    start_location,
    end_location,
    distance,
    currency,
    amount,
    notes,
    id,
    file,
    customfields,
    index
  ) => {
    var tempcustom = []
    console.log(customfields)
    setstate(customfields)
     for(let i=0;i<customfields?.length;i++){
      tempcustom.push(customfields[i].value)
     }
     setcustfields(tempcustom)
    if (type === "Mileage") {
      const found = distance.includes(".");
      if (found) {
        var tempdistance = distance.split(".");
      }
      setitemid(id);
      settoggleindex(index);
      console.log(index)
      seteditbtntoggle(true);
      setsubtypetemp(sub_type);
      setformdata({
        type: type,
        amount: amount ? amount : "",
        sub_type: sub_type || "",
        notes: notes || "",
        currency: currency || "",
        customer_name: customer_name ? customer_name : "",
        description: description || "",
        start_location: start_location || "",
        end_location: end_location || "",
        distance: distance || "",
        km: found ? tempdistance[0] : distance,
        m: found ? tempdistance[1] : "",
      });
      setimageurl(file);
    } else {
      setitemid(id);
      settoggleindex(index);
      seteditbtntoggle(true);
      setsubtypetemp(sub_type);
      setformdata({
        type: type,
        amount: amount ? amount : "",
        sub_type: sub_type || "",
        notes: notes || "",
        currency: currency || "",
        customer_name: customer_name ? customer_name : "",
        description: description || "",
        start_location: start_location || "",
        end_location: end_location || "",
        distance: distance || "",
        km: "",
        m: "",
      });
      setimageurl(file);
    }
  };
  const editclaimfn = () => {
    var new1 = [];
    var new2 = [...tablearr];
    new1.push({ ...formdata });
    let cust = [...state];
    for (let i = 0; i < state.length; i++) {
      cust[i].value = customfields[i] ? customfields[i] : '';
    }
    new2[toggleindex].sub_type = new1[0].sub_type;
    new2[toggleindex].amount = new1[0].amount;
    new2[toggleindex].notes = new1[0].notes;
    new2[toggleindex].currency = new1[0].currency;
    new2[toggleindex].customer_name = new1[0]?.customer_name;
    new2[toggleindex].description = new1[0]?.description;
    new2[toggleindex].start_location = new1[0]?.start_location;
    new2[toggleindex].end_location = new1[0]?.end_location;
    new2[toggleindex].distance = new1[0]?.distance;
    new2[toggleindex].bill_report = image;
    new2[toggleindex].custom_fields = cust;
    console.log(cust)
    settablearr(new2);
    console.log(new2)
    seteditbtntoggle(false);
    setformdata({
      type: formdata.type,
      amount: "",
      sub_type: "",
      notes: "",
      currency: "",
      customer_name: "",
      description: "",
      start_location: "",
      end_location: "",
      distance: "",
      km: "",
      m: "",
    });
    setcustfields(['','','','','']);
    setSelectedImage(null);
    settoggleindex('')
  };
  //  const editclaimfn = ()=>{
  //   const token = user.token
  //   var cust = [...state]
  //   for(let i=0;i<state.length;i++){
  //       cust[i].value = customfields[i]
  //   }
  //   const custfinal = cust?.map(({search_val,index,...rest}) => ({...rest}));
  //   var custom_fields = custfinal.length===0 ? [] : custfinal
  //   var new1 = []
  //   var new2  = [...tablearr]
  //   var new3  = [...tablearr]
  //   const expense_date = new Date()
  //   new1.push({...formdata,'bill_report': image ,custom_fields:custom_fields})
  //   for(let i =0 ;i<tablearr.length;i++){
  //     new2[toggleindex] = new1[0];
  //     new2[toggleindex].id = new3[toggleindex].id
  //     new2[toggleindex].expense_date = expense_date
  //   }
  //   var temp = {}
  //   const temp2 = new1[0].type
  //     for(let i=0;i<categoryarr?.length;i++){
  //       if(categoryarr[i].name===temp2){
  //         temp = categoryarr[i]
  //       }
  //     }
  //     var total = 0
  //     for(let i=0;i<tablearr.length;i++){
  //       total+= parseInt(tablearr[i]?.amount)
  //     }
  //   var  total_amount = JSON.stringify(total)
  //   const  {id,tag,name} = temp
  //   const claim_type = tag
  //   const claim_name = name
  //   const claim_id = id
  //   var status = 'Draft'
  //   const currency = 'INR'

  //   var reimbursement_old_items = []
  //   var incentive_old_items = []
  //   var mileage_old_items = []
  //   // var  temp3 = []
  //   // var addval = [...new2]
  //   // // var custom_fields = temp3.length===0 ? '' : temp3
  //   // for(let i=0;i<addval?.length;i++){
  //   //   if(addval[i].id===itemid){
  //   //      addval[i] = formdata
  //   //   }
  //   // }
  //   // const result = addval.map(({claim,index,...rest})=>({...rest,expense_date,custom_fields}))
  //   const newArrayOfObj = new2.map(({
  //     file,
  //     ...rest
  //   }) => ({
  //     bill_report:file,
  //     ...rest
  //   }));
  //   var reimbursement_items = []
  //   var incentive_items = []
  //   var mileage_items = []
  //   console.log({claim_id,claim_type,claim_name,status,currency,total_amount,newArrayOfObj})
  //   if(temp2==='Reimbursement'){
  //     reimbursement_old_items = newArrayOfObj
  //     axios.post(`/create-v1-user-claim-update`,{claim_id,claim_type,claim_name,status,currency,total_amount,reimbursement_old_items,reimbursement_items,id},{
  //         headers:{
  //          "Authorization":`Bearer ${token}`,
  //         'Content-Type': 'multipart/form-data'}
  //     }).then((response)=>{
  //         if(response.data.statusCode===200){
  //           toast.success(response.data.message, {
  //             position: "top-center"
  //           });
  //           // settablearr([])
  //         }
  //         setformdata({
  //           type:formdata.type,  amount:'',sub_type:'',notes:'',currency:'',customer_name:'',description:'',start_location:'',end_location:'',distance:''
  //         })
  //         setcustfields([])
  //         setSelectedImage(null)
  //     })
  //  }
  //  if(temp2==='Incentive'){
  //    incentive_old_items = newArrayOfObj
  //    axios.post(`/create-v1-user-claim-update`,{claim_id,claim_type,claim_name,status,currency,total_amount,incentive_old_items,incentive_items,id},{
  //        headers:{"Authorization":`Bearer ${token}`,
  //        'Content-Type': 'multipart/form-data'}
  //    }).then((response)=>{
  //        if(response.data.statusCode===200){
  //          toast.success(response.data.message, {
  //            position: "top-center"
  //          });
  //         //  settablearr([])
  //        }
  //        setformdata({
  //         type:formdata.type,  amount:'',sub_type:'',notes:'',currency:'',customer_name:'',description:'',start_location:'',end_location:'',distance:''
  //       })
  //       setcustfields([])
  //       setSelectedImage(null)
  // setimageurl('')
  //    })
  //  }
  //  if(temp2==='Mileage'){
  //    mileage_old_items = newArrayOfObj
  //    axios.post(`/create-v1-user-claim-update`,{claim_id,claim_type,claim_name,status,currency,total_amount,mileage_old_items,mileage_items,id},{
  //        headers:{"Authorization":`Bearer ${token}`,
  //        'Content-Type': 'multipart/form-data'}
  //    }).then((response)=>{
  //        if(response.data.statusCode===200){
  //          toast.success(response.data.message, {
  //            position: "top-center"
  //          });
  //         //  settablearr([])
  //        }
  //        setformdata({
  //         type:formdata.type,  amount:'',sub_type:'',notes:'',currency:'',customer_name:'',description:'',start_location:'',end_location:'',distance:''
  //       })
  //       setcustfields([])
  //       setSelectedImage(null)
  // setimageurl('')
  //    })
  //  }
  //  }
  const dynamicfields = (tag) => {
    var addfieldstemp = [];
    const token = user.token;
    axios
      .get(`/get-v1-claim-additional-fields?claim_type=${tag}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;
        console.log(result)
        for (let i = 0; i < result?.length; i++) {
          if (result[i].field1_name !== null) {
            addfieldstemp.push({
              field_name: result[i].field1_name,
              field_resp: result[i].field1_resp || '',
              field_type: result[i].field1_type
                ? result[i].field1_type
                : "text",
              value: "",
              search_val: "",
            });
          }
          if (result[i].field2_name !== null) {
            addfieldstemp.push({
              field_name: result[i].field2_name,
              field_resp: result[i].field2_resp || '',
              field_type: result[i].field2_type
                ? result[i].field2_type
                : "text",
              value: "",
              search_val: "",
            });
          }
          if (result[i].field3_name !== null) {
            addfieldstemp.push({
              field_name: result[i].field3_name,
              field_resp: result[i].field3_resp || '',
              field_type: result[i].field3_type
                ? result[i].field3_type
                : "text",
              value: "",
              search_val: "",
            });
          }
          if (result[i].field4_name !== null) {
            addfieldstemp.push({
              field_name: result[i].field4_name,
              field_resp: result[i].field4_resp || '',
              field_type: result[i].field4_type
                ? result[i].field4_type
                : "text",
              value: "",
              search_val: "",
            });
          }
          if (result[i].field5_name !== null) {
            addfieldstemp.push({
              field_name: result[i].field5_name,
              field_resp: result[i].field5_resp || '',
              field_type: result[i].field5_type
                ? result[i].field5_type
                : "text",
              value: "",
              search_val: "",
            });
          }
        }
        setstate(addfieldstemp);
      });
  };
  const handleChange = (i, event, type) => {
    const values = [...customfields];
    if (type === "checkbox") {
      values[i] = event.target.checked;
    } else if (type === "date") {
      values[i] = moment(event).format("yyyy-MM-DD");
    } else {
      values[i] = event.target.value;
    }
    setcustfields(values);
    console.log(values);
  };
  const handleChange2 = (i, event, type, index) => {
    var listcutfields = [];
    for (let i = 0; i < tablearr.length; i++) {
      listcutfields.push(tablearr[i].custom_fields);
    }
    setlistcustomfields(listcutfields);
    const values = [...listcutfields];
    console.log(values)
    if (type === "checkbox") {
      console.log(event.target.checked)
      values[index][i].value = event.target.checked;
    } else if (type === "date") {
      values[index][i].value = moment(event).format("yyyy-MM-DD");
    } else {
      values[index][i].value = event.target.value;
    }
    setcustfields(values);
    console.log(values);
    console.log(tablearr);
  };
  const myclaims = () => {
    const token = user.token;
    
    axios
      .get(
        `/get-v1-user-claim-filter?status=${filter}&start_date=${moment(startDate).isValid()
          ? moment(startDate).format("yyyy/MM/DD")
          : ""
        }&end_date=${moment(endDate).isValid() ? moment(endDate).format("yyyy/MM/DD") : ""
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const result = response.data;
        console.log(result)
        const  temparray = [...result?.incentiveCliam || [],...result?.mileageClaim || [],...result?.reimbursementClaim || []]  
        setmyclaims(temparray);
        settogglenew('')
      });
  };
  const handleclosemodal = ()=>{
    setsearchterm('')
    setsearchemp([])
    // searchuser()
    setsearchtoggle(false)
  }
  useEffect(() => {
    const claimtype = () => {
      const token = user.token;
      axios
        .get("/admin-v1-get-claim-type", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const result = response.data.data;
          setclaimtypearr(result);
          console.log(result);
          if (formdata.type) {
            claim_subtype();
            for (let i = 0; i < result.length; i++) {
              if (result[i].name === formdata.type) {
                dynamicfields(result[i].tag);
              }
            }
          }
          setformdata({
            type: formdata.type,
            amount: "",
            sub_type: "",
            notes: "",
            currency: "",
            customer_name: "",
            description: "",
            start_location: "",
            end_location: "",
            distance: "",
            km: "",
            m: "",
          });
        });
    };

    claimtype();
    myclaims();
    incomingclaims();
  }, [filter, daterangetoggle, formdata.type]);
  return (
    <>
      <div className="sidebarflexproperty">
        <Sidebar />
        <div className="divouterdashboard">
          <div className="dashboard col">
            <div className="row p-1 nav_bar">
              <div className="mx-4 d-flex">
                <p
                  className="font-weight-bold navcolor fontsize16 py-2 imgcursor breadcrumstyle"
                  onClick={handlebreadcrum}
                >
                  Claims{" "}
                </p>
                <p
                  className="font-weight-bold navcolor fontsize16 pt-2 imgcursor breadcrumstyle"
                  onClick={handlebreadcrum2}
                >
                  {toggle === "a"
                    ? "<" + " " + "Incoming Claims "
                    : toggle === "b"
                      ? "< My Claims"
                      : ""}{" "}
                </p>
                <p className="font-weight-bold navcolor fontsize16 pt-2 imgcursor breadcrumstyle">
                  {addreq ? "<" + " " + "Additional Requirements " : ""}{" "}
                </p>
              </div>
            </div>
            <div className="row shadowbox">
              <div
                className={
                  maintoggle
                    ? "col-lg-6 text-center bgwhite py-2 border_bottom bgcolor border_right imgcursor font-weight-bold"
                    : "col-lg-6 text-center bgwhite py-2 border_bottom  border_right imgcursor greycolor font-weight-bold"
                }
                onClick={handlebreadcrum}
              >
                <p className="fontsize12">New Claim</p>
              </div>
              <div
                className={
                  claimtoggle
                    ? "col-lg-6 text-center bgwhite py-2 border_bottom bgcolor imgcursor font-weight-bold"
                    : "col-lg-6 text-center bgwhite py-2 border_bottom  imgcursor greycolor font-weight-bold"
                }
                onClick={Myclaimstoggle}
              >
                <p className="fontsize12">Claim Requests</p>
              </div>
            </div>
            {maintoggle && (
              <>
                <div className="row ">
                  <div className="col-lg-3 bgwhite claimsleftsection ">
                    <div className="row heightclaimcreate">
                      {/* <div className="col-lg-12 ">
                        <p className='navcolor fontsize10 float-right py-3 imgcursor' >My Claims(2)</p>
                    </div> */}
                      <div className="col-lg-12 mt-3 drophandlecss">
                        <select
                          name="type"
                          className="form-control"
                          disabled={tablearr?.length !== 0 ? true : false}
                          required
                          value={formdata.type}
                          onChange={(e) => {
                            handleinputs(e);
                          }}
                          style={
                            formdata.type === "" && isSubmitted
                              ? inputStyle
                              : {}
                          }
                        >
                          <option value="" disabled hidden selected>
                            Category{" "}
                          </option>
                          {categoryarr?.map((cval) => {
                            return (
                              <>
                                <option value={cval.name}>{cval.name}</option>
                              </>
                            );
                          })}
                        </select>
                      </div>

                      <div className="col-lg-12 mt-2 drophandlecss">
                        <select
                          name="sub_type"
                          className="form-control"
                          required
                          value={formdata.sub_type}
                          onChange={(e) => {
                            handleinputs(e);
                          }}
                          style={
                            formdata.sub_type === "" && isSubmitted
                              ? inputStyle
                              : {}
                          }
                        >
                          <option value="" disabled hidden selected>
                            Sub-Type
                          </option>
                          {/* {subtypetemp.length !== 0 ? (
                            <option value="">{subtypetemp}</option>
                          ) : (
                            ""
                          )} */}
                          {claimsubtype?.map((cval) => {
                            return (
                              <>
                                <option value={cval.type}>{cval.type}</option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      {formdata.type === "Incentive" ? (
                        <>
                          <div className="col-lg-12 mt-2">
                            <input
                              type="text"
                              name="customer_name"
                              className="form-control"
                              placeholder="customer name"
                              autoComplete="off"
                              value={formdata.customer_name}
                              onChange={(e) => {
                                handleinputs(e);
                              }}
                              style={
                                formdata.customer_name === "" && isSubmitted && formdata.type === "Incentive"
                                  ? inputStyle
                                  : {}
                              }
                            />
                          </div>
                          <div className="col-lg-12 mt-2">
                            <input
                              type="text"
                              name="description"
                              className="form-control"
                              placeholder="description"
                              autoComplete="off"
                              value={formdata.description}
                              onChange={(e) => {
                                handleinputs(e);
                              }}
                              style={
                                formdata.description === "" && isSubmitted && formdata.type === "Incentive"
                                  ? inputStyle
                                  : {}
                              }
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {formdata.type === "Mileage" ? (
                        <>
                          <div className="col-lg-12 mt-2">
                            <input
                              type="text"
                              name="start_location"
                              className="form-control"
                              placeholder="start location "
                              autoComplete="off"
                              value={formdata.start_location}
                              onChange={(e) => {
                                handleinputs(e);
                              }}
                              style={
                                formdata.start_location === "" && isSubmitted && formdata.type === "Mileage"
                                  ? inputStyle
                                  : {}
                              }
                            />
                          </div>
                          <div className="col-lg-12 mt-2">
                            <input
                              type="text"
                              name="end_location"
                              className="form-control"
                              placeholder="end location"
                              autoComplete="off"
                              value={formdata.end_location}
                              onChange={(e) => {
                                handleinputs(e);
                              }}
                              style={
                                formdata.end_location === "" && isSubmitted && formdata.type === "Mileage"
                                  ? inputStyle
                                  : {}
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2 pr-1">
                            <input
                              type="number"
                              name="km"
                              min={0}
                              className="form-control "
                              placeholder="Kilometer"
                              autoComplete="off"
                              
                              value={formdata.km}
                              onChange={(e) => {
                                handleinputs(e);
                              }}
                              style={
                                formdata.km === "" && isSubmitted && formdata.type === "Mileage"
                                  ? inputStyle
                                  : {}
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2 pl-1">
                            <input
                              type="number"
                              name="m"
                              min={0}
                              className="form-control "
                              placeholder="Meter"
                              autoComplete="off"
                              
                              value={formdata.m}
                              onChange={(e) => {
                                handleinputs(e);
                              }}
                              style={
                                formdata.m === "" && isSubmitted && formdata.type === "Mileage"
                                  ? inputStyle
                                  : {}
                              }
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="col-lg-12 mt-2">
                        <input
                          type="number"
                          name="amount"
                          className="form-control"
                          placeholder="Amount"
                          autoComplete="off"
                          min={0}
                          value={formdata.amount}
                          onChange={(e) => {
                            handleinputs(e);
                          }}
                          style={
                            formdata.amount === "" && isSubmitted
                              ? inputStyle
                              : {}
                          }
                        />
                      </div>
                      <div className="col-lg-12 mt-2 drophandlecss">
                        <select
                          name="currency"
                          className="form-control"
                          placeholder="Currency"
                          value={formdata.currency}
                          required
                          onChange={(e) => {
                            handleinputs(e);
                          }}
                          style={
                            formdata.currency === "" && isSubmitted
                              ? inputStyle
                              : {}
                          }
                        >
                          <option value="" disabled hidden selected>
                            Currency
                          </option>
                          <option value="INR">INR</option>
                        </select>
                      </div>
                      {formdata.type !== "Incentive" ? (
                        <div className="col-lg-12 mt-2">
                          <input
                            type="text"
                            name="notes"
                            className="form-control"
                            placeholder="Add Note"
                            autoComplete="off"
                            value={formdata.notes}
                            onChange={(e) => {
                              handleinputs(e);
                            }}
                            style={
                              formdata.notes === "" && isSubmitted
                                ? inputStyle
                                : {}
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-lg-12  mt-2">
                        {/* <label for="inputTag" className='imgcursor col-lg-12 px-0' required>
                    <div className='d-flex  billbtndesign ' onClick={()=>{handleClick()}}>
                        <div>
                            <img src={uploadicon} alt="" width={15}/>
                        </div>
                        <p className='greycolor fontsize10 ml-2'>Upload Bill</p>
                    </div>
                <input name="image" id="inputTag" className='' type="file" ref={hiddenFileInput}  onChange={()=>{handleChange()}} /></label> */}
                        <label
                          for="inputTag"
                          className="imgcursor d-flex"
                          required
                        >
                          <div>
                            {" "}
                            {imagefile === null && isSubmitted ? (
                              <img src={uploadiconred} alt="" />
                            ) : (
                              <img src={uploadicon} alt="" />
                            )}
                          </div>{" "}
                          <div>
                            <p
                              className="fontsize12 pt-1 pl-2 m-0"
                              style={
                                imagefile === null && isSubmitted
                                  ? inputStyle2
                                  : {}
                              }
                            >
                              Upload Bill{" "}
                            </p>
                            <input
                              name="image"
                              id="inputTag"
                              className="displayhide"
                              type="file"
                              onChange={(e) => handleimages(e)}
                            />
                          </div>{" "}
                        </label>
                      </div>
                      <div className="col-lg-12 documentposition">
                        <p className="fontsize10 navcolor font-weight-bold">
                          {imagefile?.name}
                        </p>
                      </div>
                      {state.length === 0 ? (
                        ""
                      ) : (
                        <div className="col-lg-12">
                          <p className="navcolor fontsize12">
                            Additional Fields:
                          </p>
                        </div>
                      )}

                      <div className="col-lg-12">
                        { state && state?.map((cval, idx) => {
                          return (
                            <>
                           
                              <div className="">
                                {cval.field_type === "date" ? (
                                  <>
                                    <span className="">
                                      <DatePicker
                                        autoComplete="off"
                                        className="  form-control my-2 w-100"
                                        disabled={
                                          cval.field_resp === userid
                                            ? false
                                            : cval.field_resp === null
                                              ? false
                                              : cval.field_resp === "" ? false : true
                                        }
                                        value={customfields[idx]}
                                        onChange={(e) =>
                                          handleChange(idx, e, cval.field_type)
                                        }
                                        placeholderText={cval.field_name}
                                        required={cval.required_type}
                                      />
                                    </span>
                                  </>
                                ) : cval.field_type === "checkbox" ? (
                                  <>
                                    <div className="d-flex checkboxcss">
                                      <div className="">
                                        <input
                                          type={cval.field_type}
                                          className="w-100 form-control "
                                          disabled={
                                            cval.field_resp === userid
                                              ? false
                                              : cval.field_resp === null
                                                ? false
                                                : cval.field_resp === "" ? false : true
                                          }
                                          checked={customfields[idx]==='' ?  false : customfields[idx]===false ? false : customfields[idx]===null ? false : true}
                                          onChange={(e) =>
                                            handleChange(
                                              idx,
                                              e,
                                              cval.field_type
                                            )
                                          }
                                        />
                                      </div>
                                      <p className="fontsize12 greycolor pt-2 pl-2">
                                        {cval.field_name}{" "}
                                      </p>
                                    </div>
                                    
                                  </>
                                ) : (
                                  <input
                                    type={cval.field_type}
                                    className="w-100 form-control my-2"
                                    disabled={
                                    cval.field_resp === userid
                                        ? false
                                        : cval.field_resp === null
                                        ? false
                                        : cval.field_resp === "" ? false : true
                                    }
                                    placeholder={cval.field_name}
                                    value={customfields[idx]}
                                    onChange={(e) =>
                                      handleChange(idx, e, cval.field_type)
                                    }
                                  />
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                      {editbtntoggle && (
                        <>
                          <div className="col-lg-12">
                            <button
                              className="draftbtn btn btn-primary py-1 font-weight-bold fontsize12  float-right "
                              onClick={() => {
                                editclaimfn();
                              }}
                            >
                              Update
                            </button>
                          </div>
                        </>
                      )}
                      {!editbtntoggle && (
                        <>
                          <div className="col-lg-12 mb-2">
                            <div
                              className="d-flex float-right  px-2 add_req_btn imgcursor"
                              onClick={() => {
                                additem();
                              }}
                            >
                              <div>
                                <img src={addicon} alt="" width={15} />
                              </div>
                              <p className="navcolor fontsize10 pt-1  pl-2 font-weight-bold">
                                Add{" "}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-9 px-0 heightset">
                    <table className="table table-bordered table-sm bgwhite">
                      {tablearr?.length !== 0 ? (
                        <>
                          <thead>
                            <tr className="bgcolor">
                              <th className="nowrap" scope="col">
                                S. No.
                              </th>
                              <th className="nowrap" scope="col">
                                Category
                              </th>
                              <th className="nowrap" scope="col">
                                Sub-Category
                              </th>
                              {tablearr[0]?.type === "Incentive" ? (
                                <>
                                  <th className="nowrap" scope="col">
                                    Customer Name
                                  </th>
                                  <th className="nowrap" scope="col">
                                    Description
                                  </th>
                                </>
                              ) : (
                                ""
                              )}
                              {tablearr[0]?.type === "Mileage" ? (
                                <>
                                  <th className="nowrap" scope="col">
                                    {" "}
                                    Start Location
                                  </th>
                                  <th className="nowrap" scope="col">
                                    End Location
                                  </th>
                                  <th className="nowrap" scope="col">
                                    Distance
                                  </th>
                                </>
                              ) : (
                                ""
                              )}
                              <th className="nowrap" scope="col">
                                Currency
                              </th>
                              <th className="nowrap" scope="col">
                                Amount
                              </th>
                              <th className="nowrap" scope="col">
                                Attachment
                              </th>
                              <th className="nowrap" scope="col">
                                edit
                              </th>
                            </tr>
                          </thead>
                        </>
                      ) : (
                        ""
                      )}

                      <tbody>
                        {tablearr?.map((cval, index) => {
                          return (
                            <>
                              <tr
                                className={
                                  toggleindex === index ? "bgcolortable" : ""
                                }
                              >
                                <td className="greycolor fontsize12">
                                  {index + 1}
                                </td>
                                <td className="greycolor fontsize12">
                                  {cval.type}
                                </td>
                                <td className="greycolor fontsize12">
                                  {cval.sub_type}
                                </td>
                                {tablearr[0]?.type === "Incentive" ? (
                                  <>
                                    <td className="greycolor fontsize12">
                                      {cval?.customer_name}
                                    </td>
                                    <td className="greycolor fontsize12">
                                      {cval?.description}
                                    </td>
                                  </>
                                ) : (
                                  ""
                                )}
                                {tablearr[0]?.type === "Mileage" ? (
                                  <>
                                    <td className="greycolor fontsize12">
                                      {cval?.start_location}
                                    </td>
                                    <td className="greycolor fontsize12">
                                      {cval?.end_location}
                                    </td>
                                    <td className="greycolor fontsize12">
                                      {cval?.distance}
                                    </td>
                                  </>
                                ) : (
                                  ""
                                )}
                                <td className="greycolor fontsize12">
                                  {cval.currency}
                                </td>
                                <td className="greycolor fontsize12">
                                  {cval.amount}
                                </td>
                                <td className="greycolor fontsize12">file</td>
                                <td className="greycolor imgcursor">
                                  <img
                                    src={editicon}
                                    alt=""
                                    onClick={() => {
                                      editclaim(
                                        cval.type,
                                        cval.sub_type,
                                        cval?.customer_name,
                                        cval?.description,
                                        cval?.start_location,
                                        cval?.end_location,
                                        cval?.distance,
                                        cval.currency,
                                        cval.amount,
                                        cval?.notes,
                                        cval?.id,
                                        cval.bill_report,
                                        cval.custom_fields,
                                        index
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                    {tablearr.length !== 0 && !editbtntoggle ? (
                       <div className="row">
                       <div className="col-lg-12 ">
                         <button
                           className="bgcolor btn btn-primary float-right mr-4 fontsize12"
                           onClick={() => {
                             createclaim("2");
                           }}
                         >
                           Claim
                         </button>
                         <button
                           className="draftbtn btn btn-primary float-right mr-4 fontsize12"
                           onClick={() => {
                             createclaim("1");
                           }}
                         >
                           Save as Draft
                         </button>
                       </div>
                     </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            )}
            {claimtoggle && (
              <>
                <div className="row">
                  <div className="col-lg-3 bgwhite claimsleftsection">
                    <div className="row border_bottom">
                      <div
                        className={
                          toggle === "a"
                            ? "col-lg-6 pr-0 pl-1 text-center py-3  imgcursor toggledesignclaim navcolor"
                            : "col-lg-6 pr-0 pl-1 imgcursor text-center greycolor py-3"
                        }
                        onClick={() => {
                          handleclaimsectionone();
                        }}
                      >
                        <p className="fontsize8 ">INCOMING CLAIMS</p>
                      </div>
                      <div
                        className={
                          toggle === "b"
                            ? "col-lg-6 pr-0 pl-1 text-center py-3 imgcursor toggledesignclaim navcolor"
                            : "col-lg-6 pr-0 pl-1 imgcursor text-center greycolor py-3"
                        }
                        onClick={() => {
                          handleclaimsectiontwo();
                        }}
                      >
                        <p className="fontsize8 ">MY CLAIMS</p>
                      </div>
                    </div>
                    <div className="">
                      {toggle === "a" && (
                        <>
                          <div className="row incomingheight">
                            <div className="col-lg-12 px-4">
                              {incomingclaim.length === 0 ? (
                                <>
                                  <p className="text-center greycolor font-weight-bold">
                                    No Incoming Claims!
                                  </p>
                                </>
                              ) : (
                                incomingclaim?.map((val) => {
                                  return (
                                    <>
                                      <div
                                        className="border row my-2 imgcursor"
                                        onClick={() => {
                                          incomingclaimfn(
                                            val.fullname,
                                            val.amount,
                                            val.submitted_on,
                                            val.id,
                                            val.type,
                                            val.claim_type,
                                            val.profile_pic
                                          );
                                        }}
                                        style={{ alignItems: 'center' }}
                                      >
                                        <div className="col-lg-2 px-0 ">
                                          <img
                                            src={
                                              val.profile_pic
                                                ? val.profile_pic
                                                : imageicon
                                            }
                                            alt=""
                                            className=" ml-2 claimimg"
                                            width={30}
                                          />
                                        </div>
                                        <div className="col-lg-7 px-0">
                                          <div className="py-1 pl-1">
                                            <p className="fontsize8 m-0 p-0">
                                              {val.fullname}
                                            </p>
                                            <p className="fontsize10 m-0 p-0">
                                              Rs. {val.amount}
                                            </p>
                                            <p className="fontsize8 m-0 p-0">
                                            {val.submitted_on.split(' ')[0]}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-3 px-0">
                                          <p className=" float-right mr-3">
                                            {">"}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {toggle === "b" && (
                      <>
                        <div className="row">
                          <div className="filter col-lg-12 mt-2">
                            <div className="d-flex ">
                              <select
                                className="form-control w-100"
                                value={filter}
                                onChange={(e) => {
                                  setfilter(e.target.value);
                                }}
                              >
                                {status?.map((val) => {
                                  return (
                                    <>
                                      <option value={val.type}>
                                        {val.status}{" "}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                              <div className="px-2 searchbg pt-1  ">
                                <img className="pl-1" src={filtericon} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-2">
                            <div
                              className="download_report imgcursor"
                            >
                              <div className="d-flex ">
                                <div className="pt-1">
                                  <img src={downloadicon} alt="" />
                                </div>
                                <div>
                                  <p className="navcolor font-weight-bold 10 pl-2 p-1 pt-2 fontsize10" onClick={(e) => {
                                    toggledaterangepicker(e);
                                  }}>
                                    {startDate && !daterangetoggle && endDate
                                      ? "(" +
                                      moment(startDate).format("DD-MM-yyyy") +
                                      ")" +
                                      "-" +
                                      "(" +
                                      moment(endDate).format("DD-MM-yyyy") +
                                      ")"
                                      : "Date"}
                                  </p>
                                </div>
                                {
                                  daterangetoggle === false && endDate ?
                                    <p className="font-weight-bold br_round bgcolor   ml-2 float-right fontsize16" onClick={() => { cleardatefilter() }}>x</p> : ''
                                }
                              </div>
                            </div>
                          </div>
                          <div className="heightclaim col-lg-12 px-0">
                            {myclaimslist.length===0 ? <><p className="greycolor text-center font-weight-bold">No Result Found</p></> :  myclaimslist?.map((cval) => {
                              return (
                                <>
                                  <div
                                    className="col-lg-12 px-4 imgcursor"
                                    onClick={() => {
                                      claimdetails(
                                        cval.amount,
                                        cval.submitted_on,
                                        cval.type,
                                        cval.id,
                                        cval.claim_type,
                                        cval.status
                                      );
                                    }}
                                  >
                                    <div className="border row my-2 " style={{alignItems:'center'}}>
                                      <div className="col-lg-2 px-0">
                                        <img
                                          src={imageicon}
                                          alt=""
                                          className=" ml-2"
                                          width={30}
                                        />
                                      </div>
                                      <div className="col-lg-7 px-0">
                                        <div className="py-1 pl-1">
                                          <p className="fontsize8 m-0 p-0">
                                            You
                                          </p>
                                          <p className="fontsize10 m-0 p-0">
                                            Rs. {cval.amount}
                                          </p>
                                          <p className="fontsize8 m-0 p-0">
                                            
                                            {cval.submitted_on.split(' ')[0]}
                                          </p>
                                          <p className="fontsize8 m-0 p-0 navcolor">
                                            ({cval.status}){cval.type}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 px-0">
                                        <p className=" float-right mr-3">
                                          {">"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                            
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {section2 && (
                    <>
                      {togglenew === "a" && (
                        <>
                          <div className="col-lg-9">
                            <div className="row bgcolor py-2">
                              <div className="col-lg-1 pr-0">
                                <img
                                  src={
                                    claimcarddetail.profile
                                      ? claimcarddetail.profile
                                      : imageicon2
                                  }
                                  className="mt-1 claimimg"
                                  width={25}
                                  alt=""
                                />
                              </div>
                              <div className="col-lg-11 px-0">
                                <p className="pt-1">{typename.name}</p>
                              </div>
                            </div>
                            <div className="claimheight">
                              <div className="row">
                                <div className="col-lg-12">
                                  <p className="fontsize14 mt-3 mb-2">Claim</p>
                                </div>
                                <div className="col-lg-10 ">
                                  <div className="bgwhite bordershadow p-3">
                                    <p>Rs. {claimcarddetail.amount}</p>
                                    <p className="fontsize8 m-0 p-0">
                                      {claimcarddetail.date.split(' ')[0]}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-12 mt-4">
                                  <p>Claim Breakdown</p>
                                </div>
                                <div className="col-lg-10 mt-2">
                                <div id="accordion">
                                {
            tablearr?.map((val,index)=>{
              return <>
             
                  <div class="card">
    <div class="card-header" id="heading">
      <h5 className="mb-0 temp" data-toggle="collapse" data-target={'#collapseOne'+index} aria-expanded={index===0 ? "true" :"false"} aria-controls="collapseOne">
        <button className="btn btn-link w-100" >
          <div className="row">
            <div className="col-lg-6">
            <p className="fontsize12 float-left">category : {val.type}</p>
            </div>
            <div className="col-lg-6 ">
               <img src={arrowup} alt="" className="arrowup float-right" />
               <img src={arrowdown} alt=""  className="arrowdown float-right"/>
            </div>
          </div>
        </button>
      </h5>
    </div>
    <div id={"collapseOne" + index} className={index===0 ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-6">
            <p className="fontsize12 ">Sub-Category</p>
          </div>
          <div className="col-lg-6">
            <p className="fontsize12">{val.sub_type}</p>
          </div>
          <div className="col-lg-6">
            <p className="fontsize12 ">currency</p>
          </div>
          <div className="col-lg-6">
            <p className="fontsize12">{val.currency}</p>
          </div>
          <div className="col-lg-6">
            <p className="fontsize12 ">amount</p>
          </div>
          <div className="col-lg-6">
            <p className="fontsize12">{val.amount}</p>
          </div>
          {tablearr[0]?.type === "Incentive" ? (
                                          <>
                                      <div className="col-lg-6">
            <p className="fontsize12 ">Customer Name</p>
          </div>
          <div className="col-lg-6">
           <p className="fontsize12">{val.customer_name}</p>
          </div>
          <div className="col-lg-6">
            <p className="fontsize12 ">Description</p>
          </div>
          <div className="col-lg-6">
            <p className="fontsize12">{val.description}</p>
          </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                                 {tablearr[0]?.type === "Mileage" ? (
                                          <>
                                         
                                            <div className="col-lg-6">
                                             <p className="fontsize12 ">Start Location</p> 
                                            </div>
                                            <div className="col-lg-6">
                                              <p className="fontsize12">{val.start_location}</p>
                                            </div>
                                       
                                            <div className="col-lg-6">
                                             <p className="fontsize12 ">End Location</p> 
                                            </div>
                                            <div className="col-lg-6">
                                             <p className="fontsize12">{val.end_location}</p>
                                            </div>
                                        
                                          
                                            <div className="col-lg-6">
                                             <p className="fontsize12 ">Distance</p> 
                                            </div>
                                            <div className="col-lg-6">
                                               <p className="fontsize12">{val.distance}</p>
                                            </div>
                                      
                                          </>
                                        ) : (
                                          ""
                                        )}
                                       
                                          <div className="col-lg-6">
                                            <p className="fontsize12 ">Attachment</p> 
                                          </div>
                                          <div className="col-lg-6">
                                          {
                                                    val.bill_report.includes("png") ?
                                                      <img src={val.bill_report} alt="" className="" width={20} height={20} data-toggle="modal" data-target="#exampleModalCenter" onClick={() => { imgviewer(val.bill_report) }} /> : val.bill_report.includes("jpg") ? <img src={val.bill_report} alt="" className="" width={20} height={20} data-toggle="modal" data-target="#exampleModalCenter" onClick={() => { imgviewer(val.bill_report) }} /> : <img src={pdficon} alt="" data-toggle="modal" data-target="#exampleModalCenter" onClick={() => { imgviewer(val.bill_report) }} />
                                                  }
                                          </div>
                                          {
                                                    val.custom_fields === "" || null ? '' : <><p className="col-lg-12 fontsize12">Additional Requirements:</p></> 
                                                  }
                                                      {val?.custom_fields === ""
                                                    ? ""
                                                    : val?.custom_fields?.map((cval, idx) => {
                                                      return (
                                                        <>
                                                          
                                                            {cval.field_type ===
                                                              "date" ? (
                                                              <>
                                                                <span className="adddatepicker col-lg-12">
                                                                  <DatePicker
                                                                    name="value"
                                                                    autoComplete="off"
                                                                    className="  form-control my-2 col-lg-12 form-control-sm"
                                                                    disabled={
                                                                      cval?.field_resp ===
                                                                        userid
                                                                        ? false
                                                                        : cval?.field_resp ===
                                                                          null
                                                                          ? false
                                                                          : true
                                                                    }
                                                                    value={
                                                                      cval.value
                                                                    }
                                                                    
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChange2(
                                                                        idx,
                                                                        e,
                                                                        cval.field_type,
                                                                        index
                                                                      )
                                                                    }
                                                                    placeholderText={
                                                                      cval.field_name
                                                                    }
                                                                    required={
                                                                      cval.required_type
                                                                    }
                                                                  />
                                                                </span>
                                                              </>
                                                            ) : cval.field_type ===
                                                              "checkbox" ? (
                                                              <>
                                                                <div className="d-flex col-lg-12 checkboxcss">
                                                                  <div className="">
                                                                    <input
                                                                      name="value"
                                                                      type={
                                                                        cval.field_type
                                                                      }
                                                                      className="form-control mt-0 pt-0"
                                                                      disabled={
                                                                        cval?.field_resp ===
                                                                          userid
                                                                          ? false
                                                                          : cval?.field_resp ===
                                                                            null
                                                                            ? false
                                                                            : true
                                                                      }
                                                                      defaultChecked={cval.value===false ? false : cval.value==="false" ? false : cval.value==="true" ? true : cval.value===null ? false :  true }
                                                                      //  checked={cval.value}
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChange2(
                                                                          idx,
                                                                          e,
                                                                          cval.field_type,
                                                                          index
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                  <p className="fontsize12 greycolor pt-2 pl-3">
                                                                    {
                                                                      cval.field_name
                                                                    }{" "}
                                                                  </p>
                                                                </div>
                                                                
                                                              </>
                                                            ) : (
                                                              <div className="col-lg-12">
                                                                <input
                                                                  name="value"
                                                                  type={
                                                                    cval.field_type
                                                                  }
                                                                  className=" form-control col-lg-12 mt-2 form-control-sm"
                                                                  readOnly={
                                                                    cval?.field_resp ===
                                                                      userid
                                                                      ? false
                                                                      : cval?.field_resp ===
                                                                        null
                                                                        ? false
                                                                        : true
                                                                  }
                                                                  placeholder={
                                                                    cval.field_name
                                                                  }
                                                                  autoComplete="off"
                                                                  value={
                                                                    cval.value
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChange2(
                                                                      idx,
                                                                      e,
                                                                      cval.field_type,
                                                                      index
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                            )}
                                                          
                                                        </>
                                                      )
                                                    }
                                                    )}
                                        

        </div>
      </div>
    </div>
  </div>
              
              </>
            })
          }
</div>
                                  {/* <table className="table mb-0 table-sm bgwhite">
                                    <thead>
                                      <tr>
                                        <th className="fontsize12 nowrap" scope="col">
                                          S. No.
                                        </th>
                                        <th className="fontsize12" scope="col">
                                          Category
                                        </th>
                                        <th className="fontsize12 nowrap" scope="col">
                                          Sub-Category
                                        </th>
                                        <th className="fontsize12" scope="col">
                                          Currency
                                        </th>
                                        <th className="fontsize12" scope="col">
                                          Amount
                                        </th>

                                        {tablearr[0]?.type === "Incentive" ? (
                                          <>
                                            <th className=" fontsize12 nowrap" scope="col">
                                              Customer Name
                                            </th>
                                            <th className=" fontsize12" scope="col">
                                              Description
                                            </th>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {tablearr[0]?.type === "Mileage" ? (
                                          <>
                                            <th className=" fontsize12 nowrap" scope="col">
                                              {" "}
                                              Start Location
                                            </th>
                                            <th className=" fontsize12 nowrap" scope="col">
                                              End Location
                                            </th>
                                            <th className="nowrap fontsize12" scope="col">
                                              Distance
                                            </th>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <th className="fontsize12" scope="col">
                                          Attachment
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tablearr.length === 0 ? (
                                        <>
                                          <tr>
                                            <td
                                              className="greycolor fontsize10"
                                              colSpan={5}
                                            >
                                              No Breakdown
                                            </td>
                                          </tr>
                                        </>
                                      ) : (
                                        tablearr?.map((val, index) => {
                                          return (
                                            <>

                                              <tr>
                                                <td className="greycolor fontsize10 text-center">
                                                  {index + 1}
                                                </td>
                                                <td className="greycolor fontsize10 text-center">
                                                  {val.type}
                                                </td>
                                                <td className="greycolor fontsize10 text-center">
                                                  {val.sub_type}
                                                </td>
                                                <td className="greycolor fontsize10 text-center">
                                                  {val.currency}
                                                </td>
                                                <td className="greycolor fontsize10 text-center">
                                                  {val.amount}
                                                </td>

                                                {tablearr[0]?.type === "Incentive" ? (
                                                  <>
                                                    <td className="greycolor fontsize10 text-center">
                                                      {val?.customer_name}
                                                    </td>
                                                    <td className="greycolor fontsize10 text-center">
                                                      {val?.description}
                                                    </td>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                {tablearr[0]?.type === "Mileage" ? (
                                                  <>
                                                    <td className="greycolor fontsize10 text-center">
                                                      {val?.start_location}
                                                    </td>
                                                    <td className="greycolor fontsize10 text-center">
                                                      {val?.end_location}
                                                    </td>
                                                    <td className="greycolor fontsize10 text-center">
                                                      {val?.distance}

                                                    </td>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                <td className="greycolor fontsize10 text-center">
                                                  {
                                                    val.bill_report.includes("png") ?
                                                      <img src={val.bill_report} alt="" className="" width={20} height={20} data-toggle="modal" data-target="#exampleModalCenter" onClick={() => { imgviewer(val.bill_report) }} /> : val.bill_report.includes("jpg") ? <img src={val.bill_report} alt="" className="" width={20} height={20} data-toggle="modal" data-target="#exampleModalCenter" onClick={() => { imgviewer(val.bill_report) }} /> : <img src={pdficon} alt="" data-toggle="modal" data-target="#exampleModalCenter" onClick={() => { imgviewer(val.bill_report) }} />
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  className=" fontsize10 "
                                                  colSpan={10}
                                                >
                                                  {" "}
                                                  {
                                                    val.custom_fields === "" || null ? '' : 'Additional Requirements:'
                                                  }

                                                  {val?.custom_fields === ""
                                                    ? ""
                                                    : val?.custom_fields?.map((cval, idx) => {
                                                      return (
                                                        <>
                                                          <div className="">
                                                            {cval.field_type ===
                                                              "date" ? (
                                                              <>
                                                                <span className="adddatepicker">
                                                                  <DatePicker
                                                                    name="value"
                                                                    autoComplete="off"
                                                                    className="  form-control my-2 w-100 form-control-sm"
                                                                    disabled={
                                                                      cval?.field_resp ===
                                                                        userid
                                                                        ? false
                                                                        : cval?.field_resp ===
                                                                          null
                                                                          ? false
                                                                          : true
                                                                    }
                                                                    value={
                                                                      cval.value
                                                                    }
                                                                    
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChange2(
                                                                        idx,
                                                                        e,
                                                                        cval.field_type,
                                                                        index
                                                                      )
                                                                    }
                                                                    placeholderText={
                                                                      cval.field_name
                                                                    }
                                                                    required={
                                                                      cval.required_type
                                                                    }
                                                                  />
                                                                </span>
                                                              </>
                                                            ) : cval.field_type ===
                                                              "checkbox" ? (
                                                              <>
                                                                <div className="d-flex checkboxcss">
                                                                  <div className="">
                                                                    <input
                                                                      name="value"
                                                                      type={
                                                                        cval.field_type
                                                                      }
                                                                      className="form-control"
                                                                      disabled={
                                                                        cval?.field_resp ===
                                                                          userid
                                                                          ? false
                                                                          : cval?.field_resp ===
                                                                            null
                                                                            ? false
                                                                            : true
                                                                      }
                                                                      defaultChecked={cval.value===false ? false : cval.value==="false" ? false : cval.value==="true" ? true : cval.value===null ? false :  true }
                                                                      //  checked={cval.value}
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChange2(
                                                                          idx,
                                                                          e,
                                                                          cval.field_type,
                                                                          index
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                  <p className="fontsize12 greycolor pt-2 pl-3">
                                                                    {
                                                                      cval.field_name
                                                                    }{" "}
                                                                  </p>
                                                                </div>
                                                                
                                                              </>
                                                            ) : (
                                                              <input
                                                                name="value"
                                                                type={
                                                                  cval.field_type
                                                                }
                                                                className="w-100 form-control my-2 form-control-sm"
                                                                readOnly={
                                                                  cval?.field_resp ===
                                                                    userid
                                                                    ? false
                                                                    : cval?.field_resp ===
                                                                      null
                                                                      ? false
                                                                      : true
                                                                }
                                                                placeholder={
                                                                  cval.field_name
                                                                }
                                                                autoComplete="off"
                                                                value={
                                                                  cval.value
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) =>
                                                                  handleChange2(
                                                                    idx,
                                                                    e,
                                                                    cval.field_type,
                                                                    index
                                                                  )
                                                                }
                                                              />
                                                            )}
                                                          </div>
                                                        </>
                                                      )
                                                    }
                                                    )}
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })
                                      )}
                                    </tbody>
                                  </table> */}
                                </div>
                                
                              </div>
                              <div className="row">
                              <div className="col-lg-12 my-4 mb-1">
                                <p className="fontsize12">Approval Status</p>
                              </div>
                              </div>
                              {
                                timelinedata?.map((val, index) => {
                                  return <>
                                    <div className="">
                                    {
                                        timelinedata[index]?.decision === 'Requested' ? <> 
                                        <img src={timeline2} alt="" className="iconposprev mt-1" width={18} onClick={() => {
                                           handleprevioustransfer();
                                        }} data-toggle="modal" data-target="#exampleModalCenter2"/> <div className="connector2">
                                        </div></>  : ''
                                      }
                                   
                                        <div className="d-flex ">
                                        <div>
                                          <img src={timeline1} alt="" width={18} />
                                        </div>
                                        <div className="pl-2">
                                          <p className="fontsize12 ">
                                            {val.approver_full_name}{" "} <span className="fontsize12 navcolor font-weight-bold ">{val.decision ? '(' + val.decision + ')' : ''} </span>
                                          </p>
                                          <p className="fontsize10 greycolor ">Level {' '}{index + 1}</p>
                                        </div>
                                      </div>
                                      {
                                        timelinedata.length === index + 1 ? '' : <div className="connector2">
                                        </div>
                                      }
                                      {
                                        timelinedata[index]?.decision === 'Requested' && timelinedata[timelinedata.length-1]?.decision!== 'Requested' ? <> 
                                        <img src={timeline2} alt="" className="iconposprev mt-1" width={18} onClick={() => {
                                          handlenexttransfer('2');
                                        }} data-toggle="modal" data-target="#exampleModalCenter2"/> <div className="connector2">
                                        </div></>  : ''
                                      }
                                      {
                                       timelinedata[timelinedata.length-1]?.decision=== 'Requested' && timelinedata[index]?.decision === 'Requested'  ? <> 
                                       <div className="connector2">
                                        </div>
                                        <img src={timeline2} alt="" className="iconposprev mt-1" width={18} onClick={() => {
                                          handlenexttransfer('2');
                                        }} data-toggle="modal" data-target="#exampleModalCenter2"/> </>  : ''
                                      }
                                      {/* {
                                        timelinedata[index]?.decision === 'Requested' ? <img src={timeline2} alt="" width={18} onClick={() => { 
                                          handleprevioustransfer();
                                        }} /> : ''
                                      }
                                      {
                                        timelinedata[index - 1]?.decision === 'Requested' ? <img src={timeline2} alt="" width={18} onClick={() => {
                                          handlenexttransfer();
                                        }} /> : ''
                                      }
                                      {
                                        timelinedata[0]?.decision === 'Requested' ? <div className="connector">
                                        </div> : ''
                                      }
                                      {
                                        index !== 0 ? <div className="connector">
                                        </div> : ''
                                      }

                                      <div className="d-flex ">
                                        <div>
                                          <img src={timeline1} alt="" width={18} />
                                        </div>
                                        <div className="pl-2">
                                          <p className="fontsize12 ">
                                            {val.approver_full_name}{" "} <span className="fontsize12 navcolor font-weight-bold ">{val.decision ? '(' + val.decision + ')' : ''} </span>
                                          </p>
                                          <p className="fontsize10 greycolor ">Level {' '}{index + 1}</p>
                                        </div>
                                      </div>
                                      {timelinedata.length === index + 1 ? (
                                        ""
                                      ) : (
                                        <div className="connector2">
                                        </div>
                                      )} */}
                                      {/* {
                                        timelinedata[timelinedata.length-1].decision==='Requested' ? <>
                                        <div className="connector2">
                                        </div>
                                        <img src={timeline2} alt="" width={18} onClick={() => {
                                          handlenexttransfer();
                                        }} />
                                        </>  : ''
                                      } */}

                                    </div>
                                  </>
                                })
                              }
                              {/* <Timeline
                                sx={{
                                  [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                  },
                                }}
                              >
                                {timelinedata?.map((val, index) => {
                                  return (
                                    <>
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          {
                                   timelinedata[index]?.decision==='Pending' ?  <>  <img src={timeline2} alt="" width={18}  onClick={()=>{handleprevioustransfer()}}/> <TimelineConnector /> </> : ''
                                   }

                                          <div>
                                            <img
                                              src={timeline1}
                                              alt=""
                                              width={18}
                                            />
                                          </div>
                                          <TimelineConnector />
                                          {timelinedata.length === index + 1 ? (
                                            ""
                                          ) : (
                                            <TimelineConnector />
                                          )}
                                          {timelinedata.length === index + 1 ? (
                                            ""
                                          ) : (
                                            <>
                                              <div>
                                                <TimelineConnector />

                                                {timelinedata[index]
                                                  ?.decision === "Requested" ? (
                                                  <img
                                                    src={timeline2}
                                                    alt=""
                                                    width={18}
                                                    onClick={() => {
                                                      handlenexttransfer();
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <TimelineConnector />
                                              <div>
                                                <TimelineConnector />

                                                {timelinedata[index+1]
                                                  ?.decision === "Requested" ? (
                                                  <img
                                                    src={timeline2}
                                                    alt=""
                                                    width={18}
                                                    onClick={() => {
                                                      handlenexttransfer();
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <TimelineConnector />
                                            </>
                                          )}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                        <p className="fontsize12">
                                            {val.approver_full_name}{" "} <span className="fontsize12 navcolor font-weight-bold">{val.decision ? '('+val.decision+')' : ''} </span>
                                          </p>
                                  
                                          <p className="fontsize10 greycolor">Level {' '}{index+1}</p>
                                        </TimelineContent>
                                      </TimelineItem>
                                    </>
                                  );
                                })}
                              </Timeline> */}
                            </div>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="float-right  mt-2">
                                  {/* <button className='btn btn-primary bgcolor ml-3 fontsize12' onClick={()=>{handletransferclaim()}}>Transfer Claim</button> */}
                                  <button
                                    className="btn btn-primary bgcolor ml-3 fontsize12"
                                    onClick={() => {
                                      handlenexttransfer('3')
                                    }}
                                    data-toggle="modal" data-target="#exampleModalCenter2"
                                  >
                                    Transfer{" "}
                                  </button>
                                  <button
                                    className="btn btn-primary bgcolor ml-3 fontsize12"
                                    onClick={() => {
                                      denyrequest();
                                    }}
                                  >
                                    Deny{" "}
                                  </button>
                                  <button
                                    className="btn btn-primary bgcolor ml-3 fontsize12"
                                    onClick={() => {
                                      approverequest();
                                    }}
                                  >
                                    Approve
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {togglenew === "b" && (
                        <>
                          <div className="col-lg-9">
                            <div className="row bgcolor py-2">
                              <div className="col-lg-1 pr-0">
                                <img
                                  src={imageicon2}
                                  className="mt-1"
                                  width={25}
                                  alt=""
                                />
                              </div>
                              <div className="col-lg-11 px-0">
                                <p className="pt-1">You</p>
                              </div>
                            </div>
                            <div className="claimheight">
                              <div className="row">
                                <div className="col-lg-12">
                                  <p className="fontsize14 mt-3 mb-2">Claim</p>
                                </div>
                                <div className="col-lg-10 ">
                                  <div className="bgwhite bordershadow p-3">
                                    <p>{claimcarddetail.amount}</p>
                                    <p className="fontsize8 m-0 p-0">
                                    {claimcarddetail.date.split(' ')[0]}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-12 mt-4">
                                  <p>Claim Breakdown</p>
                                </div>
                                <div className="col-lg-10 mt-2 scroll-x">
                                  <table className="table table-sm bgwhite mb-0 ">
                                    <thead>
                                      <tr>
                                        <th className="fontsize12 nowrap" scope="col">
                                          S. No.
                                        </th>
                                        <th className="fontsize12" scope="col">
                                          Category
                                        </th>
                                        <th className="fontsize12 nowrap" scope="col">
                                          Sub-Category
                                        </th>
                                        <th className="fontsize12" scope="col">
                                          Currency
                                        </th>
                                        <th className="fontsize12" scope="col">
                                          Amount
                                        </th>

                                        {tablearr[0]?.type === "Incentive" ? (
                                          <>
                                            <th className=" fontsize12 nowrap" scope="col">
                                              Customer Name
                                            </th>
                                            <th className=" fontsize12" scope="col">
                                              Description
                                            </th>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {tablearr[0]?.type === "Mileage" ? (
                                          <>
                                            <th className=" fontsize12 nowrap" scope="col">
                                              {" "}
                                              Start Location
                                            </th>
                                            <th className=" fontsize12 nowrap" scope="col">
                                              End Location
                                            </th>
                                            <th className="nowrap fontsize12" scope="col">
                                              Distance
                                            </th>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <th className="fontsize12" scope="col">
                                          Attachment
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tablearr.length === 0 ? (
                                        <>
                                          <tr>
                                            <td
                                              className="greycolor fontsize10"
                                              colSpan={5}
                                            >
                                              No Breakdown
                                            </td>
                                          </tr>
                                        </>
                                      ) : (
                                        tablearr?.map((val, index) => {
                                          return (
                                            <>
                                              <tr>
                                                <td className="greycolor fontsize10 text-center">
                                                  {index + 1}
                                                </td>
                                                <td className="greycolor fontsize10 text-center">
                                                  {val.type}
                                                </td>
                                                <td className="greycolor fontsize10 text-center">
                                                  {val.sub_type}
                                                </td>
                                                <td className="greycolor fontsize10 text-center">
                                                  {val.currency}
                                                </td>
                                                <td className="greycolor fontsize10 text-center">
                                                  {val.amount}
                                                </td>

                                                {tablearr[0]?.type === "Incentive" ? (
                                                  <>
                                                    <td className="greycolor fontsize10 text-center">
                                                      {val?.customer_name}
                                                    </td>
                                                    <td className="greycolor fontsize10 text-center">
                                                      {val?.description}
                                                    </td>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                {tablearr[0]?.type === "Mileage" ? (
                                                  <>
                                                    <td className="greycolor fontsize10 text-center">
                                                      {val?.start_location}
                                                    </td>
                                                    <td className="greycolor fontsize10 text-center">
                                                      {val?.end_location}
                                                    </td>
                                                    <td className="greycolor fontsize10 text-center">
                                                      {val?.distance}
                                                    </td>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                <td className="greycolor fontsize10 text-center">

                                                  {
                                                    val.bill_report.includes("png") ?
                                                      <img src={val.bill_report} alt="" className="" width={20} height={20} data-toggle="modal" data-target="#exampleModalCenter" onClick={() => { imgviewer(val.bill_report) }} /> : val.bill_report.includes("jpg") ? <img src={val.bill_report} alt="" className="" width={20} height={20} data-toggle="modal" data-target="#exampleModalCenter" onClick={() => { imgviewer(val.bill_report) }} /> : <img src={pdficon} alt="" data-toggle="modal" data-target="#exampleModalCenter" onClick={() => { imgviewer(val.bill_report) }} />
                                                  }
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 mt-3">
                                <p>Approval Status</p>
                                </div>
                              </div>
                              <Timeline
                                sx={{
                                  [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                  },
                                }}
                              >
                                {timelinedata?.map((val, index) => {
                                  return (
                                    <>
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          <div>
                                            <img
                                              src={timeline1}
                                              alt=""
                                              width={18}
                                            />
                                          </div>
                                          {timelinedata?.length ===
                                            index + 1 ? (
                                            ""
                                          ) : (
                                            <TimelineConnector />
                                          )}
                                          {/* {
                                    timelinedata.length===index+1 ? '' :<>
                                             <div>
                                   <TimelineConnector /> 
                                   {
                                   timelinedata.slice(0).reverse()[index]?.decision==='Pending' ?  <img src={timeline2} alt="" width={18}  onClick={()=>{handleprevioustransfer()}}/> :  <img src={timeline2} alt="" width={18}  />
                                   }
          </div>
          <TimelineConnector /></>
                                  } */}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <p className="fontsize12">
                                            {val.approver_full_name}{" "} <span className="fontsize12 navcolor font-weight-bold">{val.decision ? '(' + val.decision + ')' : ''} </span>
                                          </p>

                                          <p className="fontsize10 greycolor">Level {' '}{index + 1}</p>
                                        </TimelineContent>
                                      </TimelineItem>
                                    </>
                                  );
                                })}
                              </Timeline>
                            </div>
                            {timelinedata[timelinedata.length - 1]?.decision === 'Approved' || timelinedata[0]?.decision === 'Rejected' || timelinedata?.find(obj => obj.decision === 'withdrawn') ? (
                              ""
                            ) : (
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="float-right  mt-2">
                                    <button

                                      className="btn btn-primary bgcolor ml-3 fontsize12"
                                      onClick={() => {
                                        handlewithdraw();
                                      }}
                                    >
                                      Withdraw Claim
                                    </button>
                                  </div>

                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {addreq && <></>}
                </div>
              </>
            )}
            <div className="datepicker">
              {daterangetoggle === true && (
                <>
                  <DatePicker

                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}

                    inline
                    selectsRange
                    onClickOutside={() => {
                      toggledaterangepicker();
                    }}
                  />
                  <div className="d-flex">
                    {/* <button
                      className="btn btn-primary w-100 mx-1"
                      onClick={() => {
                        toggledaterangepicker();
                      }}
                    >
                      Close
                    </button> */}
                    <button
                      className="btn btn-primary w-100 mx-1"
                      onClick={() => {
                        toggledaterangepicker();
                      }}
                    >
                      Filter
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> */}
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                {
                  imgview?.includes('jpg') ?
                    <img src={imgview} alt="" className="img-fluid" /> : imgview?.includes('png') ? <img src={imgview} alt="" className="img-fluid" /> : <iframe src={imgview} frameborder="0" className="pdfcss" height={500}></iframe>
                }
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle2" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{prev==='1' ? 'Add Previous Approver' :  'Add Next Approver' }</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>{handleclosemodal()}} >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
              {transfertoggle && (
                                  <>
                                  <div className="row">

                                    <div className="col-lg-12 my-2 mb-3">
                                      <div className="d-flex">
                                        <input
                                          className="form-control "
                                          type="text"
                                          placeholder="Search Members"
                                          value={searchterm}
                                          onChange={(e) => {
                                            setsearchterm(e.target.value);
                                          }}
                                        />
                                        <div className="px-2 searchbg pt-1  pl-2 imgcursor ">
                                          <img
                                            className=""
                                            src={searchlogo}
                                            alt=""
                                            onClick={() => {
                                              searchuser();
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {/* <p className='fontsize8 m-0 pt-2'>Choose the users to whom you would like to grant access </p> */}
                                    </div>
                                  </div>
                                    <div className="col-lg-12">
                                      {searchemp.length==0 && searchtoggle ? <><p className="greycolor font-weight-bold">No Employee Found!</p></> : searchemp?.map((cval) => {
                                        return (
                                          <>
                                          <div className="row">
                                            <div className="div border bgwhite mb-2 col-lg-12  ">
                                              <div className="d-flex border_bottom mx-2">
                                                <div className=" pl-2 pt-2 pb-2">
                                                  <img
                                                    className="m-auto imgview"
                                                    src={
                                                      cval.profile_pic
                                                        ? cval.profile_pic
                                                        : imageicon
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="col-lg-6">
                                                  <p className="fontsize12 pt-3">
                                                    {cval.fname +
                                                      " " +
                                                      cval.lname}
                                                  </p>
                                                </div>
                                                <div className=" w-100 pt-3 pr-3">
                                                  <h6
                                                    className="navcolor font-weight-bold float-right imgcursor"
                                                    onClick={() => {
                                                      transferclaim(
                                                        cval.userid
                                                      );
                                                    }}
                                                    data-dismiss="modal"
                                                  >
                                                    Add
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </>
                                )}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={()=>{handleclosemodal()}}>Close</button>
                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Claims;
