import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import Switch from 'react-switch'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import Sidebar from '../../../components/Sidebar'
import searchlogo from '../../../icons/search-interface-symbol 1.png'
import addicon from '../../../icons/Vector (14).png'
import downloadicon from '../../../icons/Vector (15).png'
import filtericon from '../../../icons/Vector (17).png'
import editicon from '../../../icons/Vector (18).png'
import viewreporticon from '../../../icons/View Reports.png'
import detailsicon from '../../../icons/Vector (19).png';
import droparrow from '../../../icons/Vector (20).png';
import firsticon from '../../../icons/first.png'
import eyeicon from "../../../icons/Vector (4).png"
import eyeicon2 from "../../../icons/eyeicon.png"
import secondicon from '../../../icons/second.png'
import thirdicon from '../../../icons/third.png'

const ManageEmployees = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const token = user.token
    const admin_roles = user?.user?.admin_assign_role
    console.log(admin_roles)
    const navigate = useNavigate()
    const [search,setsearch] = useState('')
    const [departments,setdepartments] = useState([])
    const [employeelist,setemployeelist] = useState([])
    const [rolelist,setrole] = useState([])
    const [detailsdata,setdetailsdata] = useState([])
    const [departmentname,setdepartmentname] = useState('')
    const [active,setactive] = useState(0)
    const [activestate,setactivestate] = useState('')
    const [Active,setActive] = useState('')
    const [InActive,setInActive] = useState('')
    const [detailstoggle, setdetailstoggle] = useState(false);
    const [tagname,settagname] = useState('')
    const [loading,setloading] = useState(false)
    const [onboardtoggle,setonboardtoggle] = useState(false)
    const [maintoggle,setmaintoggle] = useState(true)
    const [passwordType, setPasswordType] = useState("password");
    const [breadcrum,setbreadcrum] = useState('')
    const [profilepic,setprofilepic] = useState('')
    const [autofielddata, setautofileddata] = useState([])
    const [daterangetoggle, setdaterangetoggle] = useState(false)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [user_id,setuserid] = useState('')
    const [count, setCount] = useState(0);
    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };
    const [formdata,setfromdata] = useState({
      f_name:'',l_name:'',email_id:'',Password:'',phone_no:'',gender:'',dept_name:'',node_id:''
    })
    const found = admin_roles?.find(obj=>obj.route==='*.users')
    const filtered = !search
    ? employeelist
    : employeelist.filter((item) =>
        item.full_name.toLowerCase().includes(search.toLowerCase())
    );

    const buttonactive = (deptname,index)=>{
      setactive(index)
      setdepartmentname(deptname)
    }
    const handleeditemployee = (userid)=>{
      const token = user.token
      setuserid(userid)
      axios.get(`/get-v1-org-user-details?user_id=${userid}`,{
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response)=>{
        setautofileddata(response.data.data)
        const result  = response.data.data
        console.log(result)
        setfromdata({
          f_name:result[0].f_name,l_name:result[0].l_name,email_id:result[0].email_id,Password:'',phone_no:result[0].phone_no,gender:result[0].gender,dept_name:result[0].dept_id,node_id:result[0].node_id
        })
        setbreadcrum('Edit User')
        setonboardtoggle(true)
        setmaintoggle(false)
         setdetailstoggle(false)
      }).catch((error)=>{
        console.log(error)
      })
    }
    const toggledaterangepicker = async (e) => {
      setdaterangetoggle(item=> !item)
        //  if(value[0].$d){
        //   downloadreport()
        //  }
    }
    const downloadreport = async()=>{
      const token = user?.token
      const userid = user.user.id
      const start = moment(startDate).isValid() ? moment(startDate).format('yyyy-MM-DD') : ''
      const end = moment(endDate).isValid() ? moment(endDate).format('yyyy-MM-DD') : ''
       if(!end){
        toast.error("Please select end date!", {
          position: "top-center"
        });
      }
       else if(start===end){
        toast.error("start date and end date are same!", {
          position: "top-center"
        });
      }
       else if(start && end){
            await axios.get(`/get-v1-department-report?dept_name=${departmentname ? departmentname : 'Sales'}&start=${start}&end=${end}`, {
      responseType: "arraybuffer", headers: {
        "Content-Type": "blob",
        "Authorization": `Bearer ${token}`
      }
    }).then((res) => {
      const fileURL = URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", "Report.xlsx");
      document.body.appendChild(link);
      link.click();
      setdaterangetoggle(false)
    }).catch((error) => {
      console.log(error)
    })
      }
      else{
        toggledaterangepicker()
      }
    }
    const handleonboard = ()=>{
      setautofileddata([])
      setbreadcrum('Onboard User')
      setonboardtoggle(true)
      setmaintoggle(false)
       setfromdata({
        f_name:'',l_name:'',email_id:'',Password:'',phone_no:'',gender:'',dept_name:'',node_id:''
      })
    }
    const handlebreadcrum = ()=>{
      setbreadcrum('')
      setonboardtoggle(false)
      setmaintoggle(true)
       setdetailstoggle(false)
       setfromdata({
        f_name:'',l_name:'',email_id:'',Password:'',phone_no:'',gender:'',dept_name:'',node_id:''
      })
      setCount(0)
      setrole([])
    }

    const viewreportfunction = (fullname,userid,profile_pic)=>{
      navigate('/viewreport',{
        state:{
          fullname: fullname,
          userid : userid,
          profile_pic: profile_pic
        }
      })
    }
    const handleinputs = (e)=>{
      const newdata = { ...formdata }
      newdata[e.target.name] = e.target.value;
      setfromdata(newdata)
    }
    const viewdetails = (fullname,userid,profile_pic)=>{
          settagname(fullname)
          setdetailstoggle(true)
          setonboardtoggle(false)
          setmaintoggle(false)
          setprofilepic(profile_pic)
          setbreadcrum('Details')
         axios.get(`/get-user-details?user_id=${userid}`,{
        headers: { "Authorization": `Bearer ${token}` }
       }).then((response)=>{
        setdetailsdata(response.data.data)
        console.log(response.data.data)
       }).catch((error)=>{
        console.log(error)
       })
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
    const handleChange = (index,user_id) => {
        axios.post('/admin-v1-user-active-inactive',{user_id},{
          headers:{"Authorization":`Bearer ${token}`}
        }).then((response)=>{
          if(response.data.statusCode===200){
            departmentlist()
          }

        }).catch((error)=>{
          console.log(error)
        })
      };
    
    const handledropdown = (e)=>{
        setactivestate(e.target.value)
        if(e.target.value==='Active'){
            setActive('1')
            setInActive('0')
        }
        else if(e.target.value==='Inactive'){
            setActive('0')
            setInActive('1')
        }
        else{
            setActive('')
            setInActive('')
        }
    }
    const handlesubmit =(e)=>{
     e.preventDefault()
      if(breadcrum==='Edit User'){
        const {f_name,l_name,email_id,phone_no,Password,gender,dept_name,node_id} = formdata
        axios.post('/update-v1-org-user-details',{f_name,l_name,email_id,phone_no,Password,gender,dept_name,node_id,user_id},{
         headers:{"Authorization":`Bearer ${token}`}
        }).then((response)=>{
           if(response.data.statusCode===200){
            toast.success("Employee details updated successfully",{
              position: "top-center"
            })
           }
           departmentlist()
        }).catch((error)=>{
         console.log(error)
        })
      }
      else{
        const {f_name,l_name,email_id,phone_no,Password,gender,dept_name,node_id} = formdata
        const role_id = node_id
        axios.post('/admin-v1-employee-onboard',{f_name,l_name,email_id,phone_no,Password,gender,dept_name,role_id},{
         headers:{"Authorization":`Bearer ${token}`}
        }).then((response)=>{
          if(response.data.statusCode===200){
            toast.success(response.data.message,{
              position: "top-center"
            })
           }
           departmentlist()
           setfromdata({
            f_name:'',l_name:'',email_id:'',Password:'',phone_no:'',gender:'',dept_name:'',node_id:''
          })
        }).catch((error)=>{
         console.log(error)
        })
      }
    }
    const employee_list = (deptname) =>{
        axios.get(`/get-v1-all-department-team?department_name=${departmentname ? departmentname : deptname}&active_user=${Active}&inactive_user=${InActive}`,{
            headers:{"Authorization":`Bearer ${token}`}
        }).then((response)=>{
            setemployeelist(response.data.data)
            setloading(true)
        })
       }
       

       const departmentlist = ()=>{
        const token = user.token
        axios.get('/get-v1-org-department-name',{
            headers:{"Authorization":`Bearer ${token}`}
        }).then((response)=>{
            const result = response.data.data
            setdepartments(result)
               employee_list(result[0]?.dept_name)
               
        })
       }
     const positionsetupfn = ()=>{
      console.log(count)
        if(autofielddata.length===0){
          setfromdata({
            f_name:formdata.f_name,l_name:formdata.l_name,email_id:formdata.email_id,Password:formdata.Password,phone_no:formdata.phone_no,gender:formdata.gender,dept_name:formdata.dept_name,node_id:''
          })
        }
        if(autofielddata.length!==0 && count >0) {
          setfromdata({
            f_name:formdata.f_name,l_name:formdata.l_name,email_id:formdata.email_id,Password:formdata.Password,phone_no:formdata.phone_no,gender:formdata.gender,dept_name:formdata.dept_name,node_id:''
          })
        }
        if(autofielddata.length!==0 && count ===0){
          setfromdata({
            f_name:formdata.f_name,l_name:formdata.l_name,email_id:formdata.email_id,Password:formdata.Password,phone_no:formdata.phone_no,gender:formdata.gender,dept_name:formdata.dept_name,node_id:autofielddata[0].node_id
          })
        }
     }
    useEffect(()=>{
      
        const rolefn = () =>{
          const token = user.token
          const {dept_name} = formdata
           axios.get(`/get-v1-org-user-role-name?dept_id=${dept_name}`,{
            headers:{"Authorization":`Bearer ${token}`}
           }).then((response)=>{
              setrole(response.data.data)
              if(breadcrum==='Edit User'){
                setCount(count+1)
              }
    
           })
        } 
               
       departmentlist()
       rolefn()
       positionsetupfn()
    },[activestate,departmentname,formdata.dept_name])
  return (
    <>
    <div className="sidebarflexproperty">
        <div>
            <Sidebar/>
        </div>
        <div className="divouterdashboard">
        <div className="dashboard col">
        <div className="row p-1 nav_bar">
            <div className="mx-4 d-flex">
              <p className='font-weight-bold  fontsize16 py-2 imgcursor navcolor breadcrumstyle' onClick={handlebreadcrum}>Manage Employees{' '}</p>
              <p className='font-weight-bold  fontsize16 py-2 imgcursor navcolor breadcrumstyle'> {breadcrum.length!==0 ? ' < '+breadcrum : '' } </p>
            </div>
          </div>
           {
            maintoggle && (
              <>
            
             <div className="row">
          <div className="col-lg-12">
              <div className="d-flex mt-2">
                   <div className="d-flex w-100">
                <input className='form-control ' type="text" placeholder='Search Employees' value={search} onChange={(e)=>{setsearch(e.target.value)}}/>
                <div className="px-2 searchbg pt-1  pl-2">
                  <img className='' src={searchlogo} alt="" />
                </div>
                   </div>
              {
                found ?              <div className="onboard_btn bgcolor brhalf ml-3 imgcursor" onClick={()=>{handleonboard()}} >
                <div className="d-flex p-1 px-3">
                    <div className="div">
                    <img src={addicon} alt="" />
                    </div>
                    <div className="div">
                    <p className='fontsize10 p-1 font-weight-bold'>Onboard</p>
                    </div>
                </div>
            </div> : ''
              }
   
                <div className="download_report ml-4 imgcursor" onClick={(e)=>{toggledaterangepicker(e)}}>
                    <div className="d-flex ">
                        <div className='pt-1'>
                        <img src={downloadicon} alt="" />
                        </div>
                    <div>
                        <p className='navcolor font-weight-bold 10 pl-2 p-1'>Report</p>
                    </div>
                    </div>
                </div>
                {/* <div className="date ml-5">
                    <div className="d-flex p-1">
                        <div>
                            <img src={dateicon} alt="" />
                        </div>
                        <div>
                            <p className='navcolor font-weight-bold 10 pl-2 '>date</p>
                        </div>
                    </div>
                </div> */}
                <div className="filter ml-5 w-50">
                    <div className="d-flex ">
                    <select className='form-control w-100' value={activestate} onChange={handledropdown}>
                       <option value="" selected>All</option>
                       <option value="Active">Activated</option>
                       <option value="Inactive">Deactivated</option>
                       </select>
                <div className="px-2 searchbg pt-1  ">
                  <img className='pl-1' src={filtericon} alt="" />
                </div>
                    </div>
                </div>
              </div>
            </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                <div className="department-list departmentscroll mb-3">
          {/* <button value="" onClick={() => { buttonactive('', 'a') }} className={active === "a" ? 'btn btn-primary bgcolor ' : 'btn btn-primary empactivebtn '}>All</button> */}
          {
            departments.map((cval, index) => {
              return <><button className={active === index ? ' btn bgcolor mt-4 font-weight-bold btn-primary' : ' btn mt-4 font-weight-bold btn-primary empactivebtn'} onClick={() => { buttonactive(cval.dept_name,index) }} key={index}>{cval.dept_name}</button></>
            })
          }
        </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 ">
                <div className=" employee-listt scrollview sticky bgwhite">
              <table className="table table-bordered table-sm ">
                <thead>
                  <tr>
                    <th className='bgcolor' scope="col">S.No.</th>
                    <th className='bgcolor' scope="col">Name</th>
                    <th className='bgcolor' scope="col">Email </th>
                    <th className='bgcolor' scope="col">Phone </th>
                    <th className='bgcolor' scope="col">Gender</th>
                    <th className='bgcolor' scope="col">Edit</th>
                    <th className='bgcolor' scope="col">Status</th>
                    <th className='bgcolor' scope="col">View Reports</th>
                    <th className='bgcolor' scope="col">Details</th>
                  </tr>
                </thead>
                <tbody >
                    {
                          filtered.length === 0 && loading? <tr><td colSpan='9'>No Result Found!</td></tr> :  employeelist && filtered.sort((a,b)=>{
                            if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) {
                              return -1;
                            }
                            if (a.full_name.toLowerCase() > b.full_name.toLowerCase()) {
                              return 1;
                            }
                          }).map((cval,index)=>{
                            return <>
                        <tr >
                        <td className='fontsize10'>{index+1}</td>
                        <td className='fontsize10'>{cval.full_name}</td>
                        <td className='fontsize10'>{cval.email_id}</td>
                        <td className='fontsize10'>{cval.phone_no}</td>
                        <td className='text-center fontsize10'>{cval.gender}</td>
                        <td className='text-center'><img src={editicon} className='imgcursor' alt="" onClick={()=>{handleeditemployee(cval.user_id)}}/></td>
                        <td className='text-center setz_index'>        <Switch
          onChange={()=>{handleChange(index,cval.user_id)}}
          checked={ cval.active_status}
          onColor="#1988C5"
          defaultChecked={cval.active_status}
        //   onHandleColor="#2693e6"
          handleDiameter={12}
          uncheckedIcon={false}
          checkedIcon={false}
          height={18}
          width={40}
          className="react-switch"
        /></td>
                        <td className='text-center imgcursor'><img src={viewreporticon} alt="" onClick={()=>{viewreportfunction(cval.full_name,cval.user_id,cval.profile_pic)}} /></td>
                        <td className='text-center imgcursor'><img src={detailsicon} alt="" onClick={()=>{viewdetails(cval.full_name,cval.user_id,cval.profile_pic)}}/></td>
                      </tr>
                            </>
                        })
                    }
                </tbody>
              </table>
            </div>
                </div>
            </div>
            </>
            )}
            <div className="div float-right my-2 daterangepicker">
                                  {
                    daterangetoggle && (
                      <>
<DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      maxDate={new Date()}
      selectsRange
      inline
    />
    <div className="d-flex">
    <button className='btn btn-primary w-100 mx-1' onClick={()=>{toggledaterangepicker()}}>Close</button>
    <button className='btn btn-primary w-100 mx-1' onClick={()=>{downloadreport()}}>Download Report</button>
    </div>
                      </>
                    )
                  }
            </div>
           {
            onboardtoggle && (
                  <form  onSubmit={handlesubmit}>
                 <div className="row">
                  <div className="col-lg-7">
                    <p className='font-weight-bold navcolor fontsize18 mt-3'>{breadcrum}</p>
                  </div>
                  <div className="col-lg-7 mt-3">
                    <div className="d-flex">
                      <input name='f_name' type="text" className='form-control' value={formdata.f_name} placeholder='First Name' onChange={(e)=>{handleinputs(e)}} required autoComplete='off'/>
                      <div className='iconshift mt-1'>
                        <img src={firsticon} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 mt-2">
                    <div className="d-flex">
                      <input name='l_name' type="text" className='form-control' value={formdata.l_name} placeholder='Last Name' onChange={(e)=>{handleinputs(e)}}  autoComplete='off'/>
                      <div className='iconshift mt-1'>
                        <img src={firsticon} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 mt-2">
                    <div className="d-flex">
                      <input name='email_id' type="text" className='form-control' value={formdata.email_id} placeholder='E-mail' onChange={(e)=>{handleinputs(e)}} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required autoComplete='off'/>
                      <div className='iconshift mt-1'>
                        <img src={thirdicon} alt="" />
                      </div>
                    </div>
                  </div>
                  {
                    autofielddata.length===0 ?        <div className="col-lg-7 mt-2">
                    <div className="d-flex">
                      <input name='Password' type={passwordType} className='form-control' value={formdata.Password} placeholder='Password' onChange={(e)=>{handleinputs(e)}} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required autoComplete='off'/>
                      <div className='iconshift mt-1'>
                      {
                      passwordType === 'password' ? <span><img  src={eyeicon} alt="" onClick={togglePassword}/></span> : <span><img  src={eyeicon2} alt="" onClick={togglePassword}/></span> 
                    }
                      </div>
                    </div>
                                 <p className='fontsize8 float-right navcolor'>Password should contain minimum 8 charachters, atleast one Uppercase, 
one lowercase & one digit </p>
                  </div> : ''
                  }
           
                  <div className="col-lg-7 mt-2">
                    <div className="d-flex">
                      <input name='phone_no' type="text" className='form-control' value={formdata.phone_no} placeholder='Phone' onChange={(e)=>{handleinputs(e)}} required pattern="[789][0-9]{9}" autoComplete='off'/>
                      <div className='iconshift mt-1'>
                        <img src={secondicon} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 mt-2 ">
                    <div className="d-flex dropplaceholder">
                  <select  name="gender"  className='form-control' value={formdata.gender} onChange={(e)=>{handleinputs(e)}} required>
                    <option value="" selected hidden disabled>Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                  {/* <div  className='iconshift mt-1'>
                    <img src={droparrow} alt="" />
                  </div> */}
                  </div>
                  </div>
                  <div className="col-lg-7 mt-2 ">
                    <div className="d-flex dropplaceholder ">
                  <select name='dept_name'  className='form-control' value={formdata.dept_name} onChange={(e)=>{handleinputs(e)}} required>
                    <option value="" selected hidden disabled>Department</option>
                       {
                        departments.map((cval)=>{
                          return <>
                          <option value={cval.dept_id}>{cval.dept_name}</option>
                          </>
                        })
                       }
                  </select>
                  {/* <div  className='iconshift mt-1'>
                    <img src={droparrow} alt="" />
                  </div> */}
                    </div>
                  </div>
                  <div className="col-lg-7 mt-2 dropplaceholder">
                    <div className="d-flex">
                  <select name="node_id"   className='form-control' value={formdata.node_id} onChange={(e)=>{handleinputs(e)}} required>
                    {/* {
                      autofielddata.length!==0 && count >1  ? <option value="" selected hidden disabled>Position</option> : autofielddata.length!==0 && count===1 ? <option value={autofielddata[0].node_id} selected hidden disabled>{autofielddata[0].node_name}</option> : <option value="" selected hidden disabled>Position</option>
                    } */}
                      
                    <option value="" selected hidden disabled>Position</option>
                       {
                        rolelist.map((cval)=>{
                          return <>
                          <option value={cval.node_id}>{cval.node_name}</option>
                          </>
                        })
                       }
                  </select>
                  {/* <div  className='iconshift mt-1'>
                    <img src={droparrow} alt="" />
                  </div>             */}
                  </div>
                  </div>
                  <div className="col-lg-7 mt-2">
                    <button className='btn btn-primary float-right'>Save</button>
                  </div>
                 </div>
                  </form>
            )
           }
          {
                        detailstoggle && (
                          <>
                                <div className="row bgwhite p-2  border_bottom">
                                <div className=" ml-2">
                               <img className='m-auto imgview' src={profilepic} alt="" />
                                </div>
                          <div className="col-lg-10 pt-1">
                              <p className='font-weight-bold textcolor'>{tagname}</p>
                          </div>
                          </div>
                          <div className="Team_details">
                  {
                    detailsdata?.map((cval)=>{
                      return <>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Full Name</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.full_name}</p>
                  </div>
                  </div>
                            <div className="row  p-3">
                  <div className="col-lg-4">
                    <p>Email</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.email_id}</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Phone No</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.phone_no}</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Gender</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.gender==='M' ? 'Male' : cval.gender==='F' ? 'Female' : 'Other'}</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Position</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.role}</p>
                  </div>
                  </div>
                      </>
                    })
                  }
             
                </div>
                          </>
                        )
                      }
                      {/* {
                        viewreporttoggle && (
                          <>
                             <div className="row bgwhite border_bottom">
            <div className="d-flex col-lg-12 p-2 pb-3">
              <div className=" ">
                <img className='m-auto imgview' src={profilepic} alt="" />
              </div>
              <div className="name col-lg-9 pt-2">
                <p className=''>{tagname}</p>
              </div>
              <div className="d-flex imgcursor " onClick={toggleform}>
                <div className="div ">
                  <img src={reportsicon} alt="" />
                </div>
                <p className={formtoggle ? 'fontcolorblue ml-2' : 'ml-2'}>Reports</p>
              </div>
              <div className=" d-flex imgcursor mx-4" onClick={togglefollowup}>
                <div className="div ">
                  <img src={trackicon} alt="" />
                </div>
                <div className="div ">
                  <p className={followuptoggle ? 'fontcolorblue ml-1' : 'ml-1'}>Track</p>
                </div>
              </div>
              
            </div>

          </div>
          {
            formtoggle && (
              <>
                <div className="row ">
                  <div className="col-lg-5">
                    <p className='m-3 fontcolorblue '>Reports</p>
                  </div>
                  <div className="col-lg-2">
                    <div className="d-flex ">
                      <DatePicker autoComplete='off' className=' input_fields ' selected={getdate} onChange={date => setdate(date)} placeholderText='Select Date' required maxDate={new Date()}/>
                      <div className="div pt-2 pl-3 calendericon">
                        <img src={calendericon} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="ml-5 pl-3 text-center imgcursor mb-1 mt-2" onClick={(e) => { toggledaterangepicker(e) }}>
                    <div className="">
                      <img className=' px-3' src={downloadicon2} alt="" />
                    </div>
                    <div className="">
                      <p className='fontsize8 fontcolorblue float-right'>Download Report</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                    </div>
                    <div className="col-lg-6 ">
                 
                    </div>
                  </div>
                  
             
                  <div className="tableheight w-100 abc">
                    <div className="sticky ">
                      <table className="table table-bordered " >
                        <thead>
                          <tr className='height10'>
                            <th className='bgcolor' scope="col">S.No.</th>
                            <th className='bgcolor' scope="col" >Clinic Name</th>
                            <th className='bgcolor' scope="col" >Current Status</th>
                            <th className='bgcolor' scope="col">Visit</th>
                            <th className='bgcolor' scope="col">Visit log</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                           visitloglist && visitloglist?.map((cval, index) => {
                              return <>
                                <tr className='height10 bgwhite' key={index}>
                                  <td className='fontsize10'>{index + 1}</td>
                                  <td className='fontsize10'>{cval.customer_name}</td>
                                  <td className='fontsize10'>{cval.status === 'N' ? 'Not Intrested' : cval.status === 'D' ? 'Closed' : cval.status === 'O' ? 'Demo' :  cval.status === 'F' ? 'Follow-Up' : cval.status === 'I' ? 'Introduction' : cval.status === 'G' ? 'Negotiation' :'Renewal'}</td>
                                  <td className='fontsize10'>{cval.no_of_visits}</td>
                                  <td onClick={() => { togglevisitlog(cval.customer_id) }} ><img src={visitlogicon} alt="" className='imgcursor' /></td>
                                </tr>
                              </>
                            })
                          }
                        </tbody>
                      </table>
                  <div className="div float-right my-2 daterangepicker">
                      {
                    daterangetoggle && (
                      <>
<DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      maxDate={new Date()}
      selectsRange
      inline
    />
    <div className="d-flex">
    <button className='btn btn-primary w-100 mx-1' onClick={()=>{toggledaterangepicker()}}>Close</button>
    <button className='btn btn-primary w-100 mx-1' >Download Report</button>
    </div>
                      </>
                    )
                  }
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }
                          </>
                        )
                      } */}
                      {/* {
                      followuptoggle && (
              <>
                <div className="row ">
                  <div className="mapstyle " >
                    {
                      <Googlemap /> 
                      }
                  </div>
                </div>
              </>
            )
          } */}
                      {/* {
            visitlogtoggle && (
              <>
                <div className=" border_bottom col-lg-12">
    
                  <div className="heightvisitlog">
                    {
                      visitlogs.length!==0 ? visitlogs.slice(0).reverse().map((cval) => {
                        return <>
                          <div className="bordervisit">

                            <div className="row p-2">
                              <div className="col-lg-6">
                                <p className='my-2'>Contact Person</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor float-right my-2'>{cval.contact_person}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Contact </p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.phone_no}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Email</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.email_id}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Subscription Model</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.subs_model_name}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>No of Visits</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.no_of_visits}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Price</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.price}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Date/Time Of Visit</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.visited_on}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>status</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.status === 'N' ? 'Not Intrested' : cval.status === 'D' ? 'Closed' : cval.status === 'O' ? 'Demo' :  cval.status === 'F' ? 'Follow-Up' : cval.status === 'I' ? 'Introduction' : cval.status === 'G' ? 'Negotiation' :'Renewal'}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Comments</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='navcolor my-2 float-right'>{cval.visitor_comments}</p>
                              </div>
                              <div className="col-lg-6">
                                <p className='my-2'>Photo</p>
                              </div>
                              <div className="col-lg-6" >
                                {
                                  cval.image ? <>
                                  <img className={toggle === cval.visit_id && toggle ? ' imgcursor bigimg2 ' : 'imageresize imgcursor float-right'} src={cval.image ? cval.image : 'NA'} alt="" onClick={() => { toggleimage(cval.visit_id) }} />
                                  {
                                    toggle === cval.visit_id && toggle ? <span className={toggle === cval.visit_id && toggleimage ? 'closebtn imgcursor' : ''} onClick={() => { toggleimage(cval.visit_id) }}>X</span> :''
                                  }
                                  </>
                                  : <><p className='navcolor my-2 float-right'>NA</p></>
                                }
                            
                              </div>
                            </div>

                          </div>
                        </>
                      })
                            :
                            <>
                            <p className='greycolor errormessage font-weight-bold'>No Visit-Log Found</p>
                            </>
                    }
                  </div>
                </div>
              </>

            )
          } */}
        </div>
        </div>
    </div>
    <ToastContainer />
    </>
  )
}

export default ManageEmployees