import React from 'react'
import Sidebar from '../../components/Sidebar'
import searchlogo from '../../icons/search-interface-symbol 1.png'
import viewassign from '../../icons/viewassign.png'
import adduser from '../../icons/adduser.png'
import locationicon from '../../icons/location.png'
// import warningicon from '../../icons/caution 1.png'
// import imageicon from '../../icons/Ellipse 9.png'
import addicon from '../../icons/Vector (26).png'
import { useState } from 'react'
import { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import usericon from '../../icons/profile.png'
import warningicon from '../../icons/caution 1.png'
import editicon from '../../icons/Vector (33).png'

const Location = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const visibility = JSON.parse(localStorage?.getItem('sidebar'))
  const token = user.token 
  const is_admin = user?.user?.is_admin
  const permissions = user?.user?.role_assign
  const permissionsadmin = user?.user?.admin_assign_role
  var foundadmin= false
  for(let i=0;i<permissions?.length;i++){
    if(permissions[i].route === 'assign-location' && permissions[i].permission!==null) {
      var array = Object?.values(permissions[i]?.permission)
    }
  }
  for(let i=0;i<permissionsadmin?.length;i++){
    if(permissionsadmin[i].route === 'assign-location' && permissions[i].permission!==null) {
      foundadmin = true
    }
  }
  const found = array?.find(obj => {
    return obj === 'C' ? true : false;
  });

  const [location, setlocation] = useState([])
  const [sublocation, setsublocation] = useState([])
  const [assigntoggle, setassigntoggle] = useState(false)
  const [locationname, setlocationname] = useState([])
  const [viewassigntoggle, setviewassigntoggle] = useState(false)
  const [assigntogglenew, setassigntogglenew] = useState(false)
  const [subposition, setpos] = useState([])
  const [mynewteam, setnewteam] = useState([])
  const [unassigned, setunassined] = useState([])
  const [locationold, setlocationid] = useState('')
  const [sublocationold, setsublocationid] = useState('')
  const [employeesearch, setemployeesearch] = useState('')
  const [locationsearch, setlocationsearch] = useState('')
  const [sublocationsearch, setsublocationsearch] = useState('')
  const [locationview, setlocationview] = useState([])
  const [completeteam, setcompleteteam] = useState([])
  const [reloadpage, setreloadpage] = useState(false)
  const [locationtoggle,setlocationtoggle] = useState('')
  const [sublocationtoggle,setsublocationtoggle] = useState('')
  const [loading, setloading] = useState(false)
  const [mainpage,setmainpage] = useState(true)
  const [addlocation,setaddlocation] = useState(false)
  const [addsublocation,setaddsublocation] = useState(false)
  const [locationdata,setlocationdata]= useState({})
  const [localname,setname] = useState('')
  const [formdata,setformdata] = useState({
    location_name:'' ,lat:'',long: ''
  })
  const [formdata2,setformdata2] = useState({
    sublocation_name:'',lat:'',long:''
  })
  const [breadcrum,setbreadcrum] = useState('')
  const togglesublocation = (locationid,index,location_name) => {
    sublocationlist(locationid)
    setlocationid(locationid)
    setname(location_name)
    setlocationtoggle((prev) => {
      return prev === index ? null : index;
  });
  }
  const togglesublocationview = (index)=>{
    setsublocationtoggle((prev) => {
      return prev === index ? null : index;
  });
  }
  const updatelocation = (location_name,location_id,lat,long)=>{
    setaddlocation(true)
    setmainpage(false)
    setaddsublocation(false)
    setlocationdata({location_name,location_id,lat,long})
    setformdata({
      location_name: location_name,lat:lat,long:long 
    })
    setbreadcrum('Edit Location')
  }
  const updatesublocation = (sublocation_name,sublocation_id,lat,long)=>{
    if(!locationold){
      toast.error("Select any location!", {
        position: "top-center"
      });
    }
    else{
      setaddlocation(false)
      setmainpage(false)
      setaddsublocation(true)
      setlocationdata({sublocation_name,sublocation_id,lat,long})
      setformdata2({
        sublocation_name: sublocation_name,lat:lat,long:long 
      })
      setbreadcrum('Edit SubLocation')
    }
  }
  const filtered = !locationsearch ?
  location.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }

  }) : location.filter((item) => {
    return item.name.toLowerCase().includes(locationsearch.toLowerCase())
  }).sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
  })
  const filtered2 = !sublocationsearch ?
  sublocation.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }

  }) : sublocation.filter((item) => {
    return item.name.toLowerCase().includes(sublocationsearch.toLowerCase())
  }).sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
  })
var filtered3
assigntogglenew ? (
  filtered3 = !employeesearch ?
  mynewteam.sort(function (a, b) {
    if (a.fname+' '+a.lname.toLowerCase() < b.fname+' '+b.lname.toLowerCase()) {
      return -1;
    }
    if (a.fname+' '+a.lname.toLowerCase() > b.fname+' '+b.lname.toLowerCase()) {
      return 1;
    }

  }) : mynewteam.filter((item) => {
    return (item.fname+' '+item.lname).toLowerCase().includes(employeesearch.toLowerCase())
  }).sort(function (a, b) {
    if ((a.fname+' '+a.lname).toLowerCase() < (b.fname+' '+b.lname).toLowerCase()) {
      return -1;
    }
    if ((a.fname+' '+a.lname).toLowerCase() > (b.fname+' '+b.lname).toLowerCase()) {
      return 1;
    }
  })
) : (
     filtered3 = !employeesearch ?
    locationview.sort(function (a, b) {
      if ((a.f_name+' '+a.l_name).toLowerCase() < (b.f_name+' '+b.l_name).toLowerCase()) {
        return -1;
      }
      if ((a.f_name+' '+a.l_name).toLowerCase() > (b.f_name+' '+b.l_name).toLowerCase()) {
        return 1;
      }
  
    }) : locationview.filter((item) => {
      return (item.f_name+' '+item.l_name).toLowerCase().includes(employeesearch.toLowerCase())
    }).sort(function (a, b) {
      if ((a.f_name+' '+a.l_name).toLowerCase() < (b.f_name+' '+b.l_name).toLowerCase()) {
        return -1;
      }
      if ((a.f_name+' '+a.l_name).toLowerCase() > (b.f_name+' '+b.l_name).toLowerCase()) {
        return 1;
      }
    })
)
  const mysubteam = async (name, locationid) => {
    setviewassigntoggle(false)
    setassigntogglenew(true)
    const token = user.token
    const userid = user?.user?.id
    setassigntoggle(true)
    setlocationname(name)
    setsublocationid('')
    const subtitle = []
    const assigned = []
    const unassigned = []
    await axios.get(`/my-teams?user_id=${userid}&location_id=${locationid}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      setcompleteteam(response.data.users)
      if (response.data.users.length !== 0) {
        for (let i = 0; i < response.data.users.length; i++) {
          if (!subtitle.includes(response.data.users[i].position) && response.data.users[i].location_assign_status === false) {
            subtitle.push(response.data.users[i].position)
          }
        }
        for (let i = 0; i < response.data.users.length; i++) {
          if (response.data.users[i].location_assign_status === true) {
            unassigned.push(response.data.users[i])
          }
          else {
            assigned.push(response.data.users[i])
          }
        }


      }
      // setloading(true)
      setpos(subtitle)
      setnewteam(assigned)
      setunassined(unassigned)
    }).catch((error) => {
      console.log(error)
    })
  }
  const mysubteamforview = async (name, locationid) => {
    setloading(false)
    setviewassigntoggle(true)
    setassigntogglenew(false)
    const token = user.token
    setassigntoggle(true)
    setlocationname(name)
    setsublocationid('')
    const subtitle = []
    const view = []
    await axios.get(`/get-location-assigned-teams?location_id=${locationid}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      // setnewteam(response.data.data)
      if (response.data.data?.length !== 0) {
        for (let i = 0; i < response.data.data?.length; i++) {
          view.push(response.data.data[i].user_id)
          if (!subtitle.includes(response.data.data[i].user_id.role)) {
            subtitle.push(response.data.data[i].user_id?.role)
          }
        }
      }
      setpos(subtitle)
      setlocationview(view)
    }).catch((error) => {
      console.log(error)
    })
  }
  const mysubnewteam = async (name, sublocationid) => {
    setviewassigntoggle(false)
    setassigntogglenew(true)
    const token = user.token
    const userid = user?.user?.id
    setassigntoggle(true)
    setlocationname(name)
    setsublocationid(sublocationid)
    const subtitle = []
    const assigned = []
    const unassigned = []
    await axios.get(`/my-teams?user_id=${userid}&location_id=&sublocation_id=${sublocationid}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      if (response.data.users.length !== 0) {
        for (let i = 0; i < response.data.users.length; i++) {
          if (!subtitle.includes(response.data.users[i].position) && response.data.users[i].location_assign_status === false) {
            subtitle.push(response.data.users[i].position)
          }
        }
        for (let i = 0; i < response.data.users.length; i++) {
          if (response.data.users[i].location_assign_status === true) {
            unassigned.push(response.data.users[i])
          }
          else {
            assigned.push(response.data.users[i])
          }
        }
      }
      setloading(true)
      setpos(subtitle)
      setnewteam(assigned)
      setunassined(unassigned)
    }).catch((error) => {
      console.log(error)
    })
  }
  const mysubnewteamforview = async (name, sublocationid) => {
    setviewassigntoggle(true)
    setassigntogglenew(false)
    setloading(false)
    const token = user.token
    const userid = user?.user?.id
    setassigntoggle(true)
    setlocationname(name)
    setsublocationid(sublocationid)
    const subtitle = []
    const view = []
    await axios.get(`/get-sublocation-assigned-teams?sublocation_id=${sublocationid}`, {
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response) => {
      if (response.data.data?.length !== 0) {
        for (let i = 0; i < response.data.data?.length; i++) {
          view.push(response.data.data[i].user_id)
          if (!subtitle.includes(response.data.data[i].user_id.role)) {
            subtitle.push(response.data.data[i].user_id.role)
          }
        }

      }
      setpos(subtitle)
      setlocationview(view)


    }).catch((error) => {
      console.log(error)
    })
  }
  const assignlocation = async (user_id) => {
    if (sublocationold === '') {
      setreloadpage(true)
      const location_id = locationold.toString()
      await axios.post('/assign-locations',{user_id, location_id}, {
        headers: {"Authorization": `Bearer ${token}` }
      }).then((response) => {
        console.log(response.status)
        if (response.status === 200) {
          toast.success("Location Assigned", {
            position: "top-center"
          });
          mysubteam(locationname, locationold)
        }
      }).catch((error) => {
        console.log(error)
      })
    }
    else {
      const location_id = locationold.toString()
      const sublocation_id = sublocationold.toString()
      setreloadpage(true)
      await axios.post('/assign-sub-locations',{user_id, location_id, sublocation_id}, {
        headers: {"Authorization": `Bearer ${token}`},
      }).then((response) => {
        if (response.status === 200) {
          toast.success("Sublocation Assigned", {
            position: "top-center"
          });
          mysubnewteam(locationname, sublocationold)
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }
  const removelocation = async (user_id) => {
    if (sublocationold === '') {
      const location_id = locationold.toString()
      await axios.post('/assign-locations-remove',{user_id, location_id}, {
        headers: {"Authorization": `Bearer ${token}`}
      }).then((response) => {
        if (response.status === 200) {
          toast.success("Location Removed", {
            position: "top-center"
          });
          mysubteam(locationname, locationold)
        }
      }).catch((error) => {
        console.log(error)
      })
    }
    else {
      const sublocation_id = sublocationold.toString()
      const location_id = locationold.toString()
      await axios.post('/assign-sublocations-remove',{user_id, location_id, sublocation_id}, {
        headers: {"Authorization": `Bearer ${token}` }
      }).then((response) => {
        if (response.status === 200) {
          toast.success("Sublocation Removed", {
            position: "top-center"
          });
          mysubnewteam(locationname, sublocationold)
        }
      }).catch((error) => {
        console.log(error)
      })
    }

  }
  const sublocationlist = async (locationid) => {
    const response = await axios.get(`/get-sub-locations?location_id=${locationid}`, {
      headers: { "Authorization": `Bearer ${token}` }
    })
    setsublocation(response.data.data)
    setloading(true)
  }
  const handlebredcrum = ()=>{
      setmainpage(true)
      setaddlocation(false)
      setaddsublocation(false)
      setbreadcrum('')
      setlocationdata({})
  }
  const handleaddlocation = ()=>{
    setaddlocation(true)
    setmainpage(false)
    setaddsublocation(false)
    setbreadcrum('Add Location')
    setformdata({
      location_name:'' ,lat:'',long: ''
    })
  }
  const handleaddsublocation = ()=>{
    if(locationold.length===0){
      toast.error("Please select any location!", {
        position: "top-center"
      });
    }
    else{
      setaddlocation(false)
      setmainpage(false)
      setaddsublocation(true)
      setbreadcrum('Add Sub-Location')
      setformdata2({
        sublocation_name:'',lat:'',long:''
      })
    }
  
  }
  const handlesubmitlocation = (e)=>{
    e.preventDefault()
    if(breadcrum==='Add Location'){
      const {location_name,lat,long} = formdata
      if(lat.length===0){
        var latitude = 0
      }
      if(long.length===0){
        var longitude = 0
      }
      axios.post('/admin-v1-create-location',{location_name,latitude,longitude},{
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response)=>{
        if(response.data.statusCode===200){
          toast.success(response.data.message, {
              position: "top-center"
            });
      }
      setformdata({
        location_name:'',lat:'',long:''
      })
      }).catch((error)=>{
        if(error.response.data.statusCode===401){
          toast.error(error.response.data.message, {
            position: "top-center"
          });
        }
      })
    }
    else { 
      const {location_name,lat,long} = formdata
      const location_id = locationold
      axios.post('/admin-v1-update-location',{location_name,location_id,lat,long},{
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response)=>{
        if(response.data.statusCode===200){
          toast.success(response.data.message, {
              position: "top-center"
            });
            getlocations()
      }
      }).catch((error)=>{
        console.log(error)
      })
    }
  }
  const handlesubmitsublocation = (e)=>{
    e.preventDefault()
    if(breadcrum==='Add Sub-Location'){
      const {sublocation_name,lat,long} = formdata2
      const location_id = locationold
      const location_name = localname
       
      if(lat.length==0 ){
        var latitude = 0
      }
      if(long.length==0 ){
        var longitude = 0
      }
      
      axios.post('/admin-v1-create-sub-location',{location_name,sublocation_name,latitude,longitude,location_id},{
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response)=>{
        if(response.data.statusCode===200){
          toast.success(response.data.message, {
              position: "top-center"
            });
      }
      setformdata2({
        sublocation_name:'',lat:'',long:''
      })
      }).catch((error)=>{
        if(error.response.data.statusCode===401){
          toast.error(error.response.data.message, {
            position: "top-center"
          });
        }
      })
    }
    else{
      const {sublocation_name,lat,long} = formdata2
      const {sublocation_id} = locationdata
      const location_id = locationold
      axios.post('/admin-v1-update-sub-location',{location_id,sublocation_id,sublocation_name,lat,long},{
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response)=>{
        if(response.data.statusCode===200){
          toast.success(response.data.message, {
              position: "top-center"
            });
      }
      sublocationlist(location_id)
      }).catch((error)=>{
        console.log(error)
      })
    }
  }
  const handlechange = (e)=>{
    const newdata = {...formdata}
    newdata[e.target.name] = e.target.value;
    setformdata(newdata)
  }
  const handlechange2 = (e)=>{
    const newdata = {...formdata2}
    newdata[e.target.name] = e.target.value;
    setformdata2(newdata)
  }
  const getlocations = async () => {
    const response = await axios.get('/get-locations', {
      headers: { "Authorization": `Bearer ${token}` }
    })
    setlocation(response.data.data)
    setloading(true)
  }
  useEffect(() => {


    getlocations()
    // sublocation()
  }, [reloadpage])
  return (
    <>
      <div className="sidebarflexproperty">
        <div className="">
          <Sidebar />
        </div>
        <div className="dashboard col">
          <div className="row p-1 nav_bar">
            <div className="mx-4 d-flex">
              <p className='font-weight-bold visit_log fontsize16 py-2 imgcursor navcolor' onClick={()=>{handlebredcrum()}}>Locations</p>
              <p className='font-weight-bold visit_log fontsize16 py-2 imgcursor navcolor'>{breadcrum ?  '>' + breadcrum : ''}</p>
            </div>
          </div>
          { mainpage && (
          <div className="row ">
            <div className="col-lg-4 bgwhite border_right ">
              <div className="row">
                <div className="col-lg-12 border_bottom d-flex">
                <div className=" upper  stickyheader">
                  <p className='my-3 mx-3'>Locations</p>
                </div>
                {
                  is_admin && visibility==='b' ?     <div className="w-100 upper  stickyheader">
                  <div className="d-flex float-right adddepartmentbtn2 imgcursor my-3 px-2" onClick={()=>{handleaddlocation()}}>
                  <div>
                         <img src={addicon} alt="" />
                  </div>
                  <p className='navcolor pl-2'>Add</p>
                </div>
                  </div>  : ''
                }
      
                </div>
                <div className="col-lg-12">
              <div className="d-flex mt-2">
                <input className='form-control ' type="text" placeholder='Search Locations' value={locationsearch} onChange={(e)=>{setlocationsearch(e.target.value)}}/>
                <div className="px-2 searchbg pt-1  pl-2">
                  <img className='' src={searchlogo} alt="" />
                </div>
              </div>
            </div>
         
               <div className="col-lg-12">
                  <div className='mt-3 '>
                   <p className='fontsize10'><i>({location.length +' ' +'locations'})</i> </p> 
                  </div>

            </div>
            
     
              </div>
              <div className="div sublocationheight">
                {
                  filtered.length === 0 && loading ? <p className=' mt-3 fontsize12 font-weight-bold text-center'><img src={warningicon} alt="" /> No Result Found!</p> : location && filtered.map((cval,index) => {
                    return (
                      <div className={locationtoggle===index ? ' locationtoggledesign col-lg-12 border my-2 imgcursor' : 'col-lg-12 border my-2 imgcursor'}  onClick={() => { togglesublocation(cval.locationid,index,cval.name) }}>
                        <div  className='d-flex'>
                          <p className='col-lg-5 mt-2 fontsize12'>{cval.name}</p>
                          {
                             JSON.parse(localStorage?.getItem('sidebar'))==='b' ?    <div className=" text-center mx-3" onClick={() => { updatelocation(cval.name, cval.locationid,cval.latitude,cval.longitude) }}>
                             <span><img src={editicon} alt="" /></span>
                             <p className='fontsize8 navcolor'>Edit</p>
                           </div> : ''
                          }
                       
                          <div className=" text-center mx-2" onClick={() => { mysubteamforview(cval.name, cval.locationid) }}>
                            <span><img src={viewassign} alt="" /></span>
                            <p className='fontsize8 navcolor'>View Assignees</p>
                          </div>
                               <div className=" text-center" onClick={() => { mysubteam(cval.name, cval.locationid) }}>
                            <img src={adduser} alt="" />
                            <p className='fontsize8 navcolor'>Assign</p>
                          </div> 
                       
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="col-lg-4 bgwhite border_right ">
              <div className="row">
                <div className="col border_bottom d-flex">
                <div className=" upper w-100 stickyheader">
                  <p className='my-3 '>Sub-Locations</p>
                </div>
                {
                  is_admin && visibility==='b' ?     <div className="w-100  upper  stickyheader" >
                  <div className="float-right   my-3 px-2 ">
                  <div>
                    <button className='bgwhite d-flex addsublocationbutton imgcursor' onClick={()=>{handleaddsublocation()}}> <img src={addicon} alt="" className='p-1'/><p className='navcolor px-2'>Add</p></button>
                  </div>
                </div> 
                </div>  : ''
                }
                </div>
                <div className="col-lg-12">
              <div className="d-flex mt-2">
                <input className='form-control ' type="text" placeholder='Search Sub-location' value={sublocationsearch} onChange={(e)=>{setsublocationsearch(e.target.value)}} />
                <div className="px-2 searchbg pt-1  pl-2">
                  <img className='' src={searchlogo} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
                  <div className='mt-3 '>
                   <p className='fontsize10'><i>({sublocation.length +' ' +'Sub-locations'})</i> </p> 
                  </div>
            </div>
              </div>
              <div className="div sublocationheight">

                {
                filtered2.length === 0 && loading ? <p className=' mt-3 fontsize12 font-weight-bold text-center'><img src={warningicon} alt="" /> No Result Found!</p> : sublocation && filtered2.map((cval,index) => {
                    return (
                      <div className={sublocationtoggle===index ? ' locationtoggledesign col-lg-12 border my-2 imgcursor' : 'col-lg-12 border my-2 imgcursor'}  onClick={()=>{togglesublocationview(index)}}>
                        <div className="d-flex">
                          <p className='col-lg-5 mt-2 fontsize12'>{cval.name}</p>
                          {
                            JSON.parse(localStorage?.getItem('sidebar'))==='b' ?    <div className=" text-center mx-2" onClick={() => { updatesublocation(cval.name, cval.sublocationid,cval.latitude,cval.longitude) }}>
                            <span><img src={editicon} alt="" /></span>
                            <p className='fontsize8 navcolor'>Edit</p>
                          </div> : ''
                          }
                       
                          <div className=" text-center mx-2" onClick={() => { mysubnewteamforview(cval.name, cval.sublocationid) }} >
                            <span><img src={viewassign} alt="" /></span>
                            <p className='fontsize8'>View Assignees</p>
                          </div>
                          {
                             <div className=" text-center" onClick={() => { mysubnewteam(cval.name, cval.sublocationid) }}>
                            <img src={adduser} alt="" />
                            <p className='fontsize8'>Assign</p>
                          </div> 
                          }
                          
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="col-lg-4 ">
              {
                assigntoggle &&
                (
                  <>
                    <div className="Noassigned bgwhite row">
                      <div className="col-lg-12 border_bottom">
                        <div className="d-flex px-3 py-3 ">
                          <div className="">
                            <img src={locationicon} alt="" />
                          </div>
                          <div className="div">
                            <p className='pl-3 fontsize16 font-weight-bold navcolor'>{locationname}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
              <div className="d-flex mt-2">
                <input className='form-control ' type="text" placeholder='Search Employee' value={employeesearch} onChange={(e)=>{setemployeesearch(e.target.value)}}/>
                <div className="px-2 searchbg pt-1  pl-2">
                  <img className='' src={searchlogo} alt="" />
                </div>
              </div>
            </div>
                    </div>
                    <div className="row list teamheight">
                      <div className="col-lg-12 pt-2">
                        {
                          assigntogglenew && (
                            <>
                              {
                                unassigned && loading && unassigned?.map((cval) => {
                                  return (

                                    <div className="d-flex border bgwhite mb-2 col-lg-12 ">
                                      <div className=" p-2">
                                        <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : usericon} alt="" />
                                      </div>
                                      <div className="div col-lg-7 ">
                                        <p className='fontsize12 pt-3'>{cval.fname + ' ' + cval.lname}</p>
                                      </div>
                                      {
                                        cval.location_assign_status ?   <div className=" text-center imgcursor ml-1" onClick={() => { removelocation(cval.userid) }}>
                                        <p className='fontsize12 navcolor font-weight-bold pt-3'>Remove</p>
                                      </div> : ''
                                      }
                                    </div>
                                  )
                                })
                              }
                                    {
                                employeesearch.length!==0 && mynewteam.length!==0 && loading ? <p className='textcolor col-lg-12 my-3 font-weight-bold'>Search Result</p> : ''
                              }
                              {
                                filtered3.length===0 && mynewteam.length!==0 ? <p className=' mt-3 fontsize12 font-weight-bold text-center'><img src={warningicon} alt="" /> No Result Found!</p> : ''
                              }
                              {
                                subposition && loading && subposition.map((curr) => {
                                  return <> 
                                      {
                                    employeesearch.length!==0 ? '' : <p className='textcolor col-lg-12 my-3 font-weight-bold'>{curr}</p>
                                } 
                                    {
                                      filtered3.length === 0 && loading ? '' :  filtered3?.map((cval) => {
                                        if (cval.position === curr && mynewteam.length !== 0) {
                                          return <>       <div className="d-flex border bgwhite mb-2 col-lg-12 ">
                                            <div className=" p-2">
                                              <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : usericon} alt="" />
                                            </div>
                                            <div className="div col-lg-7 ">
                                              <p className='fontsize12 pt-3'>{cval.fname + ' ' + cval.lname}</p>
                                            </div>
                                            {
                                              !cval.location_assign_status ?    <div className=" text-center imgcursor ml-3" onClick={() => { assignlocation(cval.userid) }}>
                                              <p className='fontsize12 navcolor font-weight-bold pt-3'>Add</p>
                                            </div> : ''
                                            }
                                          </div>
                                          </>
                                        }

                                      })
                                    }
                                  </>

                                })
                              }
                            </>
                          )
                        }
                        {
                          viewassigntoggle && (
                            <>
                              {
                                employeesearch.length!==0 ? <p className='textcolor col-lg-12 my-3 font-weight-bold'>Search Result</p> : ''
                              }
                              {
                                filtered3.length===0 ? <p className=' mt-3 fontsize12 font-weight-bold text-center'><img src={warningicon} alt="" /> No Result Found!</p> : ''
                              }
                              {
                                subposition && subposition.map((cvalue) => {
                                  return <> 
                                  {
                                    employeesearch.length!==0 ? '' : <p className='textcolor col-lg-12 my-3 font-weight-bold'>{cvalue}</p>
                                } 
                                    {
                               filtered3.length === 0 && loading ? '' :  filtered3.map((cval) => {
                                        if (cval.role === cvalue) {
                                          return <>
                                            <div className="d-flex border bgwhite mb-2 col-lg-12 ">
                                              <div className=" p-2">
                                                <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : usericon} alt="" />
                                              </div>
                                              <div className="div col-lg-7 ">
                                                <p className='fontsize12 pt-3'>{cval.f_name + ' ' + cval.l_name}</p>
                                              </div>

                                            </div>
                                          </>
                                        }

                                      })
                                    }
                                  </>
                                })
                              }
                            </>
                          )
                        }
                      </div>
                    </div>
                  </>
                )
              }

            </div>
          </div>
             )
          }
          {
            addlocation && (
              <>
              <form action="" onSubmit={handlesubmitlocation}>
                <div className="row">
                  <div className="col-lg-7">
                    <p className='navcolor font-weight-bold mt-3'>Add Location</p>
                  </div>
                  <div className="col-lg-7">
                    <input name='location_name' type="text" className='form-control mt-3' placeholder='Location Name' value={  formdata.location_name} onChange={(e)=>{handlechange(e)}} autoComplete='off'/>
                  </div>
                  {/* <div className="col-lg-7">
                    <input name='lat' type="text" className='form-control mt-3' placeholder='Latitude' value={ formdata.lat} readOnly onChange={(e)=>{handlechange(e)}} autoComplete='off'/>
                  </div>
                  <div className="col-lg-7">
                    <input name='long' type="text" className='form-control mt-3' placeholder='Longitude' value={ formdata.long} readOnly onChange={(e)=>{handlechange(e)}} autoComplete='off'/>
                  </div> */}
                  <div className="col-lg-7">
                    <button className='btn btn-primary bgcolor mt-3 float-right'>Save</button>
                  </div>
                </div>
              </form>
              </>
            )
          }
          {
            addsublocation && (
              <>
              <form action="" onSubmit={handlesubmitsublocation}>
                              <div className="row">
                  <div className="col-lg-7">
                    <p className='navcolor font-weight-bold mt-3'>Add Sub-Location</p>
                  </div>
                  <div className="col-lg-7">
                    <input type="text" className='form-control mt-3' value={localname} readOnly placeholder='Location Name'/>
                  </div>
                  <div className="col-lg-7">
                    <input name='sublocation_name' type="text" className='form-control mt-3' placeholder='Sub-Location Name' value={formdata2.sublocation_name} onChange={(e)=>{handlechange2(e)}} autoComplete='off'/>
                  </div>
                  {/* <div className="col-lg-7">
                    <input name='lat' type="text" className='form-control mt-3' placeholder='Latitude' value={formdata2.lat} onChange={(e)=>{handlechange2(e)}} readOnly autoComplete='off'/>
                  </div>
                  <div className="col-lg-7">
                    <input name='long' type="text" className='form-control mt-3' placeholder='Longitude' value={formdata2.long} onChange={(e)=>{handlechange2(e)}} readOnly autoComplete='off'/>
                  </div> */}
                  <div className="col-lg-7">
                    <button className='btn btn-primary bgcolor mt-3 float-right'>Save</button>
                  </div>
                </div>
              </form>
              </>
            )
          }
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Location