import React from 'react'
import Sidebar from '../../components/Sidebar'
import uploadimg from '../../icons/plus (2) 1.png'
import firsticon from '../../icons/first.png'
import secondicon from '../../icons/second.png'
import thirdicon from '../../icons/third.png'
import fourthicon from '../../icons/fourth.png'
import { useState,useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'

const Profile = () => {
  const user =  JSON.parse(localStorage.getItem('user'))
  const token = user.token
 const fname = user.user.f_name
 const lname = user.user.l_name
 const genderdemo = user.user.gender
 const phoneno = user.user.phone_no
 const emailid = user.user.email_id
 const profile = user.user.profile_pic
 const position = user.user.role
  const[f_name,setfname] = useState(fname)
  const[l_name,setlname] = useState(lname)
  const[phone_no,setphoneno] = useState(phoneno)
  const[gender,setgender] = useState(genderdemo)
  const [profile_pic, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(profile);
  
  const profileupdate = async(e)=>{
    e.preventDefault()
    localStorage.removeItem(user)
    user.user.profile_pic = imageUrl
    user.user.f_name = f_name
    user.user.l_name = l_name
    user.user.phone_no = phone_no
    user.user.gender = gender
    localStorage.setItem('user',JSON.stringify(user))
    
    const response = await axios.post('/upload-profile-pic',{f_name,l_name,phone_no,gender,profile_pic},{
      headers:{"Authorization":`Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
    })
     if(response.data.statusCode===200){
      toast.success("Profile Updated Successfully", {
        position: "top-center"
      });
     }
  }
  const changeimage = (e)=>{
    setSelectedImage(e.target.files[0])
  }

  useEffect(() => {
    if (profile_pic) {
      setImageUrl(URL.createObjectURL(profile_pic));
  }
  }, [profile_pic]);

  return (
    <>
    <div className="sidebarflexproperty">
      <div className="div">
        <Sidebar/>
      </div>
      <div className="dashboard">
      <div className="p-3 nav_bar">
            <div className="mx-2 ">
              <p className='font-weight-bold visit_log fontsize16 py-2 d-inline navcolor'>Profile</p>
            </div>
         </div>
        <div className="row">
          <div className="col-lg-10 mt-5 pt-2 ml-4">
            <div className="profileimg ml-3">
              <img className='m-auto profileimg' src={ imageUrl} alt="" />
            </div>
            <div className="addimg">
            <label for="inputTag" className='imgcursor' required> <img src={uploadimg} alt="" /><input name="image" id="inputTag" className='displayhide' type="file" accept="image/png, image/jpg, image/jpeg" onChange={changeimage } /></label>
            </div>
            <form onSubmit={profileupdate}>
            <div className="formpage  pt-4 col-lg-6">
                <div className=" col-lg-12">
                  <div className="d-flex">
                   <input type="text" className='w-100 input_fields' placeholder='First Name'value={f_name} onChange={(e)=>{setfname(e.target.value)}}/>
                   <div className="div mt-2 form_icons_set">
                   <img src={firsticon} alt="" />
                   </div>
                  </div>
                </div>
                <div className=" col-lg-12">
                  <div className="d-flex">
                   <input type="text" className='w-100 input_fields' placeholder='Last Name' value={l_name} onChange={(e)=>{setlname(e.target.value)}}/>
                   <div className="div mt-2 form_icons_set">
                   <img src={firsticon} alt="" />
                   </div>
                  </div>
                </div>
                <div className=" col-lg-12">
                  <div className="d-flex">
                   <input type="text" className='w-100 input_fields' placeholder='' value={position} readOnly/>
                   <div className="div mt-2 form_icons_set">
                   <img src={firsticon} alt="" />
                   </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="d-flex">
                   <input type="text" className='w-100 input_fields' placeholder='nikhil@gmail.com' value={emailid} readOnly/>
                   <div className="div mt-2 form_icons_set">
                   <img src={thirdicon} alt="" />
                   </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="d-flex">
                   <input type="text" className='w-100 input_fields' placeholder='Phone No' value={phone_no} onChange={(e)=>{setphoneno(e.target.value)}} pattern="[789][0-9]{9}"/>
                   <div className="div mt-2 form_icons_set">
                   <img src={secondicon} alt="" />
                   </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="d-flex">
                   <input type="text" className='w-100 input_fields' placeholder='Gender' value={gender==='M' ? 'Male' : 'Female'} onChange={(e)=>{setgender(e.target.value)}} readOnly/>
                   <div className="div mt-2 form_icons_set">
                   <img src={fourthicon} alt="" />
                   </div>
                  </div>
                </div>
                <div className="row  mt-5 pt-2">
                <div className="col-lg-12">
                 <button className='btn btn-primary bgcolor fontsize12 px-5  float-right' >Save</button>
                </div>
                </div>
                </div>
                </form>

           </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  )
}

export default Profile