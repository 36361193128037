import { Map,GoogleApiWrapper,Marker,Polyline,InfoWindow } from "google-maps-react";
import React from "react";
import { useState } from "react";
import seventhicon from '../../icons/seventh.png'
import greenpin from '../../icons/location-pin (4).png'
import redpin from '../../icons/location-pin (3).png'
import DatePicker from 'react-datepicker'
import axios from "axios";
import moment from "moment/moment";

import { useEffect } from "react";

export  function CustomMap({ google}) {
  const user = JSON.parse(localStorage.getItem('user'))
  const [latlong,setlatlong] = useState([])
  const [latlongnew,setlatlongnew] = useState([])
  const [poly,setpath] = useState([])
  const [activeMarker, setActiveMarker] = useState(null);
  const [getdatenew, setdatenew] = useState(new Date())
  const [showInfoWindow,setinfowindow] = useState(false)


  const handleActiveMarker = (marker) => {
    setinfowindow(true)
    setActiveMarker(marker);
 
  };
  // console.log(activeMarker)
  const getgeolocation = async()=>{
    const latlongfind = []
    const path = []
    const markers=[]
    const end = moment(getdatenew).format('yyyy-MM-DD')
    const start = moment(end).subtract(1,"d").format('yyyy-MM-DD')
    const token = user.token
    const userid = user?.user?.id 
    await axios.get(`/get-geo-location?userid=${userid}&start=${start}&end=${end}`,{
     headers: { "Authorization": `Bearer ${token}` }
    }).then((response)=>{
      const result = response.data.geolocation
    //  console.log(result)
     for(let i=0;i<result.length;i++){
      latlongfind.push(JSON.parse(result[i]?.location) )
     }
     setlatlongnew(latlongfind)
      for(let i=0;i<latlongfind.length;i++){
          path.push({lat:latlongfind[i][0],lng:latlongfind[i][1]})
          markers.push({lat:latlongfind[i][0],lng:latlongfind[i][1],timestamp:result[i]?.timestamp})
      }
      setpath(path)
      setlatlong(markers)
    })
   }
   useEffect(()=>{
    getgeolocation()
   },[getdatenew])
  return (
    <>
                  <div className="div">
                            <div className="d-flex col-lg-2">
                            <DatePicker autoComplete='off' className='w-100 input_fields imgcursor' selected={getdatenew} onChange={date => setdatenew(date)} placeholderText='Date of Visit' required />
        <div className="div pt-2 imgcursor map_icons">
           <img src={seventhicon} alt=""/>
           </div>
        </div>
                            </div>
                            <div className="div">
      <Map
       onClick={()=>{setinfowindow(false)}}
          google={google}
          style={{
              width: "100%",
              height: "77%"
          }}
          initialCenter={
            {
              lat: 28.6261908,
              lng: 77.3723982
            }
           
          }
          zoom={12}
      >
        {
          latlong.map((cval,index)=>{
            return <Marker key={index}
             icon={index===latlong?.length-1 ? redpin : greenpin}
             position={
              { lat: cval.lat, lng: cval.lng }
             }
             onClick={()=>{handleActiveMarker(cval)}}
            >
            </Marker>
          })
        }
              {activeMarker && (
                <InfoWindow 
                visible={showInfoWindow}
                
                position={
                  { lat: activeMarker.lat, lng: activeMarker.lng }
                }
                 onCloseClick={() => setActiveMarker(null)}>
                <>
                 {activeMarker.timestamp}
                </>
                </InfoWindow>
              ) }
             <Polyline
              path={poly}
              strokeColor="#00539A"
              strokeOpacity={0.8}
              strokeWeight={5}/>
      </Map>
                            </div>
      </>
  )
};
  export default GoogleApiWrapper({
    apiKey: 'AIzaSyBDgIwV5yVrIXC64VjJD-lt8dGL7ozLBKU'
  })(CustomMap);