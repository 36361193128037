import React, { useState } from 'react'
import title from '../../icons/SALES TRACKY (2).png'
import logo from "../../icons/logo.png"
import loginlogo from "../../icons/Group 1043.png"
import messageicon from "../../icons/Vector (3).png"
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import OTPInput from "otp-input-react";
// import { useNavigate } from 'react-router'
import {useNavigate} from 'react-router-dom'


const Resetpassword = () => {
    const navigate = useNavigate();
    const [otp,setOTP] = useState('')
    const [email_id,setemail] = useState("")
    const otpgenerate = async() =>{
        try {
             const response =  await axios.post('/forgot-password',{email_id})
             if(response.data.statusCode===200){
                toast.success("OTP sent successfully", {
                    position: "top-center"
                  });
             }
        
            } catch (error) {
            toast.error("Invalid Email!", {
                position: "top-center"
              });
        }
    }
    const resetpass = async()=>{
        try {
           const response =  await axios.post('/verify-otp',{email_id,otp})
            if(response.data.statusCode===200){
                 navigate('/setpassword',{
                  state:{
                    email:{email_id},
                    otp:{otp}
                  }
                 })
              }
        } catch (error) {
            toast.error("Invalid OTP!", {
                position: "top-center"
              });
              console.log(error)
        }
    }
  return (
    <>
     <div className="outer_div p-5 ">
   <div className="row inner_div m-3">
    <div className="col-lg-6">
            <div className="d-flex ">
              <div className="m-3">
            <img className='' src={logo} alt="" />
              </div>
              <div className="my-4">
            <img className='' src={title} alt="" />
              </div>
            </div>
            <div className="title m-4 pt-4">
              <h2> Reset Password</h2>
            </div>
             {/* <form  > */}
               <div className="col-lg-12">
                <label htmlFor="" className='greycolor'>Email<span className='redcolor'>*</span> </label> <br />
                <div className="d-flex">
                <input name='email_id' className='input_field form-control' type="email" onChange={(e)=>{setemail(e.target.value)}} value={email_id} required autoComplete='off'/>
                <div className="mt-1 iconshift">
                <span><img  src={messageicon} alt="" /></span> 
                </div>
                </div>
               </div>
               <div className="col-lg-12 mt-2">
                <p className='float-right title font-weight-bold forgot' onClick={otpgenerate}>Generate OTP</p>
               </div>
               <div className="row pt-3 ">
               <label htmlFor="" className='greycolor col-lg-12'>Enter OTP<span className='redcolor'>*</span> </label> <br />
               <OTPInput className='col-lg-12 ' value={otp} onChange={setOTP}  OTPLength={6} otpType="number" disabled={false} required />
                {/* {otp.map((data,index)=>{
                    return (
                        <input key={index} name='otp' className='resetpasswordfields  form-control ml-3' maxLength='1' type='text' value={data} onChange={e =>handlechange(e.target,index)} onFocus={e => e.target.select()}></input>
                    )
                })} */}
               </div>
               {/* <div className="col-lg-12 pt-3">
                <p className='float-right title font-weight-bold forgot' onClick={otpgenerate}>Resend OTP</p>
               </div> */}
               <div className="col-lg-12 mt-4 py-5">
               <button className='btn btn-primary w-100 bgcolor' type='submit' onClick={resetpass} >Continue</button>
               </div>
             {/* </form> */}
            </div>
          <div className="col-lg-6">
              <div className="login-img">
                <img className='w-auto'  src={loginlogo} alt="" />
              </div>
          </div>
          </div>
        </div>
        <ToastContainer/>
    </>
  )
}
export default Resetpassword