import React from 'react'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import Sidebar from '../../components/Sidebar'
import searchlogo from '../../icons/search-interface-symbol 1.png'
import logo3 from '../../icons/plus (5) 1.png'
import viewassign from '../../icons/viewassign.png'
import adduser from '../../icons/adduser.png'
import locationicon from '../../icons/home.png'
import warningicon from '../../icons/caution 1.png'
import info from '../../icons/info 1.png'
import positionicon from '../../icons/position 1.png'
import imageicon from '../../icons/profile.png'
import firsticon from '../../icons/first.png'
import secondicon from '../../icons/second.png'
import thirdicon from '../../icons/third.png'
import locationicon2 from '../../icons/location 1.png'
import { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
// import Geocode from 'react-geocode'
import moment from 'moment'

const Customer = () => {
  const user = JSON.parse(localStorage?.getItem('user'))
  const org_id = user.user.org_id
  const permissions = user?.user?.role_assign
  const permissionsadmin = user?.user?.admin_assign_role
  var foundadmin = false
  for(let i=0;i<permissionsadmin?.length;i++){
    if(permissionsadmin[i].route === '*-customer') {
       foundadmin = true
    }
  }
  for(let i=0;i<permissions?.length;i++){
    if(permissions[i].route === '*-customer'  && permissions[i].permission!==null) {
      var array = Object.values(permissions[i].permission)
    }
    if(permissions[i].route === 'assign-customer' && permissions[i].permission!==null) {
      var array2 = Object.values(permissions[i].permission)
    }
  }
  const found = array?.find(obj => {
    return obj === 'C' ? true : false;
  });
  const found2 = array2?.find(obj => {
    return obj === 'C' ? true : false;
  });
  const customfields = user?.user?.custom_fields
   const values =[]
   for(let i=0;i<customfields.length;i++){
      if(customfields[i].key==='customer_fields'){
          values.push(customfields[i]?.value)
      }
   }

  const [formtoggle, setformtoggle] = useState(false)
  const [followuptoggle, setfollowuptoggle] = useState(false)
  const [customerlist, setcustomerlist] = useState([])
  const [position, setposition] = useState([])
  const [subposition,setpos] = useState([])
  const [location,setlocation] = useState([])
  const [sublocation,setsublocation] = useState([])
  const [sublocationnew,setsublocationnew] = useState([])
  const [customerview,setcustomerview] = useState([])
  const [assigned,setassigned]= useState([])
  const [unassigned,setunassigned]= useState([])
  const [customerinfo,setcustoerinfo]= useState([])
  const [logdetails,setlogdetails]= useState([])
  const [customer_id,setcustomer_id] = useState('')
  const [upper,setupper]= useState()
  const [loading,setloading] = useState(false)
  const [searchterm,setsearchterm] = useState('')
  const [toggle,settoggle]= useState(false)
  const [customerdetails,setcustomerdetails]= useState(false)
  const [visitdetailstoggle,setvisitdetailstoggle]= useState(false)
  const [togglecustomerview,settogglecustomerview]= useState(false)
  const [updatecustomertoggle,setupdatecustomertoggle]= useState(false)
  const [imagetoggle, setimagetoggle] = useState()
  const [fields, setFields] = useState([]);
  // const [getdate, setdate] = useState('')
  const [customer,setcustomer]= useState({
    email_id:'',customer_name:'',location_id:'',sublocation_id:'',address1:'',address2:'',cp_name:'',phone_no:'',latlng:lat? lat+','+lng:''
  })
  const [updatecustomerval,setupdatecustomer]= useState({
    email_id:'',customer_name:'',location_id:'',sublocation_id:'',address1:'',address2:'',cp_name:'',phone_no:'',latlng:''
  })
  const toggleimage = (img) => {
    // settoggle((prev) => {
    //   return prev === visitid ? null : visitid;
    // });
    console.log(img)
    setimagetoggle(img)
  }
  const filtered = !searchterm ?
  customerlist.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }

  }) : customerlist.filter((item) => {
    return item.name.toLowerCase().includes(searchterm.toLowerCase())
  }).sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
  })
  const toggleform = () => {
    setformtoggle(true)
    setfollowuptoggle(false)
    settogglecustomerview(false)
    setcustomerdetails(false)
    setvisitdetailstoggle(false)
    setupdatecustomertoggle(false)
  }
  const handlechange = (e)=>{
    const newdata = { ...customer }
    newdata[e.target.name] = e.target.value;
    setcustomer(newdata)
    console.log(newdata)

  }
  const handlechangeupdate = (e)=>{
    const newdata = { ...updatecustomerval }
    newdata[e.target.name] = e.target.value;
    setupdatecustomer(newdata)
    console.log(newdata)
  }
  const updatecustomerdetails = async(e)=>{
    e.preventDefault()
    const token = user.token
    const id = customer_id
    const {email_id,customer_name,location_id,sublocation_id,address1,address2,cp_name,phone_no,latlng} = updatecustomerval
   const newlat = latlng?.split(' , ')
   var cust_latitude
   var cust_longitude
    if(newlat){
       cust_latitude = newlat[0]
       cust_longitude = newlat[1]
    }
    await axios.post('/update-customer',{id,org_id,email_id,customer_name,location_id,sublocation_id,address1,address2,cp_name,phone_no,cust_latitude,cust_longitude},{
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response)=>{
      if(response?.data?.statusCode===200){
        toast.success("Customer Updated Successfully!", {
          position: "top-center"
        });
      }
    }).catch((error)=>{
      console.log(error)
    })
  }
  const customerdetailstoggle = (name,address,customer_id) =>{
    setformtoggle(false)
    setupper({name,address})
    setcustomer_id(customer_id)
   myteam(customer_id)
   setfollowuptoggle(false)
   settogglecustomerview(false)
   setcustomerdetails(true)
   customerinfofn(customer_id)
   setvisitdetailstoggle(false)
   setupdatecustomertoggle(false)
  }
  const visitdetails = (name,address,customer_id) =>{
    setformtoggle(false)
    setupper({name,address})
    setcustomer_id(customer_id)
   myteam(customer_id)
   setfollowuptoggle(false)
   settogglecustomerview(false)
   setcustomerdetails(false)
   visitdetailsfn(customer_id)
   setvisitdetailstoggle(true)
   setupdatecustomertoggle(false)
  }
  var lat
  var lng
  const currentlocation = ()=>{
    // Geocode.setApiKey('AIzaSyBDgIwV5yVrIXC64VjJD-lt8dGL7ozLBKU')
    // Geocode.setLanguage('en')
    navigator.geolocation.getCurrentPosition((position)=>{
        lat = position.coords.latitude
        lng = position.coords.longitude
      //  Geocode.fromLatLng(lat,lng).then((response)=>{
      //   const address = response.results[0].formatted_address;
      //   console.log(address);
      //  })
      setcustomer({
        email_id:customer.email_id,customer_name:customer.customer_name,location_id:customer.location_id,sublocation_id:customer.sublocation_id,address1:customer.address1,address2:customer.address2,cp_name:customer.cp_name,phone_no:customer.phone_no,latlng:lat ? lat+' , '+lng:''
      })
      setupdatecustomer({
        email_id:updatecustomerval.email_id,customer_name:updatecustomerval.customer_name,location_id:updatecustomerval.location_id,sublocation_id:updatecustomerval.sublocation_id,address1:updatecustomerval.address1,address2:updatecustomerval.address2,cp_name:updatecustomerval.cp_name,phone_no:updatecustomerval.phone_no,latlng:lat ? lat+' , '+lng:''
      })
    }
    )
  }

  const visitdetailsfn = async(customerid) =>{
    const token = user.token
     await axios.get(`/get-visit-all-details?customer_id=${customerid}`,{
      headers: { "Authorization": `Bearer ${token}` }
     }).then((response)=>{
      setlogdetails(response.data.data)
     })
  }
  const viewcustomerassign = async(name,address,customer_id)=>{
    const token = user.token
    setformtoggle(false)
    settogglecustomerview(true)
    setfollowuptoggle(false)
    setcustomerdetails(false)
     setupper({name,address})
     setvisitdetailstoggle(false)
     setupdatecustomertoggle(false)
     const view = []
     const subtitle=[]
      await axios.get(`/get-customer-assigned-team?customer_id=${customer_id}`,{
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response)=>{
        for(let i=0;i<response.data.data.length;i++){
          if(!subtitle.includes(response.data.data[i].user_id.role )){
            subtitle.push(response.data.data[i].user_id.role)
          }
          view.push(response.data.data[i].user_id)
        }
        setcustomerview(view)
        setpos(subtitle)
      })
  }
  const togglefollowup = (name,address,customer_id) => {
    setformtoggle(false)
     setupper({name,address})
     setcustomer_id(customer_id)
    myteam(customer_id)
    setfollowuptoggle(true)
    settogglecustomerview(false)
    setcustomerdetails(false)
    setupdatecustomertoggle(false)
    setvisitdetailstoggle(false)
  }
  const updatecustomer = (name,address,customer_id)=>{
    customerinfofn(customer_id)
    setcustomer_id(customer_id)
    setupper({name,address})
    setformtoggle(false)
    settogglecustomerview(false)
    setfollowuptoggle(false)
    setcustomerdetails(false)
     setvisitdetailstoggle(false)
     setupdatecustomertoggle(true)

  }
  // const handleInputChange = (e, index) => {
  //   const updatedData = [...userData];
  //   updatedData[index].value = e.target.value;
  //   setUserData(updatedData);
  // };
  // const handleChange =(i, event,type) => {
  //   const values = [...fields];
  //   if(type==='checkbox'){
  //     values[i] = event.target.checked;
  //   }
  //   else if(type==='date'){
  //     values[i] = moment(event).format('yyyy-MM-DD');
  //   }
  //   else{
  //     values[i] = event.target.value;
  //   }
    
  //   setFields(values);
  // }
  const myteam = async(customer_id)=>{
    const token = user.token
    const userid = user?.user?.id 
    const subtitle=[]
    const view=[]
    const assigned=[]
    const unassigned=[]
    await axios.get(`/my-teams?user_id=${userid}&customer_id=${customer_id}`,{
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response)=>{
      for(let i=0;i<response.data.users.length;i++){
        view.push(response.data.users[i])
      }
      if(response.data.users.length!==0){
        for(let i=0;i<view.length;i++){
          if(!subtitle.includes(view[i].position ) && view[i].customer_assign_status===false){
            subtitle.push(view[i].position)
          }
         }
        for(let i=0;i<response.data.users.length;i++){
          if(view[i].customer_assign_status===true){
            unassigned.push(view[i])
          }
          else{
            assigned.push(view[i])
          }
         }
        
        
      }
      setpos(subtitle)
      setassigned(assigned)
      setunassigned(unassigned)
      
    }).catch((error)=>{
      console.log(error)
    })
  }
  const customerinfofn = async(customerid) =>{
    const token = user.token
   await axios.get(`/get-customer?customer_id=${customerid}`,{
    headers: { "Authorization": `Bearer ${token}` }
   }).then((response)=>{
    const result = response.data.data
    setcustoerinfo(result)
    setupdatecustomer({
      email_id:result[0]?.email_id,customer_name:result[0]?.name,location_id:result[0]?.locationid,sublocation_id:result[0]?.sublocationid,address1:result[0]?.addr1,address2:result[0]?.addr2,cp_name:result[0]?.cp_name,phone_no:result[0]?.phone_no,latlng: result[0]?.cust_latitude ? result[0]?.cust_latitude+' , '+result[0]?.cust_longitude :''
    })
   })
  }
  const customerassign = async(user_id)=>{
    const token = user.token
    settoggle(true)
   await axios.post('/customer-assign',{user_id,customer_id},{
    headers: { "Authorization": `Bearer ${token}` }
   }).then((response)=>{
    if(response.data.statusCode===200){
         toast.success("Customer Assigned successfully", {
           position: "top-center"
          });
          myteam(customer_id)
        }
   })
  }
  const removelocation =async(user_id)=>{
    const token = user.token
    settoggle(false)
    await axios.post('/customer-assigned-remove',{user_id,customer_id},{
     headers: { "Authorization": `Bearer ${token}` }
    }).then((response)=>{
      if(response.data.statusCode===200){
        toast.success("Customer Removed successfully", {
          position: "top-center"
         });
         myteam(customer_id)
       }
    })
  }
  const createcustomer = async(e)=>{
    e.preventDefault()
    var custom=[]
     for(let i=0;i<values[0].length;i++){
       custom.push({
        'hint_text' : values[0][i]?.hint_text || '',
        'field_name': values[0][i]?.field_name || '',
        'field_type': values[0][i]?.field_type || '',
        'field_value': fields[i] || '',
       })
     }
     const custom_fields = JSON.stringify(custom) 

    const {email_id,customer_name,location_id,sublocation_id,address1,address2,cp_name,phone_no,latlng} = customer
    const token = user.token
    const newlat = latlng?.split(' , ')
    var cust_latitude
    var cust_longitude
     if(newlat){
        cust_latitude = newlat[0]
        cust_longitude = newlat[1]
     }
    await axios.post('/create-customer',{org_id,email_id,customer_name,location_id,sublocation_id,address1,address2,cp_name,phone_no,cust_latitude,cust_longitude},{
      headers: { "Authorization": `Bearer ${token}` }
    }).then((response)=>{
      if(response?.data?.statusCode===200){
        toast.success("Customer Created!", {
          position: "top-center"
        });
        setcustomer({
          email_id:'',customer_name:'',location_id:'',sublocation_id:'',address1:'',address2:'',cp_name:'',phone_no:'',latlng:''
        })
        // setFields([])
      }
    }).catch((error)=>{
      if(error.response.data.statusCode===401){
        toast.error(error.response.data.message, {
          position: "top-center"
        });
      }
    })
  }
  const sublocationlist = async()=>{
    const token = user.token
    const {location_id} = customer
    await axios.get(`/get-sub-locations?location_id=${location_id}`,{
      headers:{"Authorization":`Bearer ${token}`}
    }).then((response)=>{
      setsublocation(response.data.data)
    }).catch((error)=>{
      console.log(error.message)
    })
   
  }
  const sublocationlist2 = async()=>{
    const token = user.token
    const {location_id} = updatecustomerval
    await axios.get(`/get-sub-locations?location_id=${location_id}`,{
      headers:{"Authorization":`Bearer ${token}`}
    }).then((response)=>{
      setsublocationnew(response.data.data)
    }).catch((error)=>{
      console.log(error.message)
    })
    // setupdatecustomer({
    //   email_id:updatecustomerval.email_id,customer_name:updatecustomerval.customer_name,location_id:updatecustomerval.location_id,sublocation_id:'',address1:updatecustomerval.address1,address2:updatecustomerval.address2,cp_name:updatecustomerval.cp_name,phone_no:updatecustomerval.phone_no,latlng:lat ? lat+' , '+lng:''
    // })
  }
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    //  const positions = async ()=>{
    //   await axios.get('/visit-status').then((response)=>{
    //      setposition(response?.data?.data)
    //   }).catch((error)=>{
    //     console.log(error)
    //   })
    //  }   
      const getcustomers = async () => {
        const token = user.token
        const pos=[]
            await axios.get('/get-customer-list', {
        headers: { "Authorization": `Bearer ${token}` }
      }).then((response)=>{
        setcustomerlist(response?.data?.data)
        for(let i=0;i<response?.data?.data.length;i++){
          if(!pos.includes(response?.data?.data[i].type))
          pos.push(response?.data?.data[i].type)
        }
        setposition(pos)
        setloading(true)
      }).catch((error)=>{
        console.log(error)
      })
    }

    const getlocations = async()=>{
      const token = user.token
      const response = await axios.get('/get-locations',{
        headers:{"Authorization":`Bearer ${token}`}
      })
      setlocation(response.data.data)
      setcustomer({
        email_id:customer.email_id,customer_name:customer.customer_name,location_id:customer.location_id,sublocation_id:'',address1:customer.address1,address2:customer.address2,cp_name:customer.cp_name,phone_no:customer.phone_no,latlng:lat ? lat+' , '+lng:''
      })
      if(customer.location_id){
        sublocationlist()
      }
      if(updatecustomerval.location_id){
        sublocationlist2()
      }
    }


    getcustomers()
    getlocations()
  }, [toggle,customer.location_id,updatecustomerval.location_id])
  return (
    <>
      <div className="sidebarflexproperty">
        <div className="div">
          <Sidebar />
        </div>
        <div className="dashboard  col">
          <div className=" p-1 nav_bar d-flex">
            <div className="mx-4">
              <p className='font-weight-bold visit_log fontsize16 py-2 font-weight-bold navcolor'>Customers</p>
            </div>
            <div className="col-lg-9">
              <div className="d-flex">
                <input className='form-control ' type="text" placeholder='Search Clinics' value={searchterm} onChange={(e)=>{setsearchterm(e.target.value)
                }}/>
                <div className="px-2 searchbg pt-1  pl-2">
                  <img className='' src={searchlogo} alt="" />
                </div>
              </div>
            </div>
            {
              found || foundadmin ?        <div className="d-flex  add_followUp imgcursor" onClick={toggleform}>
              <div className="div">
            <img className='p-1' src={logo3} alt="" />
              </div>
              <button  className='  add_follow2 bgwhite fontsize12 pr-1 font-weight-bold imgcursor' >Customers</button>
            </div> 
            :''}
     
          </div>
          <div className="row">
            <div className="col-lg-4 bgwhite border_right scroll customerheight ">
              
              <div className="">
              {
                                searchterm.length!==0 && loading ? <p className='textcolor fontsize10 col-lg-12 my-3 font-weight-bold'>Search Result</p> : ''
                              }
                              {
                                filtered.length===0 ? <p className=' mt-3 fontsize12 font-weight-bold text-center'><img src={warningicon} alt="" /> No Result Found!</p> : ''
                              }
                 {
                   position?.map((cvalue)=>{
                       return <>
                      {
                                    searchterm.length!==0 && loading ? '' : <p className='textcolor fontsize8 col-lg-12 pt-2 font-weight-bold'>{cvalue}</p>
                                } 
                         {
                     filtered.length === 0 && loading ? '' : sublocation && filtered.map((cval,index)=>{
                          if(cval.type === cvalue){
                            return <>
                              <div className="col-lg-12 border my-2 " key={index}>
                                <div className=" border_bottom mx-2">
                                  <div className="d-flex ">
                                    <div className='col-lg-9'>
                                    <div className="div ">
                                    <p className='fontsize8 pt-2 font-weight-bold'>{cval.name}</p>
                                  </div>
                                  <div className="div py-1">
                                    <p className=' fontsize8'>{cval.address}</p>
                                  </div>
                                    </div>
                                    <div className='col-lg-3 float-right pt-2' >
                                  <p className='fontsize14 font-weight-bold navcolor imgcursor' onClick={()=>{updatecustomer(cval.name,cval.address,cval.customer_id,cval.cp_name,cval.phone_no,cval.email_id,)}}>Edit</p>
                                </div>
                                  </div>
                            
                                </div>
                                <div className="d-flex p-1">
                                <div className=" text-center ml-2 imgcursor" onClick={()=>{viewcustomerassign(cval.name,cval.address,cval.customer_id)}}>
                                  <span><img src={viewassign} alt="" /></span>
                                  <p className='fontsize8 navcolor'>View Assignees</p>
                                </div>
                                {
                                  found2 || foundadmin ?  <div className=" text-center ml-3 imgcursor" onClick={()=>{togglefollowup(cval.name,cval.address,cval.customer_id)}}>
                                  <span><img src={adduser} alt="" /></span>
                                  <p className='fontsize8 navcolor'>Assign</p>
                                </div> 
                                :''
                                }
                                <div className=" text-center ml-3 imgcursor" onClick={()=>{customerdetailstoggle(cval.name,cval.address,cval.customer_id)}}>
                                  <span><img src={info} alt="" /></span>
                                  <p className='fontsize8 navcolor'>Customer Details</p>
                                </div>
                                <div className=" text-center ml-3 imgcursor" onClick={()=>{visitdetails(cval.name,cval.address,cval.customer_id)}}>
                                  <span><img src={positionicon} alt="" /></span>
                                  <p className='fontsize8 navcolor'>All Visits</p>
                                </div>
                              </div>
                            </div>
                            </>
                          }
                        })
                      }
                      </>
                      
                  })
                }
              </div>
            </div>
            <div className="col-lg-8">
              {
                followuptoggle && (
                  <>

                    <div className="Noassigned bgwhite row ">
                      <div className="col-lg-12">
                        <div className="border_bottom d-flex px-2 py-3 ">
                          <div className="">
                            <img src={locationicon} alt="" />
                          </div>
                          <div className="col-lg-7">
                            <div className="div">
                              <h6 className='fontsize8 navcolor font-weight-bold'>{upper.name}</h6>
                            </div>
                            <div className="div">
                              <p className='fontsize8 navcolor font-weight-bold'>{upper.address}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mt-3 log_details2">
                    {
                               unassigned?.map((cval)=>{
                                    return (
                                      <div className="d-flex border bgwhite mb-2 col-lg-12 ">
                                      <div className=" p-2">
                                        <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : imageicon} alt="" />
                                  </div>
                                      <div className="div col-lg-7 ">
                                        <p className='fontsize12 pt-3'>{cval.fname+' '+cval.lname}</p>
                                      </div>
                                              <div className=" text-center imgcursor " onClick={()=>{removelocation(cval.userid)}}>
                                                <p className='fontsize12 navcolor font-weight-bold pt-3'>remove</p>
                                              </div>
                                     </div>
                                    )
                                  
                                })
                              }
                    {
                              subposition?.map((cvalue)=>{
                                return <> 
                                <p className='textcolor col-lg-12 my-3 font-weight-bold'>{cvalue}</p>
                                 {
                                  assigned && assigned?.map((cval)=>{
                                    if(cval.position===cvalue){
                                    return (
                                      <div className="d-flex border bgwhite mb-2 col-lg-12">
                                      <div className=" p-2">
                                        <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : imageicon} alt="" />
                                  </div>
                                      <div className="div col-lg-8 ">
                                        <p className='fontsize12 pt-3'>{cval.fname+' '+cval.lname}</p>
                                      </div>
                                              <div className=" text-center imgcursor" onClick={()=>{customerassign(cval.userid)}}>
                                                <p className='fontsize12 navcolor font-weight-bold pt-3'>Add</p>
                                              </div>
                                     </div>
                                    )
                                    }
                                  })
                                 }
                                </>
                              })
                             }   
                    </div>
                  </>
                )
              }
            {
              togglecustomerview && (
                <>
                       <div className="Noassigned bgwhite row">
                      <div className="col-lg-12">
                        <div className="border_bottom d-flex px-2 py-3">
                          <div className="">
                            <img src={locationicon} alt="" />
                          </div>
                          <div className="col-lg-7">
                            <div className="div">
                              <h6 className='fontsize8 navcolor font-weight-bold'>{upper.name}</h6>
                            </div>
                            <div className="div">
                              <p className='fontsize8 navcolor font-weight-bold'>{upper.address}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
              {
                subposition.map((cvalue)=>{
                  return <>
                       <p className='textcolor col-lg-12 my-3 font-weight-bold'>{cvalue}</p>
                       {
                                  customerview && customerview.map((cval)=>{
                                    if(cval.role===cvalue){
                                    return <>
                                         <div className="d-flex border bgwhite mb-2 col-lg-6">
                                                        <div className=" p-2">
                                                          <img className='m-auto imgview' src={cval.profile_pic ? cval.profile_pic : imageicon} alt="" />
                                                    </div>
                                                        <div className="div col-lg-8 ">
                                                          <p className='fontsize12 pt-3'>{cval.f_name+' '+cval.l_name}</p>
                                                        </div>
                                                       </div>
                                    </>
                                  }
                                  })
                       }
                  </>
                })
       
              }
                  </>
              )
            }
            {
              customerdetails && (
                <>
                <div className="Noassigned bgwhite row">
                      <div className="col-lg-12">
                        <div className="border_bottom d-flex px-2 py-3">
                          <div className="">
                            <img src={locationicon} alt="" />
                          </div>
                          <div className="col-lg-7">
                            <div className="div">
                              <h6 className='fontsize8 navcolor font-weight-bold'>{upper.name}</h6>
                            </div>
                            <div className="div">
                              <p className='fontsize8 navcolor font-weight-bold'>{upper.address}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                <div className="customer_details">
                  {
                    customerinfo?.map((cval)=>{
                      return <>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Contact Person</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.cp_name}</p>
                  </div>
                  </div>
                            <div className="row  p-3">
                  <div className="col-lg-4">
                    <p>Contact</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.phone_no}</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Email id</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.email_id}</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Created By</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.created_by}</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Created At</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{moment(cval.created_at).format('yyyy-MM-DD / HH:mm') }</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Status</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.type}</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Address</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.addr1} {cval.addr2}</p>
                  </div>
                  </div>
                            <div className="row p-3">
                  <div className="col-lg-4">
                    <p>Latitude Longitude</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.cust_latitude ? cval.cust_latitude +' ' +cval.cust_longitude : 'NA'} </p>
                  </div>
                  </div>
                      </>
                    })
                  }
             
                </div>
                </>
              )
            }
            {
              visitdetailstoggle && (
                <>
                <div className="Noassigned bgwhite row">
                      <div className="col-lg-12">
                        <div className="border_bottom d-flex px-2 py-3">
                          <div className="">
                            <img src={locationicon} alt="" />
                          </div>
                          <div className="col-lg-7">
                            <div className="div">
                              <h6 className='fontsize8 navcolor font-weight-bold'>{upper.name}</h6>
                            </div>
                            <div className="div">
                              <p className='fontsize8 navcolor font-weight-bold'>{upper.address}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="log_details ">
                    {
                       logdetails.length!==0 ? logdetails?.slice(0)?.reverse()?.map((cval)=>{
                        return <>
                        <div className="div border_bottom pb-3 pt-3">
                            <p className='navcolor fontsize14 font-weight-bold p-2'>{cval.visitor}</p>
                              <div className="row p-2">
                  <div className="col-lg-4">
                    <p>Contact Person</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.contact_person}</p>
                  </div>
                  </div>
                            <div className="row  p-2">
                  <div className="col-lg-4">
                    <p>Contact</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.phone_no}</p>
                  </div>
                  </div>
                            <div className="row p-2">
                  <div className="col-lg-4">
                    <p>Email id</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.email_id}</p>
                  </div>
                  </div>
                            <div className="row p-2">
                  <div className="col-lg-4">
                    <p>Subscription Model</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.subs_model_name}</p>
                  </div>
                  </div>
                            <div className="row p-2">
                  <div className="col-lg-4">
                    <p>Price</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.price}</p>
                  </div>
                  </div>
                            <div className="row p-2">
                  <div className="col-lg-4">
                    <p>No. of Visits</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.no_of_visits}</p>
                  </div>
                  </div>
                            <div className="row p-2">
                  <div className="col-lg-4">
                    <p>Date of Visit</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{moment(cval.visited_on).format('yyyy-MM-DD')}</p>
                  </div>
                  </div>
                            <div className="row p-2">
                  <div className="col-lg-4">
                    <p>Time of Visit</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{moment(cval.visited_on).format('HH:mm')} </p>
                  </div>
                  </div>
                            <div className="row p-2">
                  <div className="col-lg-4">
                    <p>Duration</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.duration} </p>
                  </div>
                  </div>
                            <div className="row p-2">
                  <div className="col-lg-4">
                    <p>Status</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.type} </p>
                  </div>
                  </div>
                            <div className="row p-2">
                  <div className="col-lg-4">
                    <p>Comments</p>
                  </div>
                  <div className="col-lg-4">
                     <p className='navcolor'>{cval.visitor_comments} </p>
                  </div>
                  </div>
                            <div className="row p-2">
                  <div className="col-lg-4">
                    <p>Image</p>
                  </div>
                  <div className="col-lg-4">
                  {
                                  cval.image ? <>
                                  <img className='imageresize imgcursor ' src={cval.image} alt=""  data-toggle="modal" data-target="#exampleModalCenter" onClick={() => { toggleimage(cval.image) }}/>
                                  {/* {
                                    toggle === cval.visit_id && toggle ? <span className={toggle === cval.visit_id && toggleimage ? 'closebtn imgcursor' : ''} onClick={() => { toggleimage(cval.visit_id) }}>X</span> :''
                                  } */}
                                  </>
                                  : <><p className='navcolor my-2 '>NA</p></>
                                }
                  
                  </div>
                  </div>
                  </div>
                        </>
                       })
                       :
                        <>
                        <p className='fontsize16 font-weight-bold errormessage m-5 greycolor '>No Visit Found</p>
                        </>
                    }
                    </div>
                </>
              )
            }
              {
                formtoggle && (
                  <>
                    <div className="Noassigned bgwhite row border_bottom navcolor font-weight-bold">
                      <div className="col-lg-12 p-4">
                        <p>New Customer</p>
                      </div>
                    </div>
                     <form onSubmit={createcustomer}>
                    <div className="row bgwhite">
                      <div className="formpagecomp col-lg-7">
                        <div className=" col-lg-12">
                          <div className="d-flex">
                            <input name='customer_name' type="text" className='w-100 input_fields' placeholder='Business Name' value={customer.customer_name} onChange={(e)=>{handlechange(e)}}  required autoComplete='off' />
                          </div>
                        </div>
                        <div className=" col-lg-12">
                          <div className="d-flex">
                            <input name='cp_name' type="text" className='w-100 input_fields' placeholder='Contact Person' value={customer.cp_name} onChange={(e)=>{handlechange(e)}} required autoComplete='off' />
                            <div className="div mt-2 form_icons_set">
                              <img src={firsticon} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex">
                            <input name='phone_no' type="text" className='w-100 input_fields' placeholder='Contact' value={customer.phone_no} onChange={(e)=>{handlechange(e)}} pattern='[789][0-9]{9}' required autoComplete='off'/>
                            <div className="div mt-2 form_icons_set">
                              <img src={secondicon} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex">
                            <input name='email_id' type="email" className='w-100 input_fields' placeholder='Email' value={customer.email_id} onChange={(e)=>{handlechange(e)}} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"  required autoComplete='off'/>
                            <div className="div mt-2 form_icons_set">
                              <img src={thirdicon} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                   <div className=" dropplaceholder">
                   <select name='location_id' value={customer.location_id} onChange={(e)=>{handlechange(e)}} className='input_fields w-100' required >
                <option className='fontsize8' value="" disabled selected hidden>Location</option>
                {
                  location.map((curele) => {
                    return <><option  value={curele.locationid}>{curele.name}</option></>
                  })
                }
              </select>
                </div>
                </div>
                <div className="col-lg-12">
                   <div className="d-flex dropplaceholder">
                   <select name='sublocation_id' value={customer.sublocation_id} onChange={(e)=>{handlechange(e)}} className='input_fields w-100' required >
                <option className='fontsize8' value="" disabled selected hidden>Sub Location</option>
                {
                  sublocation.sort((a,b)=>{
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                      return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                      return 1;
                    }
                  }).map((curele) => {
                    return <><option  value={curele.sublocationid}>{curele.name}</option></>
                  })
                }
              </select>
                </div>
                </div>
                        <div className="col-lg-12">
                          <div className="d-flex">
                            <input name='address1' type="text" className='w-100 input_fields' placeholder='Address Line 1' value={customer.address1} onChange={(e)=>{handlechange(e)}}  required autoComplete='off'/>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex">
                            <input name='address2' type="text" className='w-100 input_fields' placeholder='Address Line 2'  value={customer.address2} onChange={(e)=>{handlechange(e)}} required autoComplete='off'/>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex">
                            <input name='latlng' type="text" className='w-100 input_fields' placeholder='latitude longitude'  value={customer.latlng} onChange={(e)=>{handlechange(e)}}  autoComplete='off' readOnly/>
                          </div>
                        </div>
                        {/* {
                          values[0]?.map((cval,idx)=>{
                            return <>
                            <div className="col-lg-12">
                              {
                                cval.field_type==='date' ? <>
                                <span className=''>
                                <DatePicker autoComplete='off' className=' input_fields w-100' value={fields[idx]} onChange={(e) => handleChange(idx, e,cval.field_type)} placeholderText={cval.field_name}  required={cval.required_type}/>
                                </span>
                                </> 
                                : cval.field_type==='checkbox' ? <>
                                <div className="d-flex mt-2 checkboxcss">
                                      <div className=" ">
                                      <input  type={cval.field_type}   className='w-100 input_fields' placeholder={cval.field_name} required={cval.required_type} value={fields[idx]}  onChange={(e) => handleChange(idx, e,cval.field_type)}/>
                                      </div>
                                      <p className='fontsize12 greycolor pt-2 pl-3'>{cval.field_name} </p>
                                </div>
                                </> :  <input  type={cval.field_type}   className='w-100 input_fields' placeholder={cval.field_name} required={cval.required_type} value={fields[idx]} onChange={(e) => handleChange(idx, e,cval.field_type)}/>
                              }
                            </div>
                            </>
                          })
                        } */}
                        <div className="col-lg-12 float-right">
                          <div className="d-flex pt-3 float-right" onClick={()=>{currentlocation()}}>
                            <p className='fontsize10 pt-1 pr-2'>Current Location</p>
                            <span><img src={locationicon2} alt="" /></span>
                          </div>
                        </div>
                  
                        <div className="row px-3 py-2">
                          <div className="col-lg-12 d-block mt-3">
                            <button className='btn btn-primary bgcolor fontsize12 px-5 py-2 float-right'>Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                     </form>
                  </>
                )
              }
              {
                  updatecustomertoggle && (
                    <>
                         <div className="Noassigned bgwhite row">
                      <div className="col-lg-12">
                        <div className="border_bottom d-flex px-2 py-3">
                          <div className="">
                            <img src={locationicon} alt="" />
                          </div>
                          <div className="col-lg-7">
                            <div className="div">
                              <h6 className='fontsize8 navcolor font-weight-bold'>{upper.name}</h6>
                            </div>
                            <div className="div">
                              <p className='fontsize8 navcolor font-weight-bold'>{upper.address}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="update_customer">
                    <form onSubmit={updatecustomerdetails}>
                    <div className="row bgwhite">
                      <div className="formpagecomp col-lg-7">
                        <div className=" col-lg-12">
                          <div className="d-flex">
                            <input name='customer_name' type="text" className='w-100 input_fields' placeholder='Business Name' value={updatecustomerval.customer_name} onChange={(e)=>{handlechangeupdate(e)}}  required autoComplete='off' />
                          </div>
                        </div>
                        <div className=" col-lg-12">
                          <div className="d-flex">
                            <input name='cp_name' type="text" className='w-100 input_fields' placeholder='Contact Person' value={updatecustomerval.cp_name} onChange={(e)=>{handlechangeupdate(e)}} required autoComplete='off' />
                            <div className="div mt-2 form_icons_set">
                              <img src={firsticon} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex">
                            <input name='phone_no' type="text" className='w-100 input_fields' placeholder='Contact' value={updatecustomerval.phone_no} onChange={(e)=>{handlechangeupdate(e)}} pattern='[789][0-9]{9}' required autoComplete='off'/>
                            <div className="div mt-2 form_icons_set">
                              <img src={secondicon} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex">
                            <input name='email_id' type="email" className='w-100 input_fields' placeholder='Email' value={updatecustomerval.email_id} onChange={(e)=>{handlechangeupdate(e)}} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"  required autoComplete='off'/>
                            <div className="div mt-2 form_icons_set">
                              <img src={thirdicon} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                   <div className="d-flex">
                   <select name='location_id' value={updatecustomerval.location_id} onChange={(e)=>{handlechangeupdate(e)}} className='input_fields w-100' required >
                <option className='fontsize8' value='' disabled selected hidden>{[customerinfo[0]?.location_name]}</option>
                {
                  location.map((curele) => {
                    return <><option  value={curele.locationid}>{curele.name}</option></>
                  })
                }
              </select>
                </div>
                </div>
                <div className="col-lg-12">
                   <div className="d-flex dropplaceholder">
                   <select name='sublocation_id' value={updatecustomerval.sublocation_id} onChange={(e)=>{handlechangeupdate(e)}} className='input_fields w-100' required >
                <option className='fontsize8' value='' disabled hidden selected >Sub Locations</option>
                {
                  sublocationnew.sort((a,b)=>{
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                      return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                      return 1;
                    }
                  }).map((curele) => {
                    return <><option  value={curele.sublocationid}>{curele.name}</option></>
                  })
                }
              </select>
                </div>
                </div>
                        <div className="col-lg-12">
                          <div className="d-flex">
                            <input name='address1' type="text" className='w-100 input_fields' placeholder='Address Line 1' value={updatecustomerval.address1} onChange={(e)=>{handlechangeupdate(e)}}  required autoComplete='off'/>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex">
                            <input name='address2' type="text" className='w-100 input_fields' placeholder='Address Line 2'  value={updatecustomerval.address2} onChange={(e)=>{handlechangeupdate(e)}} required autoComplete='off'/>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex">
                            <input name='latlng' type="text" className='w-100 input_fields' placeholder='latitude longitude'  value={updatecustomerval.latlng} onChange={(e)=>{handlechangeupdate(e)}}  autoComplete='off'/>
                          </div>
                        </div>
                        <div className="col-lg-12 float-right">
                          <div className="d-flex pt-3 float-right" onClick={()=>{currentlocation()}}>
                            <p className='fontsize10 pt-1 pr-2'>Current Location</p>
                            <span><img src={locationicon2} alt="" /></span>
                          </div>
                        </div>
                        <div className="row px-3 py-2">
                          <div className="col-lg-12">
                            <button className='btn btn-primary bgcolor fontsize12 px-5 py-2 float-right'>Save</button>
                          </div>
                        </div>
              
                      </div>
                    </div>
                     </form>
                    </div>
                    </>
                  )
              }
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> */}
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <img src={imagetoggle} alt="" className='img-fluid' />
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  )
}

export default Customer