import React, { useEffect } from 'react'
import { useState } from 'react'
import title from '../../icons/SALES TRACKY (2).png'
import logo from "../../icons/logo.png"
import loginlogo from "../../icons/Group 1043.png"
import messageicon from "../../icons/Vector (3).png"
import eyeicon from "../../icons/Vector (4).png"
import eyeicon2 from "../../icons/eyeicon.png"
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router'

const Login = () => {
  const navigate = useNavigate();
  const side = JSON.parse(localStorage.getItem('sidebar'))
  const [passwordType, setPasswordType] = useState("password");
  const [user,setUser] = useState(false)
  const [logindata, setlogindata] = useState({
    email_id: "",
    pass: ""
  })
  const togglePassword =()=>{
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  const handleforgot =()=>{
   navigate('/resetpassword')
  }
  const handleinput = (e) => {
    const newdata = { ...logindata }
    newdata[e.target.name] = e.target.value;
    setlogindata(newdata)
  }
  const senddata = async(e)=>{
    try {
      e.preventDefault();
      const {email_id,pass} = logindata;
      const password = btoa(pass)
      const response =  await axios.post('/login',{email_id,password})
      if(response.data.statusCode===200){
        localStorage.setItem("auth", true);
        localStorage.setItem("user", JSON.stringify(response?.data?.data));
         navigate('/dashboard')
      }
    } catch (error) {
      toast.error("Invalid Credentials!", {
        position: "top-center"
      });
      console.log(error)
    }
  }
  if(user){
    if(side === 'a'){
      navigate('/dashboard')
    }
    if(side === 'b'){
      navigate('/Admindashboard')
    }
  }
  useEffect(()=>{
    const userloggedin = localStorage.getItem('user')
    if(userloggedin){
      setUser(true)
    }
  },[])
  return (
    <>
      <div className="outer_div p-5">
   <div className=" inner_div">
    <div className="row">
    <div className="col-lg-6">
            <div className="d-flex ">
              <div className="m-3">
            <img className='' src={logo} alt="" />
              </div>
              <div className="my-4">
            <img className='' src={title} alt="" />
              </div>
            </div>
            <div className="title m-4 pt-4">
              <h2> Log - In</h2>
            </div>
             <form onSubmit={senddata} >
               <div className="col-lg-12">
                <label htmlFor="" className='greycolor'>Email<span className='redcolor'>*</span> </label> <br />
                <div className="d-flex">
                <input name='email_id' className='input_field form-control' type="email" onChange={handleinput} value={logindata.email_id} required autoComplete='off'/>
                <div className="mt-1 iconshift">
                <img  src={messageicon} alt="" />
                </div>
                </div>
               </div>
               <div className="col-lg-12 pt-3">
                <label htmlFor="" className='greycolor'>Password<span className='redcolor'>*</span> </label> <br />
                <div className="d-flex">
                  <input name='pass' className='input_field form-control ' type={passwordType} onChange={(e) => { handleinput(e)}} value={logindata.password} required/>
                  <div className="mt-1 iconshift">
                    {
                      passwordType === 'password' ? <span><img  src={eyeicon} alt="" onClick={togglePassword}/></span> : <span><img  src={eyeicon2} alt="" onClick={togglePassword}/></span> 
                    }
                  
                  </div>
                </div>
               </div>
               <div className="col-lg-12 pt-3">
                <p className='float-right title font-weight-bold forgot' onClick={handleforgot}>Forgot Password?</p>
               </div>
               <div className="col-lg-12 mt-4 py-5">
               <button className='btn btn-primary w-100 bgcolor' >Log In</button>
               </div>
             </form>
            </div>
          <div className="col-lg-6">
            <div className="login-img">
             <img className='img-fluid  loginimgcss'  src={loginlogo} alt="" />
            </div>
          </div>
          </div>
          </div>        
          </div>
        <ToastContainer limit={1}/>
        </>
  )
}

export default Login