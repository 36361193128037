import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import title from '../icons/SALES TRACKY (2).png'
import logo from "../icons/logo.png"
import logo2 from "../icons/Vector (43).png"
import logo22 from "../icons/dashboard (2) 1.png"
import logo3 from "../icons/files (1) 1.png"
import logo4 from "../icons/maps-and-flags (1) 1.png"
import logo5 from "../icons/group (2) 1.png"
import logo6 from "../icons/customers (1) 1.png"
import logo7 from "../icons/inspection (1) 1.png"
import logo8 from "../icons/bell (1) 1.png"
import logo9 from "../icons/user (1) 1.png"
import logo10 from "../icons/logout (1) 1.png"
import requestlogo from "../icons/request (1) 42.png"
import switchicon from "../icons/Vector (27).png"
import icon1 from "../icons/Vector (28).png"
import icon2 from "../icons/Vector (29).png"
import icon3 from "../icons/Vector (30).png"
import icon4 from "../icons/Vector (31).png"
import icon5 from "../icons/Vector (32).png"
import eyeicon from "../icons/Vector (36).png"
import claimicon from "../icons/Vector (41).png"
import menu from "../icons/menu.png"
import close from "../icons/close.png"
import { useState } from 'react'
const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [toggle,settoggle] = useState('')
    const { pathname } = location;

   const user = JSON.parse(localStorage?.getItem('user'))
   const isadmin = user?.user?.is_admin
   const admin_roles = user?.user?.admin_assign_role
const splitLocation = pathname.split("/");
  const handlelogout = ()=>{
        localStorage.clear();
        navigate('/login')
  }
  const formroute = admin_roles?.find(obj=>obj.route==='*-forms')
  const locationroute = admin_roles?.find(obj=>obj.route==='*-locations')
  const adminswitch = ()=>{
    localStorage.removeItem('sidebar')
    localStorage.setItem('sidebar',JSON.stringify('b'))
    settoggle(JSON.parse(localStorage?.getItem('sidebar')) )
    navigate('/Admindashboard')
  }
  const userswitch = ()=>{
      localStorage.removeItem('sidebar')
      localStorage.setItem('sidebar',JSON.stringify('a'))
      settoggle(JSON.parse(localStorage?.getItem('sidebar')) )
    navigate('/dashboard')
  }
  useEffect(()=>{
    if(!localStorage?.getItem('sidebar')){
        localStorage.setItem('sidebar',JSON.stringify('a'))
        settoggle(JSON.parse(localStorage?.getItem('sidebar')) )
    }
    else{
        settoggle(JSON.parse(localStorage?.getItem('sidebar')) )
    }
  },[])
  return (
     <>
     <div className="">
                {
               toggle==='a' ? <div className="sidebar">
                     <nav>
                    <ul className='links'>

                    <li>
                        
                            <div className="sidebartagicon">
                                <Link to='#' className=''> <img className=' m-2 greycolor' src={logo} alt="" /><img className='' src={title} alt="" /></Link>
                            </div>
                        </li>
                        <li>
                            <div className="mt-1">
                                <Link to='/dashboard' className={splitLocation[1] === "dashboard" ? "activenav nav-link " : 'nav-link'}>
                                     <div className="row">
                                    <div className="col-lg-1 col-1">
                                    <img className='' width={15}  src={logo22} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                    <p className='m-0 fontsize12'>Dashboard</p>
                                    </div>
                                    </div>
                                    </Link>
                            </div>
                        </li>
                        <li>
                            <div className="mt-1">
                                <Link to='/visitlog' className={splitLocation[1] === "visitlog" ? "activenav nav-link" : 'nav-link'}>
                                    <div className="row">
                                        <div className="col-lg-1 col-1">
                                        <img className=' ' width={15} src={logo3} alt="" />
                                        </div>
                                        <div className="col-lg-10 col-10">
                                           <p className='m-0 fontsize12'>Follow-Up Log</p>
                                        </div>
                                    </div>
                                     
                                     </Link>
                            </div>
                        </li>
                        <li>
                            <div className="mt-1">
                                <Link to='/location' className={splitLocation[1] === "location" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                    <img className='' width={15} src={logo4} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                    <p className='m-0 fontsize12'>Locations</p>
                                    </div>
                                    </div>
                                     </Link>
                            </div>
                        </li>
                        <li>
                            <div className="mt-1">
                                <Link to='/team' className={splitLocation[1] === "team" ? "activenav nav-link" : 'nav-link'}> 
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                    <img className='' width={15} src={logo7} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                    <p className='m-0 fontsize12'>Team</p>
                                    </div>
                                    </div>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="mt-1">
                                <Link to='/customer' className={splitLocation[1] === "customer" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                    <img className='' width={15} src={logo6} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                    <p className='m-0 fontsize12'>Customers</p>
                                    </div>
                                    </div>
                                     </Link>
                            </div>
                        </li>
                        <li>
                            <div className="mt-1">
                                <Link to='/viewreport' className={splitLocation[1] === "viewreport" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                    <img className='' width={15} src={logo5} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                    <p className='m-0 fontsize12'>View Reports</p>
                                    </div>
                                    </div>
                                     
                                     </Link>
                            </div>
                        </li>
                        <li>
                            <div className="mt-1">
                                <Link to='/notifications' className={splitLocation[1] === "notifications" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                    <img className='' width={15} src={logo8} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                    <p className='m-0 fontsize12'>Notification</p>
                                    </div>
                                    </div>
                                    
                                     </Link>
                            </div>
                        </li>
                        <li>
                            <div className="mt-1">
                                <Link to='/managerequests' className={splitLocation[1] === "managerequests" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                    <img className='' width={15} src={requestlogo} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                    <p className='m-0 fontsize12'>Manage Requests</p>
                                    </div>
                                    </div>
                                     </Link>
                            </div>
                        </li>
                        <li>
                            <div className="mt-1">
                                <Link to='/profile' className={splitLocation[1] === "profile" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                    <img className='' width={15} src={logo9} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                    <p className='m-0 fontsize12'>Profile</p>
                                    </div>
                                    </div>
                                     
                                     </Link>
                            </div>
                        </li>
           
                        <li>
                        <div className="mt-1">
                            <Link to='/claims' className={splitLocation[1] === "claims" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                      <img src={claimicon} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                      <p className='m-0 fontsize12'>Claims</p>
                                    </div>
                                </div>
                                 </Link>
                        </div>
                    </li>
                        {
                            isadmin ?    <li>
                            <div className="mt-1" onClick={()=>{adminswitch()}}>
                                <Link  className={splitLocation[1] === "Admindashboard" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                    <img className='' width={15} src={switchicon} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                    <p className='m-0 fontsize12'>Admin Controls</p>
                                    </div>
                                    </div>
                                     </Link>
                            </div>
                        </li> : ''
                         }
                                     <li>
                            <div className="mt-1">
                                <Link to='/setpassword' className={splitLocation[1] === "setpassword" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                    <img className='' width={15} src={eyeicon} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                    <p className='m-0 fontsize12'>Change Password</p>
                                    </div>
                                    </div>
                                     </Link>
                            </div>
                        </li>
                        <li  data-toggle="modal" data-target="#exampleModal">
                            <div className="mt-2">
                                <Link className='nav-link'>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                    <img className='' width={15} src={logo10} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                    <p className='m-0 fontsize12'>Log Out</p>
                                    </div>
                                    </div>
                                    
                                     </Link>
                            </div>
                        </li>
                    </ul>
                </nav>
                    </div> : ''
                }
                {
                    toggle==='b' ? <div className="sidebar ">
                        <nav>
                            <ul className='links'>
                            <li >
                        <div className="sidebartagicon">
                            <Link to='#' className=''> <img className=' m-2 greycolor' src={logo} alt="" /><img className='' src={title} alt="" /></Link>
                        </div>
                    </li>
                    <li>
                        <div className="mt-1">
                            <Link to='/Admindashboard' className={splitLocation[1] === "Admindashboard" ? "activenav nav-link " : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                      <img src={logo2} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                      <p className='m-0 fontsize12'>Dashboard</p>
                                    </div>
                                </div>
                                
                                 </Link>
                        </div>
                    </li>
                    <li>
                        <div className="mt-1">
                            <Link to='/manageemployees' className={splitLocation[1] === "manageemployees" ? "activenav nav-link " : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                      <img src={icon4} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                      <p className='m-0 fontsize12'>Manage Employees</p>
                                    </div>
                                </div>
                                  </Link>
                        </div>
                    </li>
                    {
                        locationroute ?     <li>
                        <div className="mt-1">
                            <Link to='/location' className={splitLocation[1] === "location" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                      <img src={icon5} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                      <p className='m-0 fontsize12'>Manage Locations</p>
                                    </div>
                                </div>
                                 </Link>
                        </div>
                    </li> : ''
                    }
                
                    <li>
                        <div className="mt-1">
                            <Link to='/team' className={splitLocation[1] === "team" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                      <img src={icon3} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                      <p className='m-0 fontsize12'>Manage Teams</p>
                                    </div>
                                </div>
                                 </Link>
                        </div>
                    </li>
                    {
                        formroute ?    <li>
                        <div className="mt-1">
                            <Link to='/manageforms' className={splitLocation[1] === "manageforms" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                      <img src={icon2} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                      <p className='m-0 fontsize12'>Manage Forms</p>
                                    </div>
                                </div>
                                </Link>
                        </div>
                    </li> : ''
                    } 
                    <li>
                        <div className="mt-1">
                            <Link to='/setting' className={splitLocation[1] === "setting" ? "activenav nav-link" : 'nav-link'}>
                                <div className="row">
                                    <div className="col-lg-1 col-1">
                                      <img src={icon1} alt="" />
                                    </div>
                                    <div className="col-lg-10 col-10">
                                      <p className='m-0 fontsize12'>Settings</p>
                                    </div>
                                </div>
                                 </Link>
                        </div>
                    </li>
                    
                        <div className="mt-1" onClick={()=>{userswitch()}}>
                            <Link to='' className={splitLocation[1] === "" ? "activenav nav-link" : 'nav-link'}> 
                            <div className="row">
                                <div className="col-lg-1 col-1">
                                      <img src={switchicon} alt="" />
                                </div>
                                <div className="col-lg-10 col-10">
                                      <p className='m-0 fontsize12'>User Account</p>
                                </div>
                            </div>
                           </Link>
                        </div>
                            </ul>
                        </nav>
                    </div> : ''
                }

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Do You Want To Log-out?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {/* <div class="modal-body">
        ...
      </div> */}
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={()=>{handlelogout()}}>Yes</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
                          {/* <div className={logouttoggle ? 'open-popup2 bgwhite popup2' : 'popup2 bgwhite'}>
                <div className="">
                  <p className='col-lg-12 text-center navcolor font-weight-bold my-4'>Do You want to Log-out.</p>
                  <div className="row">
                  <p className='col-lg-7 pl-5 navcolor font-weight-bold imgcursor' onClick={()=>{handlelogout()}}>Yes</p>
                  <p className='col-lg-5 pl-5 navcolor font-weight-bold imgcursor float-right ' onClick={()=>{logouthandler()}}>No</p>
                  </div>
                </div>
              </div> */}
     </div>
     </>
  )
}

export default Sidebar