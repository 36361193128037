import React, { useState } from 'react'
import axios from "axios";
import { useEffect } from 'react';
import Chart from 'react-apexcharts'
import Sidebar from '../../components/Sidebar'
import logo1 from '../../icons/Vector.png'
import logo2 from '../../icons/Vector (1).png'
import logo3 from '../../icons/customers 1.png'
import mark from '../../icons/Vector 5.png'
import { ToastContainer, toast } from "react-toastify";
import imageicon from '../../icons/profile.png'
import '../../App.css'
import { useNavigate } from 'react-router';
const Dashboard = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const profile = user.user.profile_pic
  const fname = user.user.f_name
  const lname = user.user.l_name
    const [countdata,setcountdata] = useState([])
    const [piedata,setpiedata] = useState([])
    const [todolist,settodolist] = useState([])
    const [colors,setcolor] = useState([])
    const [count,setcount] = useState()
    const navigate = useNavigate();
    const [togglelog,settogglevisitlog] = useState(false)
    const togglevisitlog = (index)=>{
      settogglevisitlog(index)
    }
    const updatetodo = async(id)=>{
      const status = "D"
      const token = user.token
      await axios.post('/update-todo',{id,status},{
        headers:{"Authorization":`Bearer ${token}`}
      }).then((response)=>{
        console.log(response)
        if(response.data.statusCode===200){
          toast.success(response.data.message, {
            position: "top-center",
          });
          gettodo()
        }
      })
    }
    const gettodo = async()=>{
      const token = user.token
      const response = await axios.get('/get-todo',{
        headers:{"Authorization":`Bearer ${token}`}
      })
       const result = response.data.data
       settodolist(result)
       console.log(result)
    }
    useEffect(()=>{
      
      const token = user.token
      const values=[]
      const names=[]
      const colors =[]
      var count = 0
      const piedata = async()=>{
       const response = await axios.get('/get-customer-status-counts',{
          headers:{"Authorization":`Bearer ${token}`}
        })
        for(let i=0;i<response.data.data.chart_data.length;i++){
          values.push(response.data.data.chart_data[i]?.value)
          names.push(response.data.data.chart_data[i]?.name)
          colors.push('rgba'+'('+response.data.data.chart_data[i]?.colour+')')
        }
        setcount(response.data.data.count_data)
        setpiedata(values)
        setcolor( colors )
        setcountdata( names )
      }

         
    piedata()
    gettodo()
    },[])
  return (
   <>
      <div className="sidebarflexproperty ">
        <div className="">
        <Sidebar/>
        </div>
        <div className="divouterdashboard">
            <div className="dashboard w-auto col-lg-12">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                       <div className="intro my-2 pt-2 px-1">
                       <div className="d-flex">
                       <div className=" p-2">
                             <img className='imgview2' src={profile ? profile : imageicon} alt="" />
                        </div>
                        <div className="div info pt-3 pl-2">
                          <p className='wcolor font-weight-bold'>Hi, {fname} {lname}</p>
                          <p className='wcolor fontsize10'>Welcome to SmartCare!</p>
                        </div>
                       </div>
                       </div>
                  </div>
                  <div className="col-lg-6">
                  <div className="row ml-1">
               <div className="col-lg-3  px-4 m-3 bgwhite py-2 br_1 imgcursor" onClick={()=>{navigate('/location')}}>
                <div className="row pb-3">
                    <img src={logo1} alt="" />
                </div>
                <div className="row ">
                <h5 className='fontsize16'>{count?.assign_locations}</h5>
                </div>
                <div className="row ">
               <h6 className='fontsize12'>locations</h6>
                </div>
               </div>
                <div className="col-lg-3 bgwhite px-4 m-3 py-2 br_1 imgcursor" onClick={()=>{navigate('/team')}}>
                 <div className="row pb-3">
                 <img src={logo2} alt="" />
                 </div>
                 <div className="row ">
                    <h5 className='fontsize16'>{count?.team_count}</h5>
                 </div>
                 <div className="row">
                   <h6 className='fontsize12'>Teams</h6>
                 </div>
               </div>
               <div className="col-lg-3 bgwhite px-4 m-3 py-2  br_1 imgcursor" onClick={()=>{navigate('/customer')}}>
                <div className="row pb-2">
                <img src={logo3} alt="" />
                </div>
                <div className="row">
                    <h5 className='fontsize16'>{count?.customer_count}</h5>
                </div>
                <div className="row">
                     <h6 className='fontsize12'>Customers</h6>
                </div>
               </div>
              </div>
               </div>
                </div>
             
            <div className="row   ">
              <div className="col-lg-6 ">
                <div className="div br_1 bgwhite pb-3 h-100">
              <h6 className='col-lg-12 py-3'>Customer Conversion Status</h6>
                 <div className="row ">
                  {
                    console.log(colors)
                  }
                    <Chart 
                    type='donut'
                    width={400}
                    height={300}
                    series={piedata}
                    options={{
                      noData:{
                        text: 'No Data Found',
                        align: 'center',
                        verticalAlign: 'middle',
                        style: {
                          color: '#fff',
                          fontSize: '14px',
                        }
                      },
                      // colors: colors,
                      colors : colors,
                      labels: countdata,
                      legend:{
                        position: 'right',
                      },
                      dataLabels:{
                        enabled: false
                      }
                    }}
                    />
                 </div>
                </div>
              </div>
              <div className="col-lg-6">
              <div className=" bgwhite  br_1 p-3 followupheight">
                <div className="">
              <div className="row mb-3">
                <div className="col-lg-6">
                  <p className='fontsize16 fontcolorblue'>Your Follow - Ups</p>
                </div>
                <div className="col-lg-6 imgcursor">
                  <p className='float-right fontsize12 fontcolorblue' onClick={()=>{navigate('/notifications')}}>See All</p>
                </div>
              </div>
              {
                 todolist.length!==0 ? todolist.map((cval,index)=>{
                  return (
                         <div className=" border my-2" key={index}>
                         <div className="row px-2" style={{alignItems:'center'}}>
                <div className="col-lg-8 ">
                <p className='fontsize12  greycolor'>{cval.message_header}</p>
                
                {
                  cval.target_date===null ? '' : <>
                      <div className="">
                  <p className='fontsize8 font-weight-bold'>{cval.target_date}</p>
                  </div>
                  <div className="row">
                  <div className="col-lg-5">
                  <p className='fontsize8 fontcolorblue'>{cval.message_type + ' '}</p>
                  </div>
                  {
                    togglelog===index ? '' :   <div className="col-lg-6 imgcursor" onClick={()=>{togglevisitlog(index)}}>
                    <p className='fontsize8 fontcolorblue'>{' '}View Full message</p>
                    </div>
                  }
                  </div>
                
                  {
                    togglelog===index && (
                      <>
                        <div className="">
                  <p className='fontsize8 greycolor font-weight-bold'>{cval.message_text} <span className='fontsize8 fontcolorblue font-weight-bolder imgcursor' onClick={togglevisitlog} >&nbsp; See less</span></p>
                  </div>
                      </>
                    )
                  }
                  
                  </>
                }
                 </div>
                   <div className="div  col-lg-4 text-center imgcursor" onClick={()=>{updatetodo(cval.todo_id)}} >
                 <img className=' mx-auto' src={mark} alt="" />
                 <p className='fontsize8 colorgreen '>Mark as Done</p>
                   </div>
                   </div>
              </div>
                  )
                })
                :
                <>
                <p className='font-weight-bold text-center mt-5 pt-3 greycolor'>No Follow-Ups</p>
                </>
              }

            </div>
            </div>
              </div>
                 
            </div>
            
            </div>
            
            </div>
            </div>
        </div>
   {/* </div> */}
   <ToastContainer/>
   </>
  )
}

export default Dashboard