import React from 'react'
import title from '../../icons/MicrosoftTeams-image (21).png'
import mob1 from '../../icons/MicrosoftTeams-image (27).png'
import mob2 from '../../icons/MicrosoftTeams-image (28).png'
import mob3 from '../../icons/MicrosoftTeams-image (29).png'
import { Carousel } from 'react-bootstrap'
import tick from '../../icons/Vector (9).png'
import tick2 from '../../icons/Vector (10).png'
import message from '../../icons/Vector (11).png'
import phone from '../../icons/Vector (12).png'
import location from '../../icons/Vector (13).png'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'
import { useState } from 'react'
const LandingPage = () => {
  const navigate = useNavigate()
  const [user,setUser]= useState(false)
  const [sidetoggle,setside]= useState('')
  if(user){
    if(sidetoggle==='a'){
      navigate('/dashboard')
    }
    if(sidetoggle==='b'){
      navigate('/Admindashboard')
    }
  }
  useEffect(()=>{
    const userloggedin = localStorage.getItem('user')
    const side =  JSON.parse(localStorage.getItem('sidebar')) 
    if(userloggedin){
      setUser(true)
    }
    if(side){
      setside(side)
    }
  },[])
  return (
    <>
       <div className="div">
       <div className="header_blur p-2 pr-5 w-100">
        <div className="row">
             <div className="col-lg-6 col-md-6 col-sm-6 px-4">
                <img src={title} alt="" />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 mt-1">
                 <button className=' float-right login_button_design p-2 px-4' onClick={()=>{navigate('/login')}}>Log - In</button>
              </div>
              </div>
            </div>
          <div className="main_div img-fluid">
            <div className="div ml-5 pl-4">
              <h1 className='pt-5 wcolor title_landing'>Manage your business efficiently <span> <br /></span> with Sales Tracky</h1>
            </div>
            <div className="div ml-5 mt-5 pl-4">
            <button className=' login_button_design p-2 px-5'>Contact Us</button>
            </div>
          </div>
          <div className="middle img-fluid">
            <div className="row">
              <div className="col-lg-12  ">
                <h1 className='wcolor middle_title'>Why Sales Tracky ?</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-1">
              </div>
              <div className="col-lg-5  animation">
              <Carousel controls={false} indicators={false} className='carousel'>
      <Carousel.Item interval={3000}>
      <img src={mob1} alt="" className='img-fluid mob_img mx-auto d-block'/>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
      <img src={mob2} alt="" className='img-fluid mob_img  mx-auto d-block'/>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
      <img src={mob3} alt="" className='img-fluid mob_img mx-auto d-block'/>
      </Carousel.Item>
    </Carousel>
              </div>
              <div className="col-lg-5 pt-5 ptext">
                <div className="content">
                <Carousel controls={false} indicators={false}>
      <Carousel.Item interval={3000}>
      <h3 className='wcolor '>Manage your team efficiently</h3>
                  <div className="d-flex my-3 pl-3">
                  <div className="">
                    <img src={tick} alt="" />
                  </div>
                  <div className="">
                     <p className='wcolor pl-3'>Clearly communicate expectations and goals</p>
                  </div>
                  </div>
                  <div className="d-flex my-3 pl-3">
                  <div className="">
                    <img src={tick} alt="" />
                  </div>
                  <div className="">
                     <p className='wcolor pl-3'>Check & download regular reports</p>
                  </div>
                  </div>
                  <div className="d-flex my-3 pl-3">
                  <div className="">
                    <img src={tick} alt="" />
                  </div>
                  <div className="">
                     <p className='wcolor pl-3'>Plan and organize</p>
                  </div>
                  </div>
                  <div className="d-flex my-3 pl-3">
                  <div className="">
                    <img src={tick} alt="" />
                  </div>
                  <div className="">
                     <p className='wcolor pl-3'>Track live location</p>
                  </div>
                  </div>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
      <h3 className='wcolor '>Manage your target audience</h3>
                  <div className="d-flex my-3 pl-3">
                  <div className="">
                    <img src={tick} alt="" />
                  </div>
                  <div className="">
                     <p className='wcolor pl-3'>Create individual customers</p>
                  </div>
                  </div>
                  <div className="d-flex my-3 pl-3">
                  <div className="">
                    <img src={tick} alt="" />
                  </div>
                  <div className="">
                     <p className='wcolor pl-3'>Get detailed meeting reports for each customer</p>
                  </div>
                  </div>
                  <div className="d-flex my-3 pl-3">
                  <div className="">
                    <img src={tick} alt="" />
                  </div>
                  <div className="">
                     <p className='wcolor pl-3'>Get analytical metrics of your customer conversion rates </p>
                  </div>
                  </div>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
      <h3 className='wcolor '>Available for both Web & Mobile</h3>
      </Carousel.Item>
    </Carousel>
                  
                </div>
                
              </div>
              
              <div className="col-lg-1">

              </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                  
                </div>
                <div className="div col-lg-6  w-100">
            <button className=' login_button_design2  '>Contact Us</button>
            </div>
              </div>
          </div>
          <div className="fotter p-4">
             <div className="row">
              <div className="col-lg-4">
              <img src={title} alt="" />
              <div className="d-flex mt-3 ml-3">
                <div className="div">
               <img src={tick2} alt="" className='iconimg'/>
                </div>
              <p className='wcolor pl-2 pt-1'>Copyright @ 2023 Datametricks Pvt. Ltd.</p>
              </div>
              </div>
              <div className="col-lg-8">
                <p className='wcolor'>Contact Us</p>
                <div className="d-flex mt-2">
                  <div className="div">
                  <img src={message} alt="" className='iconimg'/>
                  </div>
                  <p className='wcolor pl-2 pt-1'>support@datametricks.com</p>
                </div>
                <div className="d-flex mt-2">
                  <div>
                  <img src={phone} alt=""className='iconimg' />
                  </div>
                  <p className='wcolor pl-2 pt-1'>+91 xxxxxxxxxx</p>
                </div>
                <div className="d-flex mt-2">
                  <div className="div">
                  <img src={location} alt="" className='iconimg'/>
                  </div>
                  <p className='wcolor pl-2 pt-1'>B - 1224, Ithum Tower, Noida Sector - 62, Uttar Pradesh</p>
                </div>
              </div>
             </div>
          </div>
       </div>
    </>
  )
}

export default LandingPage