import React, { useEffect } from 'react'
import { useState } from 'react'
import Sidebar from '../../components/Sidebar'
import imageicon from '../../icons/profile.png'
import viewreport from '../../icons/inspection 2.png'
import dropicon from '../../icons/Vector (23).png'
import axios from 'axios'
import { useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
const ManageRequests = () => {
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user'))
    const permissions = user?.user?.role_assign
    const permissionsadmin = user?.user?.admin_assign_role
    for(let i=0;i<permissions?.length;i++){
      if(permissions[i].route === 'approve-visitlog' && permissions[i].permission!==null) {
        var array = Object?.values(permissions[i].permission)
      }
    }
    var foundadmin= false
    for(let i=0;i<permissionsadmin?.length;i++){
      if(permissionsadmin[i].route === 'approve-visitlog' ) {
         foundadmin = true
      }
    }
    const found = array?.find(obj => {
        return obj === 'C' ? true : false;
      });
    const found2 = array?.find(obj => {
        return obj === 'R' ? true : false;
      });
    const [toggleclr,settoggleclr] = useState('a')
    const [inrequests,setinrequests] = useState([])
    const [yourrequestlist,setyourrequests] = useState([])
    const [dropvalue,setdropvalue] = useState('')
    const handleincomingrequests = ()=>{
      settoggleclr('a')
    }
    const handleinyourrequests = ()=>{
      settoggleclr('b')
    }
    const viewreportfunction = (userid,name,profile_pic)=>{
        navigate('/viewreport',{
          state:{
            userid : userid,
            fullname: name,
            profile_pic: profile_pic
          }
        })
      }
    const approvereq = (for_context_id,for_context_type,sender_id,todo_id)=>{
        const token = user.token
       axios.post('/visit-log-approved',{for_context_id,for_context_type,sender_id,todo_id},{
        headers:{"Authorization":`Bearer ${token}`}
       }).then((response)=>{
        if(response.data.statusCode===200){
            toast.success(response.data.message, {
                position: "top-center"
              });
        }
        incomingrequest()
       })
    }
    const submitreq = (visit_id,sender_id)=>{
        const token = user.token
        axios.post('/visit-log-request-update',{visit_id,sender_id},{
         headers:{"Authorization":`Bearer ${token}`}
        }).then((response)=>{
         if(response.data.statusCode===200){
             toast.success(response.data.message, {
                 position: "top-center"
               });
         }
         yourrequest()
        })
    }
    const denyreq = (for_context_id,for_context_type,sender_id,todo_id)=>{
        const token = user.token
       axios.post('/visit-log-deny',{for_context_id,for_context_type,sender_id,todo_id},{
        headers:{"Authorization":`Bearer ${token}`}
       }).then((response)=>{
        if(response.data.statusCode===200){
            toast.success(response.data.message, {
                position: "top-center"
              });
        }
        incomingrequest()
       })
    }
    const incomingrequest = ()=>{
        const token = user.token
        axios.get('/get-visit-recived-request',{
            headers:{"Authorization":`Bearer ${token}`}
        }).then((response)=>{
            setinrequests(response.data.data)
        })
    }
    const yourrequest = ()=>{
        const token = user.token
        axios.get('/get-visit-send-requests',{
            headers:{"Authorization":`Bearer ${token}`}
        }).then((response)=>{
            setyourrequests(response.data.data)
        })
    }
    useEffect(()=>{
        incomingrequest()
        yourrequest()
    },[dropvalue])
  return (
      <div className="sidebarflexproperty">
        <div className="div">
            <Sidebar/>
        </div>
        <div className="divouterdashboard">
        <div className="dashboard">
        <div className="p-3 nav_bar">
            <div className="mx-2 ">
              <p className='font-weight-bold visit_log fontsize16 py-2 d-inline navcolor'>Manage Request</p>
            </div>
         </div>
         <div className="row">
            <div className="col-lg-6">
                <div className="d-flex">
                    <div>
                        <button className={toggleclr==='a' ? 'px-3 py-2 requests_btn imgcursor bgcolor' : 'px-3 py-2 requests_btn imgcursor borderbtn'} onClick={handleincomingrequests}>Incoming Requests</button>
                    </div>
                    <div>
                        <button className={toggleclr==='b' ? 'px-3 py-2 requests_btn imgcursor bgcolor' : 'px-3 py-2 requests_btn imgcursor borderbtn'} onClick={handleinyourrequests}>Your Requests</button>
                    </div>
                </div>
            </div>
            {
                toggleclr==='b' ?         <div className="col-lg-6 mt-3">
                <div className="d-flex">
            <select name='field_type' className='form-control  w-50 ' value={dropvalue} onChange={(e)=>{setdropvalue(e.target.value)}}>
                      <option value='' selected >All</option>
                       <option value="approved" >Approved</option>
                       <option value="rejected">Rejected</option>
                       <option value="Pending">Pending</option>
                       </select>
                       <div className="px-2 searchbg pt-1 ">
                  <img className='pl-1' src={dropicon} alt="" />
                </div>
                </div>
            </div> : ''
            }
    
         </div>
         {
            toggleclr==='a' && (
                <>
                    <div className="row ">
            <div className="col-lg-12  ">
                <div className="scrollview requestsheight mr-3">

               
                {
                       inrequests.length===0 ? <><h2 className='greycolor font-weight-bold text-center'>No Incoming Requests</h2></> : inrequests.map((cval,index)=>{
                        return <>
                              <div className="d-flex bgwhite border ml-3 mr-1 mb-2 " key={index}>
            <div className="col-lg-7">
                <div className="d-flex  ">
                <div className=" p-2">
                         <img className='m-auto imgview3' src={cval.sender_image ? cval.sender_image :  imageicon} alt="" />
                        </div>
                <div className="div pt-3 pl-1">
                    <p className='fontsize10'>{cval.sender_name}</p>
                </div>
                <div className="div pt-3 pl-2">
                    <p className='fontsize10'>({cval.message_header})</p>
                </div>
                </div>
                </div>
               <div className="col-lg-5">
                <div className="d-flex">
                    {
                        found || foundadmin ? <>        <div className="div">
                        <button className='px-2 py-1 requests_btn2 imgcursor btndesign m-2' onClick={()=>{denyreq(cval.for_context_id,cval.for_context_type,cval.sender_id,cval.todo_id)}}>Reject</button>
                    </div>
                    <div className="div">
                        <button className='px-2 py-1 requests_btn2 imgcursor bgcolor m-2' onClick={()=>{approvereq(cval.for_context_id,cval.for_context_type,cval.sender_id,cval.todo_id)}}>Approve</button>
                    </div>
                        </> : ''
                    }
         {
            found2 || foundadmin ?      <div className=" text-center ml-2 imgcursor pt-1 ml-3" onClick={()=>{viewreportfunction(cval.user_id,cval.sender_name,cval.sender_image)}}>
            <span><img src={viewreport} alt="" /></span>
            <p className='fontsize8 navcolor'>View Reports</p>
            </div> : ''
         }
           
                </div>
                      </div>
            </div>
                        </>
                    })
                }
               </div>
            </div>
         
         </div>
                </>
            )
         }
         {
            toggleclr==='b' && (
                <>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="scrollview requestsheight mr-3">
                        {
                            yourrequestlist && yourrequestlist.map((cval,index)=>{
                                 if(cval.visit_request===dropvalue || dropvalue===''){
                                return<>
                                 <div className="d-flex bgwhite border ml-3 mr-1 mb-2 " key={index}>
            <div className="col-lg-7">
                <div className="d-flex  ">
                <div className=" p-2">
                         <img className='m-auto imgview3' src={cval.profile_pic ? cval.profile_pic :  imageicon} alt="" />
                        </div>
                <div className="div pt-3 pl-1">
                    <p className='fontsize10'>{cval.customer_name}</p>
                </div>
                <div className="div pt-3 pl-2">
                    <p className='fontsize10 navcolor ml-4'>({cval.visit_request})</p>
                </div>
                </div>
                </div>
               <div className="col-lg-5">
                {
                    cval.visit_request==='rejected' ?       <div className="float-right">
                    <div className="div ">
                        <button className='px-2 py-1 requests_btn2 imgcursor bgcolor m-2 float-right' onClick={()=>{submitreq(cval.visit_id,cval.sender_id)}}>Submit</button>
                    </div>
                    </div> : ''
                }
          
                      </div>
            </div>
                                </>
                                 }
                            })
                        }
                    </div>
                    </div>
                </div>
                </>
            )
         }
     
        </div>
        </div>
        <ToastContainer/>
      </div>
  )
}

export default ManageRequests